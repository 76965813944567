import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import image from '../../img/bg-ico-hero.jpg';
import { Typography } from '@mui/material';
import { FaFacebook } from 'react-icons/fa';
import { FaInstagramSquare } from 'react-icons/fa';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  // ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

export default function Maps() {
  return (
    <Box
      sx={{ flexGrow: 1 }}
      style={{ width: '90%', margin: 'auto', marginBottom: '50px' }}
    >
      <Grid container spacing={6} justifyContent={'space-evenly'}>
        <Grid item xs={12} sm={3}>
          <Box>
            <br />
            <br />
            <h1 style={{ fontSize: '17px', color: 'GrayText' }}>
              Kieler Str. 113{' '}
            </h1>
            <h1 style={{ fontSize: '17px', color: 'GrayText' }}>
              24119 Kronshagen
            </h1>
            <h1 style={{ fontSize: '17px', color: 'GrayText' }}>
              Telefon: 0431 - 59674213
            </h1>
            <br />

            <h1 style={{ fontSize: '17px', color: 'GrayText' }}>
              Montag - Mittwoch:{' '}
              <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
                10:00 - 18:00 Uhr
              </span>
            </h1>
            <h1 style={{ fontSize: '17px', color: 'GrayText' }}>
              Donnerstag - Freitag:{' '}
              <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
                10:00 - 17:00 Uhr
              </span>
            </h1>
            <h1 style={{ fontSize: '17px', color: 'GrayText' }}>
              Samstag:{' '}
              <span style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
                10:00 - 15:00 Uhr
              </span>
            </h1>
            <br />

            <h1
              style={{
                fontSize: '17px',
                color: 'GrayText',
                textDecorationLine: 'underline',
              }}
            >
              info@minmini.de
            </h1>
            <h1
              style={{
                fontSize: '17px',
                fontWeight: 'bold',
                color: 'GrayText',
                fontStyle: 'italic',
              }}
            ></h1>

            <a
              href='https://www.instagram.com/minmini.kidsstore'
              target='_blank'
              style={{
                display: 'flex',
                alignItems: 'center',
                fontSize: '17px',
                color: 'GrayText',
                fontStyle: 'italic',
              }}
            >
              <img
                style={{ width: 20, marginRight: 4 }}
                src='/images/insta-icon.jpeg'
                alt='insta icon'
              />
              minmini.kidsstore
            </a>
          </Box>
        </Grid>

        <Grid item xs={12} sm={6}>
          <iframe
            src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2326.419591092771!2d10.0776702!3d54.3318916!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47b255c7e56edb47%3A0x30c2bd90a916cb7a!2sKieler%20Str.%20113%2C%2024119%20Kronshagen%2C%20Germany!5e0!3m2!1sen!2s!4v1685042099165!5m2!1sen!2s'
            width='100%'
            height='360'
            style={{ border: 0 }}
            allowfullscreen=''
            loading='lazy'
            referrerPolicy='no-referrer-when-downgrade'
          ></iframe>
        </Grid>
      </Grid>
    </Box>
  );
}
