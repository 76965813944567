import { Grid, TextField, Button, Box, InputLabel, Paper } from '@mui/material';
import { useFormik } from 'formik';
import React, { useState } from 'react';
import http from '../../http';

const PasswordForm = () => {
  const [error, setError] = useState('');
  const { email } = JSON.parse(localStorage.getItem('authUser'));
  const handleSubmit = (values) => {
    http
      .request({
        method: 'post',
        maxBodyLength: Infinity,
        url: '/auth/change-password',
        headers: {
          'Content-Type': 'application/json',
        },
        data: values,
      })
      .then((response) => {
        alert('Password changed!');
      })
      .catch(function (error) {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log(error?.response?.data);
          alert(error?.response?.data?.message);
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser
          // and an instance of http.ClientRequest in node.js
          console.log(error.request);
          alert(error?.request?.message);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log('Error', error?.message);
          alert(error?.message);
        }
      });
  };
  const formik = useFormik({
    initialValues: {
      email: email || '',
      newPassword: '',
      oldPassword: '',
    },
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  return (
    <Paper
      sx={{
        // minHeight: "50vh",
        width: '100%',
        mt: '2rem',
        borderRadius: '1rem',
      }}
    >
      <form
        onSubmit={formik.handleSubmit}
        style={{
          backgroundColor: '#fff',
          padding: '50px',
          borderRadius: '1rem',
        }}
      >
        <Grid container spacing={3}>
          <Grid xs={12}>
            <Box sx={{ fontSize: '1.3rem', pb: '1rem' }}>Passwort ändern</Box>
          </Grid>
          <Grid item xs={12} sm={3}>
            <InputLabel
              sx={{ fontWeight: '700', fontSize: '20px' }}
              shrink
              htmlFor='oldPassword'
            >
              Altes Passwort
            </InputLabel>
            <TextField
              fullWidth
              id='oldPassword'
              name='oldPassword'
              size='small'
              placeholder='Altes Passwort'
              value={formik.values.oldPassword}
              onChange={formik.handleChange}
              error={
                formik.touched.oldPassword && Boolean(formik.errors.oldPassword)
              }
              sx={{
                '& label': {
                  color: 'black',
                },
                '& input': {
                  backgroundColor: 'white',
                },
              }}
              helperText={
                formik.touched.oldPassword && formik.errors.oldPassword
              }
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <InputLabel
              sx={{ fontWeight: 700, fontSize: '20px' }}
              shrink
              htmlFor='newPassword'
            >
              Neues Passwort
            </InputLabel>
            <TextField
              fullWidth
              id='newPassword'
              name='newPassword'
              // label="Last Name"
              size='small'
              placeholder='Neues Passwort'
              value={formik.values.newPassword}
              onChange={formik.handleChange}
              error={
                formik.touched.newPassword && Boolean(formik.errors.newPassword)
              }
              sx={{
                '& label': {
                  color: 'black',
                },
                '& input': {
                  backgroundColor: 'white',
                },
              }}
              helperText={
                formik.touched.newPassword && formik.errors.newPassword
              }
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <InputLabel
              sx={{ fontWeight: 700, fontSize: '20px' }}
              shrink
              htmlFor='email'
            >
              Email
            </InputLabel>
            <TextField
              fullWidth
              id='email'
              name='email'
              disabled
              size='small'
              placeholder='email'
              value={email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              sx={{
                '& label': {
                  color: 'black',
                },
                '& input': {
                  backgroundColor: 'white',
                },
              }}
              helperText={formik.touched.email && formik.errors.email}
            />
          </Grid>
        </Grid>
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
          <Button
            type='submit'
            sx={{ textTransform: 'capitalize' }}
            variant='contained'
            style={{ background: '#b89981' }}
            size='large'
          >
            Passwort aktualisieren
          </Button>
        </Box>
      </form>
    </Paper>
  );
};
export default PasswordForm;
