import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import http from '../../http';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';

import { Box, Button, Typography } from '@mui/material';
import Pagination from './Pagination';

export default function VerkäufeTable() {
  const [products, setProducts] = React.useState([]);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const onPageChange = (event, newPage) => {
    // if (newPage < Math.round(products?.length / rowsPerPage)) {
    setPage(newPage);
    // } else {
    // Math.max(0, Math.ceil(products?.length / rowsPerPage));
    // }
  };

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(
      event,
      Math.max(0, Math.ceil(products?.length / rowsPerPage) - 1)
    );
  };

  const navigate = useNavigate();

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  React.useEffect(() => {
    const fetchUserData = async () => {
      try {
        let config = {
          method: 'get',
          maxBodyLength: Infinity,
          url: '/transactions?limit=10000&page=1',
        };
        http
          .request(config)
          .then((response) => {
            const { data } = response.data;
            setProducts(response.data.data);
          })
          .catch((error) => {
            console.log(error);
          });
      } catch (error) {
        console.log('Error fetching user data', error);
      }
    };

    fetchUserData();
  }, []);

  return (
    <Box sx={{ minHeight: '100vh' }}>
      <Box sx={{ boxShadow: 1, p: 2, margin: 2 }}>
        <Typography sx={{ fontSize: '20px', fontWeight: 'bold' }}>
          Übersicht deiner Artikel
        </Typography>
        <select
          style={{ padding: '6px', marginBottom: '6px' }}
          onChange={handleChangeRowsPerPage}
        >
          {[10, 20, 30, 40, 50].map((pageSize) => (
            // {[1, 2, 3].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              zeigen {pageSize}
            </option>
          ))}
        </select>

        <TableContainer component={Paper}>
          <Table>
            <TableHead sx={{ bgcolor: '#e4f0f1' }}>
              <TableRow>
                <TableCell align='left' sx={{ fontWeight: '600' }}>
                  Order Id
                </TableCell>{' '}
                <TableCell align='left' sx={{ fontWeight: '600' }}>
                  Mieter
                </TableCell>
                <TableCell align='left' sx={{ fontWeight: '600' }}>
                  Verkaufsdatum
                </TableCell>
                <TableCell align='left' sx={{ fontWeight: '600' }}>
                  Total
                </TableCell>
                <TableCell align='left' sx={{ fontWeight: '600' }}>
                  Verkaufsstatus
                </TableCell>
              </TableRow>
            </TableHead>
            {/* {products?.map((valued, index) => ( */}
            {(rowsPerPage > 0
              ? products.slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                )
              : products
            )?.map((valued, index) => (
              <TableBody>
                <TableRow
                  sx={{
                    background: '#F9F9F9 ',
                  }}
                >
                  <TableCell sx={{}}>{valued.booking_id} </TableCell>
                  <TableCell align='left' sx={{}}>
                    {valued.user.first_name}
                  </TableCell>
                  <TableCell align='left' sx={{}}>
                    {' '}
                    {moment(valued.createdAt).format('DD-MM-YYYY')}
                  </TableCell>
                  <TableCell align='left' sx={{}}>
                    {valued.amount}
                  </TableCell>
                  <TableCell align='left' sx={{}}>
                    {valued?.status}
                  </TableCell>
                </TableRow>
              </TableBody>
            ))}
          </Table>
        </TableContainer>

        <Box sx={{ p: 2, display: 'flex', justifyContent: 'end' }}>
          <Pagination
            products={products}
            page={page}
            rowsPerPage={rowsPerPage}
            handleFirstPageButtonClick={handleFirstPageButtonClick}
            handleBackButtonClick={handleBackButtonClick}
            handleNextButtonClick={handleNextButtonClick}
            handleLastPageButtonClick={handleLastPageButtonClick}
            handleChangePage={onPageChange}
          />
        </Box>
      </Box>
    </Box>
  );
}
