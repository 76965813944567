import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const defaultOptions = {
  position: 'top-right',
  autoClose: 3000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
};

export const showSuccessNotification = (message, options = {}) => {
  toast.success(message, { ...defaultOptions, ...options });
};

export const showErrorNotification = (message, options = {}) => {
  toast.error(message, { ...defaultOptions, ...options });
};
