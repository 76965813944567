import React, { useEffect, useState } from "react";
import { Container, Box, Typography, Button } from "@mui/material";
export default function Cookies(props) {
  const content = {
    header: "Cookie Policy",
    description:
      "We use cookies to personalise content, to provide social media features and to analyse our traffic. We also share information about your use of our site with our social media, advertising and analytics partners.",
    "primary-action": "Accept cookies",
    "secondary-action": "Refuse cookies",
    ...props.content,
  };

  const [showCookie, setShowCookie] = useState(0);
  useEffect(() => {
    const isCookie = localStorage.getItem("cookie");
    if(!isCookie){
        setShowCookie(true)
    }
  }, []);
  return (
    <>
      {showCookie ? (
        <Box
          sx={{
            position: "fixed",
            width: "100%",
            bottom: 0,
            p: 4,
            zIndex: "modal",
            bgcolor: "#fdfcf6",
          }}
        >
          <Container maxWidth="md" className={{}}>
            <Typography
              variant="h5"
              component="h2"
              gutterBottom={true}
              className={{}}
            >
              {content["header"]}
            </Typography>
            <Typography
              variant="subtitle1"
              color="textSecondary"
              paragraph={true}
            >
              {content["description"]}
            </Typography>
            <Box sx={{}}>
              <Button
                variant="contained"
                sx={{
                  marginRight: 2,
                  width: { xs: "100%", md: "50%", lg: "auto" },
                  marginRight: 1,
                }}
                onClick={() => {
                    localStorage.setItem("cookie", "cookies");
                    setShowCookie(false)}
                }>
                {content["primary-action"]}
              </Button>
              <Button
                onClick={() => {
                    localStorage.setItem("cookie", "cookies");
                    setShowCookie(false)}
                }
                variant="outlined"
                color="primary"
                sx={{
                  width: { xs: "100%", md: "50%", lg: "auto" },
                  marginRight: 0,
                }}
              >
                {content["secondary-action"]}
              </Button>
            </Box>
          </Container>
        </Box>
      ) : null}
    </>
  );
}
