import * as React from 'react';
import AdminNav from './component/AdminProfile/AdminNav';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { routes, userProfileRoutes, adminProfileRoutes } from './route';
import { Box } from '@mui/material';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import NewNav from './component/home/NewNav';
import UserLayout from './layouts/UserLayout';
import AdminLayout from './layouts/AdminLayout';
import Cookie from './component/home/Cookie';
import Footer from './component/home/Footer';
export default function App() {
  const pathName = window.location.pathname;

  const path = [
    // "/admin-profile",
    '/stands-admin',
    '/products-admin',
    '/categories-admin',
    '/holidays-admin',
    '/add_holiday',
    '/stands-details',
  ];

  return (
    <Box sx={{ backgroundColor: '#fdfcf6' }}>
      <Router>
        <NewNav />
        {/* {path.includes(pathName) ? <AdminNav /> : null} */}

        <ToastContainer
          position='top-right'
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          style={{ zIndex: 999999 }}
        />

        <Routes>
          {routes.map((route) => (
            <Route
              key={route.key}
              path={route.path}
              element={<route.component />}
            ></Route>
          ))}
        </Routes>
        <Routes>
          {userProfileRoutes.map((route) => (
            <Route
              key={route.key}
              path={route.path}
              element={
                <UserLayout>
                  {' '}
                  <route.component />
                </UserLayout>
              }
            ></Route>
          ))}
        </Routes>
        <Routes>
          {adminProfileRoutes.map((route) => (
            <Route
              key={route.key}
              path={route.path}
              element={
                <AdminLayout>
                  {' '}
                  <route.component />
                </AdminLayout>
              }
            ></Route>
          ))}
        </Routes>
        <Footer />
        <Cookie />
      </Router>
    </Box>
  );
}
