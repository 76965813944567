import { Box, Button, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import http from '../../http';
import { useNavigate, Link } from 'react-router-dom';
import { Spinner } from '../Spinner/Spinner';

const Dashboard = (props) => {
  const navigate = useNavigate();

  const [showRating, setShowRating] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [stats, setStats] = React.useState(null);
  const [allData, setAllData] = React.useState();
  const [loading, setLoading] = React.useState(true);

  const handleClickOpen = () => {
    setOpen(true);
    navigate('/my-profile/stands/2');
  };

  const handleImageClick = () => {
    navigate('/my-profile/couponDetails');
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  useEffect(() => {
    http
      .request({
        method: 'get',
        maxBodyLength: Infinity,
        url: '/rating/has-user-submitted/rating',
      })
      .then((response) => {
        const { data } = response.data;
        setShowRating(data?.status);
      })
      .catch((error) => {
        console.log(error);
      });
    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: '/stats',
    };
    http
      .request(config)
      .then((response) => {
        const { data } = response.data;
        setStats(data);
      })
      .catch((error) => {
        console.log(error);
      });
    const fetchUserData = async () => {
      try {
        const response = await http.get('/user'); // Replace with the appropriate API endpoint URL
        const productData = await http.get(
          `/product/get-all-by-user-id-filter/${response.data.data.id}`
        );
        setAllData(productData.data.data);
        setLoading(false);
      } catch (error) {
        console.log('Error fetching user data', error);
        setLoading(false);
      }
    };

    fetchUserData();
  }, []);

  if (loading)
    return (
      <Spinner
        text={'Umsätze werden übertragen - das kann einen Moment dauern'}
      />
    );

  return (
    <Box sx={{ minHeight: '100vh' }}>
      <Box
        sx={{
          display: { xs: 'block', sm: 'flex' },
          justifyContent: 'center',
          gap: '2rem',
          // background: "lightGray",
          margin: 'auto',
          mt: 3,
        }}
      >
        <Box
          sx={{
            background: 'white',
            borderRadius: '2rem',
            border: '2px solid lightgray',
            width: { xs: '100%', sm: '35%', md: '25%' },
            height: '20rem',
            display: 'flex',

            flexDirection: 'column',
            textAlign: 'left',
            justifyContent: 'end',
            mt: { xs: '1rem', sm: '0' },
          }}
        >
          <Box
            sx={{ fontSize: '20px', fontWeight: '600', textAlign: 'center' }}
          >
            Mein Umsatz:
          </Box>
          <Typography
            sx={{
              fontSize: '20px',
              fontWeight: '600',
              textAlign: 'center',
              padding: '5%',
            }}
          >
            {allData?.totalSoldPrice !== undefined
              ? `${allData.totalSoldPrice} € `
              : 'Sitzung abgelaufen - bitte aktualisieren!'}
          </Typography>
          <img
            src='https://minmini.de/images/coupon.png'
            alt='Coupon Detail Icon'
            style={{
              width: '100%',
              height: 'auto',
              marginBottom: 0,
              cursor: 'pointer',
            }}
            onClick={handleImageClick}
          />
        </Box>
      </Box>

      <Box
        sx={{
          background: '#fdfcf6',
          display: { xs: 'block', sm: 'flex' },
          gap: '1rem',
          justifyContent: 'space-around',
          width: '80%',
          m: 'auto',
          height: '130px',
          p: 1,
        }}
      >
        <Box
          sx={{
            width: { xs: '100%', sm: '32%' },
            background: '#fff',
            marginTop: '1rem',
            mb: { xs: '1rem', sm: '0' },
            p: 3,
            boxShadow: 2,
            borderRadius: '2rem',
            border: '2px solid lightgray',
          }}
        >
          <Typography
            sx={{ py: '0rem', fontWeight: '600', textAlign: 'center' }}
          >
            {' '}
            Verkaufte Produkte
          </Typography>
          <Box sx={{ pb: '.5rem', fontWeight: '600', textAlign: 'center' }}>
            {allData?.totalSoldQuantity !== undefined
              ? `${allData.totalSoldQuantity}`
              : '-'}
          </Box>
        </Box>
      </Box>

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          gap: '4rem',
          my: '2rem',
        }}
      >
        <Button
          style={{ background: '#b89981' }}
          variant='contained'
          size='large'
          onClick={handleClickOpen}
        >
          Buchung verlängern
        </Button>
        <Link to={'https://g.page/r/CXbzk_e_uMhPEBM/review'} target='__blank'>
          <Button
            style={{ background: '#686c7d' }}
            variant='contained'
            size='large'
            disabled={showRating}
          >
            BEWERTEN SIE UNS
          </Button>
        </Link>
      </Box>
    </Box>
  );
};

export default Dashboard;
