import { Box, Typography } from '@mui/material';
import React from 'react';

const CouponDetail = () => {
  return (
    <Box sx={{ minHeight: '100vh' }}>
      <Box
        sx={{
          display: { xs: 'block', sm: 'flex' },
          justifyContent: 'center',
          gap: '2rem',
          margin: 'auto',
          mt: 3,
        }}
      >
        <Box
          sx={{
            background: '#FDFCF7',
            borderRadius: '2rem',
            border: '2px solid lightgray',
            width: { xs: '100%', sm: '75%', md: '80%' },
            height: 'auto',
            display: 'flex',
            flexDirection: 'column',
            textAlign: 'center',
            justifyContent: 'center',
            mt: { xs: '1rem', sm: '0' },
            padding: '2rem',
          }}
        >
          <img
            src='https://minmini.de/images/coupon-icon.png'
            alt='Coupon Detail Icon'
            style={{
              width: '75%',
              height: 'auto',
              alignSelf: 'center',
              marginBottom: '5%',
            }}
          />
          <Typography
            sx={{
              fontSize: '20px',
              textAlign: 'center',
              mt: '1rem',
            }}
          >
            Mit deinem persönlichen Empfehlungs-Code (diesen findest du unter
            „Mein Profil“) erhalten deine Freundinnen und Freunde{' '}
            <Typography
              component='span'
              sx={{ fontSize: '20px', fontWeight: 'bold', fontFamily: 'Arial' }}
            >
              20% auf die erste Buchung*.
            </Typography>
          </Typography>
          <Typography
            sx={{
              fontSize: '20px',
              textAlign: 'center',
              mt: '2rem',
            }}
          >
            Auch deine Treue wird belohnt:
          </Typography>
          <Typography
            sx={{
              fontSize: '20px',
              textAlign: 'center',
              alignSelf: 'center',
              width: '75%',
            }}
          >
            Jedes Mal, wenn dein Code 4 Mal eingelöst wurde, erhältst du dein
            Regal für{' '}
            <Typography
              component='span'
              sx={{ fontSize: '20px', fontWeight: 'bold', fontFamily: 'Arial' }}
            >
              4 Wochen mietfrei.
            </Typography>
          </Typography>
          <Typography
            sx={{
              fontSize: '14px',
              textAlign: 'center',
              mt: '5rem',
              fontStyle: 'italic',
            }}
          >
            *Dieses Angebot gilt nur für Neukunden. Die geworbene Person darf
            nicht im selben Haushalt mit der werbenden Person leben. Gültig bei
            Buchungen ab 2 Wochen. Wir behalten uns vor, Buchungen ohne Angaben
            von Gründen zu stornieren.
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default CouponDetail;
