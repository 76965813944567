import http from '../../http';
import login from '../../img/login.png';
import { AiFillLock } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import {
  Grid,
  TextField,
  Button,
  Box,
  FormControlLabel,
  Checkbox,
  InputLabel,
  Paper,
  IconButton,
} from '@mui/material';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Dashboard, House } from '@mui/icons-material';
import {
  showSuccessNotification,
  showErrorNotification,
} from '../Notifications/notification';

const Login = () => {
  const [data, setData] = useState({
    email: '',
    password: '',
  });
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const handleInput = (e) => {
    const { name, value } = e.target;

    setData((preVal) => {
      return {
        ...preVal,
        [name]: value,
      };
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    http
      .request({
        method: 'post',
        maxBodyLength: Infinity,
        url: '/auth/login',
        headers: {
          'Content-Type': 'application/json',
        },
        data: data,
      })
      .then((response) => {
        localStorage.setItem('id', response.data.data.id);
        localStorage.setItem('authUser', JSON.stringify(response.data.data));
        localStorage.setItem('type', response.data.data.type);
        navigate(
          response.data.data.type == 'admin'
            ? '/bookings-admin'
            : '/my-profile/dashboard'
        );
      })
      .catch(function (error) {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          showErrorNotification(error.response.data.message);
          setError(error?.response?.data?.message);
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser
          // and an instance of http.ClientRequest in node.js
          console.log(error.request);
          setError(error?.request?.message);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log('Error', error?.message);
          setError(error?.message);
        }
      });
  };
  const handleHome = () => {
    navigate('/');
  };

  return (
    <Box sx={{ height: '100vh' }}>
      <Box
        sx={{
          display: { xs: 'none', sm: 'flex' },
          justifyContent: 'end',
          pr: '1rem',
          py: '1rem',
        }}
      ></Box>
      <Paper
        sx={{
          // display: "flex",
          // justifyContent: "center",
          width: { xs: '90%', sm: '40%', md: '35%', lg: '25%' },
          margin: 'auto',
          alignItems: 'center',
          minHeight: '53vh',
          p: '1rem',
          borderRadius: '20px',
        }}
        elevation={1}
      >
        <form
          style={
            {
              // backgroundColor: "#f7f0f4",
              // padding: "5px",
              // borderRadius: "1rem",
              // boxShadow: "2px solid gray",
            }
          }
        >
          <Grid container spacing={3} justifyContent={'center'}>
            <Grid item xs={12} sm={11}>
              <Box
                sx={{
                  display: {
                    xs: 'block',
                    sm: 'flex',
                  },
                  justifyContent: {
                    xs: 'space-between',
                  },
                  alignItems: 'center',
                }}
              >
                <Box>
                  <h2 style={{ fontSize: '16px', fontWeight: 'bolder' }}>
                    {' '}
                    Schön, dich wiederzusehen!
                  </h2>
                </Box>
                <Box
                  display='flex'
                  justifyContent='center'
                  flexDirection='Column'
                  alignItems='center'
                >
                  <img src={login} height='100px' width='150px' />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={11}>
              <InputLabel
                sx={{ fontWeight: 700, fontSize: '20px' }}
                shrink
                htmlFor='email'
              >
                E-Mail
              </InputLabel>
              <TextField
                fullWidth
                size='small'
                id='email'
                name='email'
                value={data.email}
                placeholder='Tippe deine E-Mail Adresse ein'
                onChange={handleInput}
                sx={{
                  '& label': {
                    color: 'black',
                  },
                  '& input': {
                    backgroundColor: 'white',
                  },
                }}
              />
            </Grid>

            <Grid item xs={12} sm={11}>
              <InputLabel
                sx={{ fontWeight: 700, fontSize: '20px' }}
                shrink
                htmlFor='password'
              >
                Passwort
              </InputLabel>
              <TextField
                fullWidth
                size='small'
                id='password'
                name='password'
                value={data.password}
                placeholder='Tippe dein Passwort ein'
                onChange={handleInput}
                sx={{
                  '& label': {
                    color: 'black',
                  },
                  '& input': {
                    backgroundColor: 'white',
                  },
                }}
              />
            </Grid>

            <Grid item xs={12} sm={11}>
              <FormControlLabel
                control={<Checkbox />}
                label={<Box sx={{ fontSize: '13px' }}>Angemeldet bleiben</Box>}
              />
            </Grid>
            <Grid item xs={12} sm={11}>
              <Box sx={{ display: 'flex', justifyContent: 'left', mt: 2 }}>
                <Button
                  onClick={handleSubmit}
                  sx={{ textTransform: 'capitalize' }}
                  variant='contained'
                  style={{ background: '#b89981', width: '11rem' }}
                  size='large'
                >
                  Login
                </Button>
              </Box>
            </Grid>

            <Grid item xs={12} sm={8}>
              <Link to={'/forgot-password'} style={{ textDecoration: 'none' }}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    fontSize: '13px',
                    mt: 1,
                    pb: 1,
                  }}
                >
                  <AiFillLock style={{ marginTop: 3 }} /> &nbsp; Passwort
                  vergessen?
                </Box>
              </Link>
            </Grid>
          </Grid>
        </form>
      </Paper>
      <Box
        sx={{
          fontSize: '13px',
          textAlign: 'center',
          mt: '3rem',
          pb: 1,
          fontWeight: 's',
        }}
      >
        Du hast noch keinen Zugang?{' '}
        <Link to='/booking-process'> Buche jetzt dein Regal!</Link>
      </Box>
    </Box>
  );
};
export default Login;
