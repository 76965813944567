import { Grid, Box, Typography } from "@mui/material";
import React from "react";
import logo from "../../img/logo.png";
import "./styles/letter.css";

const Letter = () => {
  return (
    <div style={{ padding: "20px" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection:{xs:"column-reverse", lg:"row"},
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            display: "flex",
            marginTop: "20px",
            justifyContent: "center",
            width: "100%",
            // marginLeft: "220px",
          }}
        >
          <Typography
            style={{
              fontWeight: "bold",
              textAlign: "center",
              fontSize: "30px",
            }}
          >
            Allgemeine Geschäftsbedingungen
          </Typography>
        </Box>


        <img src={logo} width="200px" height="200px" />
      
      </Box>

      <Box className="text-container">
        <ol style={{ fontWeight: "bold" }} className="orderd-list">
          <li sx={{ fontSize: 22, fontWeight: "bold", marginTop: "2%" }}>
            <Typography
              style={{
                fontWeight: "bold",
                fontSize: "22px",
              }}
            >
              Geltungsbereich
            </Typography>

            <Typography
              style={{
                fontSize: "18px",
                wordSpacing: "5px",
                lineHeight: 2,
              }}
            >
              Diese Allgemeinen Geschäftsbedingungen („Vereinbarung“) regeln die
              Vermietung von Regalen und den Verkauf von Artikeln bei MinMini
              zwischen MinMini und dem Mieter („Mieter“ oder „Sie“). Durch die
              Anmietung eines Regals und die Nutzung der Dienste von MinMini
              erklären Sie sich mit dieser Vereinbarung einverstanden.
            </Typography>
          </li>

          <li style={{ fontSize: 22, fontWeight: "bold", marginTop: "2%" }}>
            <Typography
              style={{
                fontWeight: "bold",
                fontSize: "22px",
              }}
            >
              Mietvertrag
            </Typography>

            <ol style={{ fontWeight: "normal", fontSize: 18 }}>
              <li sx={{ fontWeight: "bold" }}>
                <Typography
                  style={{
                    fontSize: "18px",
                    wordSpacing: "5px",
                    lineHeight: 2,
                  }}
                >
                  Der Mietvertrag kommt ausschließlich auf der Grundlage dieser
                  Allgemeinen Geschäftsbedingungen zustande und gilt auch für
                  alle künftigen Verträge, auch wenn dies nicht nochmals
                  ausdrücklich vereinbart wird.
                </Typography>
              </li>
              <li sx={{ fontWeight: "bold" }}>
                <Typography
                  style={{
                    fontSize: "18px",
                    wordSpacing: "5px",
                    lineHeight: 2,
                  }}
                >
                  Mit Abschluss des Mietvertrages ist der Mieter berechtigt, die
                  im Mietvertrag vorgesehene Verkaufsfläche zum Zwecke des
                  Verkaufs von Kinderartikeln für die vereinbarte Mietdauer zu
                  nutzen.
                </Typography>
              </li>
              <li sx={{ fontWeight: "bold" }}>
                <Typography
                  style={{
                    fontSize: "18px",
                    wordSpacing: "5px",
                    lineHeight: 2,
                  }}
                >
                  MinMini übernimmt in ihrem Namen den repräsentativen Verkauf
                  der Artikel des Mieters.
                </Typography>
              </li>
            </ol>
          </li>

          <li style={{ fontSize: 22, fontWeight: "bold", marginTop: "2%" }}>
            <Typography
              style={{
                fontWeight: "bold",
                fontSize: "22px",
              }}
            >
              Haftung
            </Typography>

            <ol style={{ fontWeight: "normal", fontSize: 18 }}>
              <li sx={{ fontWeight: "bold" }}>
                <Typography
                  style={{
                    fontSize: "18px",
                    wordSpacing: "5px",
                    lineHeight: 2,
                  }}
                >
                  MinMini übernimmt keine Haftung für die vom Mieter
                  eingebrachten Artikel, insbesondere nicht für Diebstahl,
                  Beschädigung durch andere Käufer und Verkäufer oder sonstige
                  Schäden.
                </Typography>
              </li>
              <li sx={{ fontWeight: "bold" }}>
                <Typography
                  style={{
                    fontSize: "18px",
                    wordSpacing: "5px",
                    lineHeight: 2,
                  }}
                >
                  MinMini übernimmt keine Garantie für die Sicherheit und
                  Funktionalität von Artikeln wie Kinderwagen, Kindersitzen
                  und ähnlichen Artikeln, da es sich nicht um Produkte von
                  MinMini handelt und diese vor dem Verkauf nicht auf ihre
                  Funktionalität geprüft werden.
                </Typography>
              </li>
              <li sx={{ fontWeight: "bold" }}>
                <Typography
                  style={{
                    fontSize: "18px",
                    wordSpacing: "5px",
                    lineHeight: 2,
                  }}
                >
                  MinMini haftet gegenüber Käufern nicht für Unzufriedenheit,
                  Rücksendungen oder Mängel an den Artikeln.
                </Typography>
              </li>
            </ol>
          </li>

          <li style={{ fontSize: 22, fontWeight: "bold", marginTop: "2%" }}>
            <Typography
              style={{
                fontWeight: "bold",
                fontSize: "22px",
              }}
            >
              Nutzung der Verkaufsfläche
            </Typography>

            <ol style={{ fontWeight: "normal", fontSize: 18 }}>
              <li sx={{ fontWeight: "bold" }}>
                <Typography
                  style={{
                    fontSize: "18px",
                    wordSpacing: "5px",
                    lineHeight: 2,
                  }}
                >
                  Der Mieter hat die Verkaufsfläche pfleglich zu behandeln und
                  sie sauber und frei von Abfall zu halten.
                </Typography>
              </li>
              <li sx={{ fontWeight: "bold" }}>
                <Typography
                  style={{
                    fontSize: "18px",
                    wordSpacing: "5px",
                    lineHeight: 2,
                  }}
                >
                  Der Mieter trägt dafür Sorge, dass durch die Nutzung der
                  Verkaufsfläche keine Beeinträchtigungen für das Mietobjekt,
                  das Gebäude oder Dritte entstehen.
                </Typography>
              </li>
              <li sx={{ fontWeight: "bold" }}>
                <Typography
                  style={{
                    fontSize: "18px",
                    wordSpacing: "5px",
                    lineHeight: 2,
                  }}
                >
                  Beschädigungen oder Verschmutzungen der Verkaufsfläche hat der
                  Mieter bei Übernahme der Verkaufsfläche unverzüglich MinMini
                  zu melden. Andernfalls gilt die Übernahme der Verkaufsfläche
                  in mangelfreiem Zustand.
                </Typography>
              </li>
            </ol>
          </li>

          <li style={{ fontSize: 22, fontWeight: "bold", marginTop: "2%" }}>
            <Typography
              style={{
                fontWeight: "bold",
                fontSize: "22px",
              }}
            >
              Einschränkungen und verbotene Gegenstände
            </Typography>

            <ol style={{ fontWeight: "normal", fontSize: 18 }}>
              <li sx={{ fontWeight: "bold" }}>
                <Typography
                  style={{
                    fontSize: "18px",
                    wordSpacing: "5px",
                    lineHeight: 2,
                  }}
                >
                  Dem Mieter ist es nicht gestattet, elektrische Geräte
                  anzuschließen, zu betreiben oder Veränderungen an der
                  Mietsache vorzunehmen.
                </Typography>
              </li>
              <li sx={{ fontWeight: "bold" }}>
                <Typography
                  style={{
                    fontSize: "18px",
                    wordSpacing: "5px",
                    lineHeight: 2,
                  }}
                >
                  Der Mieter darf keine der folgenden Gegenstände mitbringen und
                  zum Verkauf auslegen: gefälschte Artikel, brennbare,
                  gefährliche und verderbliche Stoffe, Lebensmittel,
                  verderbliche Waren, lebende oder lebensfähige Stoffe,
                  Flüssigkeiten, Gase, Farben, illegal erworbene Gegenstände
                  oder Materialien, die Dritte beeinträchtigen könnten durch
                  mögliche Emissionen.
                </Typography>
              </li>
              <li sx={{ fontWeight: "bold" }}>
                <Typography
                  style={{
                    fontSize: "18px",
                    wordSpacing: "5px",
                    lineHeight: 2,
                  }}
                >
                  Der Mieter hat alle Handlungen zu unterlassen, die geeignet
                  sind, Dritte in den Verkaufsräumen von MinMini zu stören oder
                  zubeeinträchtigen. Außerhalb der Mietfläche gelagerte
                  Gegenstände können von MinMini auf Kosten des Mieters
                  unverzüglich entfernt werden.
                </Typography>
              </li>
            </ol>
          </li>

          <li style={{ fontSize: 22, fontWeight: "bold", marginTop: "2%" }}>
            <Typography
              style={{
                fontWeight: "bold",
                fontSize: "22px",
              }}
            >
              Pflichten des Mieters
            </Typography>

            <ol style={{ fontWeight: "normal", fontSize: 18 }}>
              <li sx={{ fontWeight: "bold" }}>
                <Typography
                  style={{
                    fontSize: "18px",
                    wordSpacing: "5px",
                    lineHeight: 2,
                  }}
                >
                  Mit Abschluss des Mietvertrages hat der Mieter MinMini
                  persönliche Informationen mitzuteilen, unter anderem: Name,
                  Anschrift, Kontaktinformationen und eine Bankverbindung zum
                  Zwecke der Lieferung/Auszahlung mitzuteilen. Bei
                  Nichtbeachtung kann der Mieter für die Kosten verantwortlich
                  sein, die durch fehlgeschlagene Zustellversuche entstehen.
                </Typography>
              </li>
            </ol>
          </li>

          <li style={{ fontSize: 22, fontWeight: "bold", marginTop: "2%" }}>
            <Typography
              style={{
                fontWeight: "bold",
                fontSize: "22px",
              }}
            >
              Miet- und Verkaufserlöse
            </Typography>

            <ol style={{ fontWeight: "normal", fontSize: 18 }}>
              <li sx={{ fontWeight: "bold" }}>
                <Typography
                  style={{
                    fontSize: "18px",
                    wordSpacing: "5px",
                    lineHeight: 2,
                  }}
                >
                  Die Höhe der Miete variiert je nach Mietdauer, wie auf der
                  MinMini- Website angegeben.
                </Typography>
              </li>
              <li sx={{ fontWeight: "bold" }}>
                <Typography
                  style={{
                    fontSize: "18px",
                    wordSpacing: "5px",
                    lineHeight: 2,
                  }}
                >
                  Am Ende der Mietzeit wird der Verkaufserlös abzüglich ...der vereinbarten Verkaufsprovision i.H.v. 15 % /40% (oder individuell vereinbart)... auf das vom Mieter angegebene Konto
                  überwiesen. Das Mietverhältnis gilt als
                  offiziell beendet, wenn der Mieter alle verbliebenen Artikel
                  aus dem Regal bzw. aus dem Geschäft abgeholt hat. Nachem das Mietverhältnis als beendet gilt, dauert es 7 Werktage bis die Endabrechnung erstellt wird.
                </Typography>
              </li>
              <li sx={{ fontWeight: "bold" }}>
                <Typography
                  style={{
                    fontSize: "18px",
                    wordSpacing: "5px",
                    lineHeight: 2,
                  }}
                >
                  Eventuell anfallende Mahn- und Lagergebühren werden vor der
                  Auszahlung vom Verkaufserlös des Mieters abgezogen.
                </Typography>
              </li>
            </ol>
          </li>

          <li style={{ fontSize: 22, fontWeight: "bold", marginTop: "2%" }}>
            <Typography
              style={{
                fontWeight: "bold",
                fontSize: "22px",
              }}
            >
              Stornierung und Rückerstattung
            </Typography>

            <ol style={{ fontWeight: "normal", fontSize: 18 }}>
              <li sx={{ fontWeight: "bold" }}>
                <Typography
                  style={{
                    fontSize: "18px",
                    wordSpacing: "5px",
                    lineHeight: 2,
                  }}
                >
                  Die Stornierung eines gemieteten Regals ist bis zu zwei Wochen
                  vor Mietbeginn kostenfrei und bei voller Rückerstattung
                  möglich.
                </Typography>
              </li>
              <li sx={{ fontWeight: "bold" }}>
                <Typography
                  style={{
                    fontSize: "18px",
                    wordSpacing: "5px",
                    lineHeight: 2,
                  }}
                >
                  Bis eine Woche vor Mietbeginn erfolgt bei Stornierungen eine
                  Rückerstattung von 50 % der Mietkosten.
                </Typography>
              </li>
              <li sx={{ fontWeight: "bold" }}>
                <Typography
                  style={{
                    fontSize: "18px",
                    wordSpacing: "5px",
                    lineHeight: 2,
                  }}
                >
                  Sollte der Mietzeitraum weniger als eine Woche entfernt
                  liegen, verzichtet der Kunde automatisch auf eine
                  Rückerstattung der Mietkosten
                </Typography>
              </li>
              <li sx={{ fontWeight: "bold" }}>
                <Typography
                  style={{
                    fontSize: "18px",
                    wordSpacing: "5px",
                    lineHeight: 2,
                  }}
                >
                  Wir behalten uns das Recht vor Buchungen ohne Angabe von Gründen jederzeit storneiren und das Mietverhältnis jederzeit beenden zu können.
                </Typography>
              </li>
            </ol>
          </li>

          <li style={{ fontSize: 22, fontWeight: "bold", marginTop: "2%" }}>
            <Typography
              style={{
                fontWeight: "bold",
                fontSize: "22px",
              }}
            >
              Regale Ein- und Ausräumen
            </Typography>

            <ol style={{ fontWeight: "normal", fontSize: 18 }}>
              <li sx={{ fontWeight: "bold" }}>
                <Typography
                  style={{
                    fontSize: "18px",
                    wordSpacing: "5px",
                    lineHeight: 2,
                  }}
                >
                  Mieter können ihre Artikel jeweils einen Werktag vor
                  Mietbeginn, eine Stunde vor Ladenschluss, in die Regale
                  einräumen. Für die angegebenen Artikel werden im Shop
                  entsprechende Etiketten bereitgestellt.
                </Typography>
              </li>
              <li sx={{ fontWeight: "bold" }}>
                <Typography
                  style={{
                    fontSize: "18px",
                    wordSpacing: "5px",
                    lineHeight: 2,
                  }}
                >
                  Während der Mietzeit kann der Mieter neue Artikel online
                  hinzufügen und in den Regalen ausstellen. Die Anzahl der
                  Artikel pro Regal ist nicht begrenzt, außer dass pro Regal nur
                  100 Kleiderbügel gleichzeitig verwendet werden dürfen.
                </Typography>
              </li>
              <li sx={{ fontWeight: "bold" }}>
                <Typography
                  style={{
                    fontSize: "18px",
                    wordSpacing: "5px",
                    lineHeight: 2,
                  }}
                >
                  Am Ende der Mietzeit hat der Mieter bis eine Stunde vor
                  Ladenschluss die restlichen Artikel aus den Regalen zu
                  entfernen. Bei Nichtbeachtung oder Verlassen des Regals in
                  schlechtem Zustand wird eine Bearbeitungsgebühr von 15,00 €
                  erhoben.
                </Typography>
              </li>
            </ol>
          </li>

          <li style={{ fontSize: 22, fontWeight: "bold", marginTop: "2%" }}>
            <Typography
              style={{
                fontWeight: "bold",
                fontSize: "22px",
              }}
            >
              Ein- und Ausräumung des Regals
            </Typography>

            <ol style={{ fontWeight: "normal", fontSize: 18 }}>
              <li sx={{ fontWeight: "bold" }}>
                <Typography
                  style={{
                    fontSize: "18px",
                    wordSpacing: "5px",
                    lineHeight: 2,
                  }}
                >
                  Mieter haben die Möglichkeit, im Rahmen der Buchung eines
                  Regals einen Ein- und Ausräumungsdienst für 15,00 € zu buchen.
                  In diesem Fall muss der Mieter die Gegenstände frühestens 72
                  Stunden und spätestens 48 Stunden vor Mietbeginn im Shop
                  abgeben.
                </Typography>
              </li>
              <li sx={{ fontWeight: "bold" }}>
                <Typography
                  style={{
                    fontSize: "18px",
                    wordSpacing: "5px",
                    lineHeight: 2,
                  }}
                >
                  Der Mieter ist verpflichtet, die restlichen Gegenstände
                  spätestens 48 Stunden nach Ende der Mietzeit abzuholen.
                </Typography>
              </li>
              <li sx={{ fontWeight: "bold" }}>
                <Typography
                  style={{
                    fontSize: "18px",
                    wordSpacing: "5px",
                    lineHeight: 2,
                  }}
                >
                  Werden die restlichen Gegenstände nicht rechtzeitig abgeholt,
                  wird für jeden weiteren Tag eine Lagergebühr von 5,00 € pro
                  Tag erhoben.
                </Typography>
              </li>
            </ol>
          </li>

          <li style={{ fontSize: 22, fontWeight: "bold", marginTop: "2%" }}>
            <Typography
              style={{
                fontWeight: "bold",
                fontSize: "22px",
              }}
            >
              Artikelplatzierung und Sicherheit
            </Typography>

            <ol style={{ fontWeight: "normal", fontSize: 18 }}>
              <li sx={{ fontWeight: "bold" }}>
                <Typography
                  style={{
                    fontSize: "18px",
                    wordSpacing: "5px",
                    lineHeight: 2,
                  }}
                >
                  Es dürfen nur Artikel im angemieteten Regal ausgestellt
                  werden. Außerhalb des Regals platzierte Artikel können
                  jederzeit von uns entfernt werden, wenn
                  diese als unangemessen empfunden werden.
                </Typography>
              </li>
              <li sx={{ fontWeight: "bold" }}>
                <Typography
                  style={{
                    fontSize: "18px",
                    wordSpacing: "5px",
                    lineHeight: 2,
                  }}
                >
                 Alle außerhalb des gemieteten Regals platzierten Artikel, bedürfen einer gesonderten Vereinbarung und unterliegen eigenen Verkaufsbedingungen.
                </Typography>
              </li>
            </ol>
          </li>

          <li style={{ fontSize: 22, fontWeight: "bold", marginTop: "2%" }}>
            <Typography
              style={{
                fontWeight: "bold",
                fontSize: "22px",
              }}
            >
              Regalzuordnung{" "}
            </Typography>

            <ol style={{ fontWeight: "normal", fontSize: 18 }}>
              <li sx={{ fontWeight: "bold" }}>
                <Typography
                  style={{
                    fontSize: "18px",
                    wordSpacing: "5px",
                    lineHeight: 2,
                  }}
                >
                  MinMini behält sich das Recht vor dem Mieter zur
                  betrieblichen Optimierung ein anderes ...Regal als das im Buchungsprozess ausgewählte zuzuweisen.
                </Typography>
              </li>
            </ol>
          </li>

          <li style={{ fontSize: 22, fontWeight: "bold", marginTop: "2%" }}>
            <Typography
              style={{
                fontWeight: "bold",
                fontSize: "22px",
              }}
            >
              Haftung und Rückgabe{" "}
            </Typography>

            <ol style={{ fontWeight: "normal", fontSize: 18 }}>
              <li sx={{ fontWeight: "bold" }}>
                <Typography
                  style={{
                    fontSize: "18px",
                    wordSpacing: "5px",
                    lineHeight: 2,
                  }}
                >
                  MinMini übernimmt keine Haftung für die ausgestellten
                  Gegenstände, auch nicht für später festgestellte Schäden und
                  Mängel.{" "}
                </Typography>
              </li>
              <li sx={{ fontWeight: "bold" }}>
                <Typography
                  style={{
                    fontSize: "18px",
                    wordSpacing: "5px",
                    lineHeight: 2,
                  }}
                >
                  MinMini haftet nicht für Diebstahl, Beschädigung, Verlust,
                  Elementarschäden oder andere Ansprüche im Zusammenhang mit den
                  Artikeln.
                </Typography>
              </li>
              <li sx={{ fontWeight: "bold" }}>
                <Typography
                  style={{
                    fontSize: "18px",
                    wordSpacing: "5px",
                    lineHeight: 2,
                  }}
                >
                  Rückgaben und Umtausch gekaufter Artikel sind ausgeschlossen.
                </Typography>
              </li>
            </ol>
          </li>

          <li style={{ fontSize: 22, fontWeight: "bold", marginTop: "2%" }}>
            <Typography
              style={{
                fontWeight: "bold",
                fontSize: "22px",
              }}
            >
              Mängelrüge
            </Typography>

            <ol style={{ fontWeight: "normal", fontSize: 18 }}>
              <li sx={{ fontWeight: "bold" }}>
                <Typography
                  style={{
                    fontSize: "18px",
                    wordSpacing: "5px",
                    lineHeight: 2,
                  }}
                >
                  Sollte während des Mietverhältnisses ein Mangel an der
                  Mietsache auftreten, hat der Mieter MinMini unverzüglich zu
                  informieren. Bei Nichtbeachtung kann der Mieter für den daraus
                  resultierenden Schaden haftbar gemacht werden, wenn eine
                  rechtzeitige Benachrichtigung den Schaden hätte verhindern
                  oder mildern können.{" "}
                </Typography>
              </li>
            </ol>
          </li>

          <li style={{ fontSize: 22, fontWeight: "bold", marginTop: "2%" }}>
            <Typography
              style={{
                fontWeight: "bold",
                fontSize: "22px",
              }}
            >
              Schriftliche Vereinbarungen
            </Typography>

            <ol style={{ fontWeight: "normal", fontSize: 18 }}>
              <li sx={{ fontWeight: "bold" }}>
                <Typography
                  style={{
                    fontSize: "18px",
                    wordSpacing: "5px",
                    lineHeight: 2,
                  }}
                >
                  Darüber hinausgehende Regelungen oder Vereinbarungen zum
                  Mietverhältnis bedürfen der Schriftform. Mündliche
                  Nebenabreden haben keine Gültigkeit.
                </Typography>
              </li>
            </ol>
          </li>

          <li style={{ fontSize: 22, fontWeight: "bold", marginTop: "2%" }}>
            <Typography
              style={{
                fontWeight: "bold",
                fontSize: "22px",
              }}
            >
              Salvatorische Klausel
            </Typography>

            <ol style={{ fontWeight: "normal", fontSize: 18 }}>
              <li sx={{ fontWeight: "bold" }}>
                <Typography
                  style={{
                    fontSize: "18px",
                    wordSpacing: "5px",
                    lineHeight: 2,
                  }}
                >
                  Sollte eine Bestimmung dieser Allgemeinen Geschäftsbedingungen
                  als ungültig oder nicht durchsetzbar erachtet werden, hat dies
                  keinen Einfluss auf die Gültigkeit oder Durchsetzbarkeit der
                  übrigen Bestimmungen. In diesen Fällen gelten anstelle der
                  unwirksamen Bestimmungen die gesetzlichen Regelungen.
                </Typography>
              </li>
            </ol>
          </li>

          <li style={{ fontSize: 22, fontWeight: "bold", marginTop: "2%" }}>
            <Typography
              style={{
                fontWeight: "bold",
                fontSize: "22px",
              }}
            >
              Anwendbares Recht und Gerichtsstand
            </Typography>

            <ol style={{ fontWeight: "normal", fontSize: 18 }}>
              <li sx={{ fontWeight: "bold" }}>
                <Typography
                  style={{
                    fontSize: "18px",
                    wordSpacing: "5px",
                    lineHeight: 2,
                  }}
                >
                  Diese Vereinbarung unterliegt deutschem Recht und wird in
                  Übereinstimmung mit diesem ausgelegt.
                </Typography>
              </li>
              <li sx={{ fontWeight: "bold" }}>
                <Typography
                  style={{
                    fontSize: "18px",
                    wordSpacing: "5px",
                    lineHeight: 2,
                  }}
                >
                  Ausschließlicher Gerichtsstand für alle Streitigkeiten aus dem
                  Vertragsverhältnis ist das für den Geschäftssitz von MinMini
                  zuständige Gericht.
                </Typography>
              </li>
            </ol>
          </li>
        </ol>
      </Box>
    </div>
  );
};

export default Letter;
