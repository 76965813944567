import { Avatar, Box, Button, Container, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import http from '../../http';
import BasicTable from './BasicTable';
import UpdateForm from './Form';
import PasswordForm from './PasswordForm';
import VerkäufeTable from './VerkäufeTable';
import MeineBuchungTable from './MeineBuchungTable';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Rating from '@mui/material/Rating';
import StarIcon from '@mui/icons-material/Star';
import MenuItem from '@mui/material/MenuItem';
import { useNavigate, useLocation } from 'react-router-dom';
const labels = {
  0.5: 'Useless',
  1: 'Useless+',
  1.5: 'Poor',
  2: 'Poor+',
  2.5: 'Ok',
  3: 'Ok+',
  3.5: 'Good',
  4: 'Good+',
  4.5: 'Excellent',
  5: 'Excellent+',
};

function getLabelText(value) {
  return `${value} Star${value !== 1 ? 's' : ''}, ${labels[value]}`;
}

const Tabs = (props) => {
  const location = useLocation();
  const navigate = useNavigate();

  const [active, setActive] = useState(
    location?.state?.tab ? location?.state?.tab : 0
  );
  window.history.replaceState({}, document.title);

  const [userData, setUserData] = useState(null);
  const [openRating, setOpenRating] = React.useState(false);
  const [value, setValue] = React.useState(2);
  const [feedback, setFeedback] = React.useState('');
  const [hover, setHover] = React.useState(-1);
  const [showRating, setShowRating] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState('paper');

  const handleClickOpenRating = () => {
    setOpenRating(true);
  };

  const handleCloseRating = () => {
    setOpenRating(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
    navigate('/my-profile/stands/2');
  };

  const handleClose = () => {
    setOpen(false);
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  const handleActive = (value) => {
    setActive(value);
  };
  useEffect(() => {
    http
      .request({
        method: 'get',
        maxBodyLength: Infinity,
        url: '/rating/has-user-submitted/rating',
      })
      .then((response) => {
        const { data } = response.data;
        setShowRating(data?.status);
      })
      .catch((error) => {
        console.log(error);
      });
    const fetchUserData = async () => {
      try {
        const response = await http.get('/user'); // Replace with the appropriate API endpoint URL
        setUserData(response.data);
      } catch (error) {
        console.log('Error fetching user data', error);
      }
    };

    fetchUserData();
  }, []);

  const onDeleteClick = () => {
    let data = JSON.stringify({
      rating: value,
      comment: feedback,
      user_id: userData?.data?.id,
    });

    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: '/rating',
      headers: {
        'Content-Type': 'application/json',
      },
      data: data,
    };

    http
      .request(config)
      .then((response) => {
        setOpenRating(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <Container sx={{ minHeight: '100vh' }}>
      <br />
      <Box
        sx={{
          display: 'flex',
          justifyContent: { xs: 'start', sm: 'center' },
          gap: '3rem',
          flexWrap: 'wrap',
          mt: { xs: '1rem', sm: '0' },
          mb: '2.5rem',
        }}
      >
        <Button
          sx={{ width: '9rem', fontWeight: 'bold', textTransform: 'none' }}
          style={{
            background: '#ceccd4',
            color: active === 0 ? 'black' : 'white',
          }}
          onClick={() => handleActive(0)}
        >
          {' '}
          Übersicht
        </Button>
        <Button
          sx={{ width: '9rem', fontWeight: 'bold', textTransform: 'none' }}
          style={{
            background: '#beafa0',
            color: active === 1 ? 'black' : 'white',
          }}
          onClick={() => handleActive(1)}
        >
          Artikel
        </Button>
        <Button
          sx={{ width: '9rem', fontWeight: 'bold', textTransform: 'none' }}
          style={{
            background: '#bac2be',
            color: active === 2 ? 'black' : 'white',
          }}
          onClick={() => handleActive(2)}
        >
          Mein Profil
        </Button>
        <Button
          sx={{ width: '9rem', fontWeight: 'bold', textTransform: 'none' }}
          style={{
            background: '#aea8a2',
            color: active == 3 ? 'black' : 'white',
          }}
          onClick={() => handleActive(3)}
        >
          Verkäufe
        </Button>
        <Button
          sx={{ width: '9rem', fontWeight: 'bold', textTransform: 'none' }}
          style={{
            background: '#8b8c84',
            color: active == 4 ? 'black' : 'white',
          }}
          onClick={() => handleActive(4)}
        >
          Meine Buchung
        </Button>
      </Box>
      {active == 0 && (
        <Box sx={{ minHeight: '100vh' }}>
          <Box
            sx={{
              display: { xs: 'block', sm: 'flex' },
              justifyContent: 'center',
              gap: '2rem',
              // background: "lightGray",
              margin: 'auto',
              mt: 3,
            }}
          >
            <Box
              sx={{
                background: 'white',
                borderRadius: '2rem',
                width: { xs: '100%', sm: '35%', md: '25%' },
                height: '20rem',
                border: '2px solid lightgray',
                p: 3,
              }}
            >
              <Avatar />
              <Typography
                sx={{ pt: 3, pb: 0.5, fontWeight: '700', fontSize: '20px' }}
              >
                {userData?.data?.first_name} {userData?.data?.last_name}
              </Typography>
              <Typography
                sx={{ pt: 0, pb: 1, fontWeight: '500', fontSize: '16px' }}
              >
                {userData?.data?.email}
              </Typography>
              <Typography sx={{ py: 0, fontWeight: '500', fontSize: '16px' }}>
                ID No: {userData?.data?.id}
              </Typography>
              <br />
              <Typography sx={{ pb: 1, fontWeight: '600', fontSize: '18px' }}>
                Referenzcode:
              </Typography>
              <Typography sx={{ fontWeight: '600', fontSize: '22px' }}>
                {userData?.data?.reference_code}
              </Typography>
            </Box>
            <Box
              sx={{
                background: 'white',
                borderRadius: '2rem',
                border: '2px solid lightgray',
                width: { xs: '100%', sm: '35%', md: '25%' },
                height: '20rem',
                display: 'flex',
                paddingLeft: '5%',
                flexDirection: 'column',
                textAlign: 'left',
                justifyContent: 'center',
                mt: { xs: '1rem', sm: '0' },
              }}
            >
              <Box sx={{ fontSize: '20px', fontWeight: '600' }}>Balance :</Box>
              <Typography sx={{ fontSize: '25px', fontWeight: '800' }}>
                $ 0
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              gap: '4rem',
              my: '5rem',
            }}
          >
            <Button
              style={{ background: '#b89981' }}
              variant='contained'
              size='large'
              onClick={handleClickOpen}
            >
              Erweitern Sie Ihren Stand
            </Button>
            <Button
              style={{ background: '#686c7d' }}
              variant='contained'
              size='large'
              disabled={showRating}
              onClick={handleClickOpenRating}
            >
              bewerten Sie uns
            </Button>
          </Box>

          <Box
            sx={{
              background: '#fdfcf6',
              display: { xs: 'block', sm: 'flex' },
              gap: '2rem',
              justifyContent: 'space-around',
              width: '100%',
              m: 'auto',
              mb: '2rem',
              p: 1,
            }}
          >
            <Box
              sx={{
                width: { xs: '100%', sm: '32%' },
                background: '#fff',
                mb: { xs: '1rem', sm: '0' },
                p: 3,
                boxShadow: 2,
                borderRadius: '.5rem',
              }}
            >
              <Typography sx={{ py: '2rem', fontWeight: '600' }}>
                {' '}
                Verkaufte Produkte
              </Typography>
              <Box sx={{ pb: '.5rem', fontWeight: '600' }}> $ 0</Box>
            </Box>
            <Box
              sx={{
                width: { xs: '100%', sm: '32%' },
                background: '#fff',
                mb: { xs: '1rem', sm: '0' },
                p: 3,
                boxShadow: 2,
                borderRadius: '.5rem',
              }}
            >
              <Typography sx={{ py: '2rem', fontWeight: '600' }}>
                Bisherige Verkäufe
              </Typography>
              <Box sx={{ pb: '.5rem', fontWeight: '600' }}>$ 0</Box>
            </Box>
            <Box
              sx={{
                width: { xs: '100%', sm: '32%' },
                background: '#fff',
                mb: { xs: '1rem', sm: '0' },
                p: 3,
                boxShadow: 2,
                borderRadius: '.5rem',
              }}
            >
              <Typography sx={{ py: '1.5rem', fontWeight: '600' }}>
                Bisher ausgezahlt
              </Typography>
              <Box sx={{ pb: '.5rem', fontWeight: '600' }}> $ 0</Box>{' '}
            </Box>
          </Box>
        </Box>
      )}
      {active == 1 && <BasicTable />}
      {active == 2 && (
        <Box sx={{ py: '1rem', minHeight: '100vh' }}>
          <UpdateForm />
          <PasswordForm />
        </Box>
      )}
      {active == 3 && <VerkäufeTable />}
      {active == 4 && <MeineBuchungTable />}
      <Dialog
        open={openRating}
        onClose={handleCloseRating}
        style={{ zIndex: 99999 }}
      >
        <DialogTitle>
          Rating
          <IconButton
            aria-label='close'
            onClick={handleCloseRating}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please give us your valuable feedback.
          </DialogContentText>
          <br />
          <Box
            sx={{
              width: 200,
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Rating
              name='hover-feedback'
              value={value}
              precision={0.5}
              getLabelText={getLabelText}
              onChange={(event, newValue) => {
                setValue(newValue);
              }}
              onChangeActive={(event, newHover) => {
                setHover(newHover);
              }}
              emptyIcon={
                <StarIcon style={{ opacity: 0.55 }} fontSize='inherit' />
              }
            />
            {value !== null && (
              <Box sx={{ ml: 2 }}>{labels[hover !== -1 ? hover : value]}</Box>
            )}
          </Box>
          <br />
          <TextField
            autoFocus
            margin='dense'
            id='name'
            label='Feedback'
            type='text'
            multiline
            rows={3}
            fullWidth
            onChange={(event) => setFeedback(event.target.value)}
            value={feedback}
            variant='standard'
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseRating}>Cancel</Button>
          <Button onClick={onDeleteClick}>Subscribe</Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default Tabs;
