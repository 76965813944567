import { Avatar, Box, Button, Container, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import http from '../http';
import { useNavigate, useLocation, Outlet } from 'react-router-dom';
import { Logout } from '@mui/icons-material';
const Tabs = (props) => {
  const location = useLocation();
  const navigate = useNavigate();

  const [active, setActive] = useState(
    location?.state?.tab ? location?.state?.tab : 0
  );
  window.history.replaceState({}, document.title);

  const [userData, setUserData] = useState(null);
  const [openRating, setOpenRating] = React.useState(false);
  const [value, setValue] = React.useState(2);
  const [feedback, setFeedback] = React.useState('');
  const [showRating, setShowRating] = useState(false);
  const [open, setOpen] = React.useState(false);

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  const handleActive = (value) => {
    setActive(value);
  };

  useEffect(() => {
    http
      .request({
        method: 'get',
        maxBodyLength: Infinity,
        url: '/rating/has-user-submitted/rating',
      })
      .then((response) => {
        const { data } = response.data;
        setShowRating(data?.status);
      })
      .catch((error) => {
        console.log(error);
      });
    const fetchUserData = async () => {
      try {
        const response = await http.get('/user'); // Replace with the appropriate API endpoint URL
        setUserData(response.data);
      } catch (error) {
        console.log('Error fetching user data', error);
      }
    };

    fetchUserData();
  }, []);

  const handleLogout = async () => {
    try {
      let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `/auth/logout`,
        headers: {},
      };

      await http.request(config);

      localStorage.clear();
      navigate('/');
      window.location.reload();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Container sx={{ minHeight: '100vh' }}>
      <br />
      <Box
        sx={{
          display: 'flex',
          justifyContent: { xs: 'start', sm: 'center' },
          gap: '3rem',
          flexWrap: 'wrap',
          mt: { xs: '1rem', sm: '0' },
          mb: '2.5rem',
        }}
      >
        <Button
          sx={{ width: '9rem', fontWeight: 'bold', textTransform: 'none' }}
          style={{
            background: '#ceccd4',
            color: active === 0 ? 'black' : 'white',
          }}
          onClick={() => navigate('/my-profile/dashboard')}
        >
          {' '}
          Übersicht
        </Button>
        <Button
          sx={{ width: '9rem', fontWeight: 'bold', textTransform: 'none' }}
          style={{
            background: '#beafa0',
            color: active === 1 ? 'black' : 'white',
          }}
          onClick={() => navigate('/my-profile/products')}
        >
          Artikel
        </Button>
        <Button
          sx={{ width: '9rem', fontWeight: 'bold', textTransform: 'none' }}
          style={{
            background: '#bac2be',
            color: active === 2 ? 'black' : 'white',
          }}
          onClick={() => navigate('/my-profile/profile')}
        >
          Mein Profil
        </Button>
        <Button
          sx={{ width: '9rem', fontWeight: 'bold', textTransform: 'none' }}
          style={{
            background: '#8b8c84',
            color: active == 4 ? 'black' : 'white',
          }}
          onClick={() => navigate('/my-profile/bookings')}
        >
          Meine Buchung
        </Button>

        <Button
          sx={{ width: '9rem', fontWeight: 'bold', textTransform: 'none' }}
          startIcon={<Logout />}
          style={{
            // background: "#8b8c84",
            color: 'black',
          }}
          onClick={handleLogout}
        >
          Logout
        </Button>
      </Box>
      {props.children}
    </Container>
  );
};

export default Tabs;
