import { Box, Typography } from "@mui/material";
import logo from "../../img/logo.png";
import React from "react";

const Urban = () => {
  return (
    <div style={{ padding: "20px" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection:{xs:"column-reverse", lg:"row"},
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            display: "flex",
            marginTop: "20px",
            justifyContent: "center",
            width: "100%",
            // marginLeft: "220px",
          }}
        >
          <Typography
            style={{
              fontWeight: "bold",
              textAlign: "center",
              fontSize: "30px",
            }}
          >
            Über uns
          </Typography>
        </Box>


        <img src={logo} width="200px" height="200px" />
      
      </Box>

      <Box className="text-container">
        <Typography
          style={{
            fontSize: "18px",
            wordSpacing: "5px",
            lineHeight: 2,
          }}
          >
          Als Eltern einer wundervollen Tochter stehen wir regelmäßig vor den gleichen Problemen wie wahrscheinlich alle Eltern: Die Kleidung ist plötzlich wieder zu klein, das Lieblingsspielzeug von heute auf morgen langweilig geworden und im Kinderwagen liegen ist mit zunehmender, eigener Mobilität auch nicht mehr erwünscht. Wohin dann mit den nicht mehr benötigten Dingen? Klar, vieles findet im Familien- und Freundeskreis glückliche Abnehmer und das eine oder andere wird natürlich auch aufbewahrt. Aber der größte Teil findet seinen Platz in Boxen, die dann irgendwann mal verkauft werden sollen. Da der Tag mit Kindern aber selten genug Stunden hat, findet man oft nicht die Zeit, dies auch wirklich in Angriff zu nehmen. Schon in der Schwangerschaft sind wir gerne nach Dänemark gefahren, um in den dortigen Kinder-Second Hand Läden zu stöbern. Das für Eltern unkomplizierte Verkaufen und Kaufen gebrauchter Kinderausstattung begeistert uns jedes Mal auf’s Neue und so entstand die Idee, dieses Konzept in die Kieler Umgebung zu bringen. Wir möchten anderen Eltern ebenfalls die Möglichkeit bieten, ihre nicht mehr benötigten Dinge zu verkaufen und auf der anderen Seite gut erhaltene Kleidung, Spielzeug, etc. kaufen zu können.
          <br></br><br></br>
          Gebt den Sachen eurer Kinder eine 2. Chance - ganz ohne lästiges fotografieren und inserieren oder stundenlangem Stehen auf Flohmärkten. Wir übernehmen den kompletten Verkauf für euch.
        </Typography>
      </Box>
    </div>
  );
};

export default Urban;
