import React from "react";
import image from "../../img/bg-ico-hero.jpg";
import Herologo from "../home/HeroLogo";
import { Card, CardMedia, CardContent, Typography } from "@mui/material";
function Promo() {
  return (
    <div>
      <Herologo />
      <div style={{ position: "relative" }}>
        <CardMedia
          component="img"
          image={image}
          style={{
            // height: "100vh",
            objectPosition: "center",
            width: "100%",
            objectFit: "cover",
          }}
        />
        <Typography
          sx={{
            position: "absolute",
            color: "white",
            top: { xs: 10, sm: 20, md: 40, lg: 60 },
            textAlign: "center",
            left: "50%",
            transform: {
              xs: "translateX(-50%)",
              md: "translateX(-50%)",
              lg: "translateX(-50%)",
            },
            fontSize: { xs: 20, sm: 50, md: 70, lg: 90 },
            fontWeight: "bold",
          }}
        >
          Coming soon...
        </Typography>

        <Typography
          sx={{
            position: "absolute",
            color: "white",
            bottom: { xs: 7, sm: 10, md: 15, lg: 20 },
            textAlign: "end",
            marginRight: 40,
            fontSize: { xs: 7, sm: 20, md: 30, lg: 40 },
            fontWeight: "bold",
            display: "flex",
            alignItems: "center",
            justifyContent: "end",
            width: "100%",
            marginRight: "20px",
          }}
        >
          <span
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "end",
            }}
          >
            <span style={{}}>Du möchtest keine Infos verpassen?</span>
            <span style={{}}>Dann folge uns auf Instagram</span>
          </span>

          <CardMedia
          component={"img"}
          image={"/images/q.png"}
            alt="main hero image"
            sx={{
              height:{xs:35,sm:60,md:100, lg:130},
              width:{xs:35,sm:60,md:100, lg:130},
              // objectPosition: "center",
              // objectFit: "cover",
            }}
          />
        </Typography>
      </div>
      {/* <img
        src={image}
        alt="main hero image"
        style={{
          // height: "80vh",
          objectPosition: "center",
          width: "100vw",
          objectFit: "cover",
        }}
      /> */}

      {/* <Footer /> */}
    </div>
  );
}

export default Promo;
