import { Box, Typography } from "@mui/material";
import logo from "../../img/logo.png";
import React from "react";

const Urban = () => {
  return (
    <div style={{ padding: "20px" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection:{xs:"column-reverse", lg:"row"},
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            display: "flex",
            marginTop: "20px",
            justifyContent: "center",
            width: "100%",
            // marginLeft: "220px",
          }}
        >
          <Typography
            style={{
              fontWeight: "bold",
              textAlign: "center",
              fontSize: "30px",
            }}
          >
            Widerrufsbelehrung
          </Typography>
        </Box>


        <img src={logo} width="200px" height="200px" />
      
      </Box>

      <Box className="text-container">
        <ol style={{ fontWeight: "bold" }} className="orderd-list">
          <li sx={{ fontSize: 22, fontWeight: "bold", marginTop: "2%" }}>
            <Typography
              style={{
                fontWeight: "bold",
                fontSize: "22px",
              }}
            >
              Widerrufsrecht
            </Typography>

            <Typography
              style={{
                fontSize: "18px",
                wordSpacing: "5px",
                lineHeight: 2,
              }}
            >
             Sie haben das Recht, binnen vierzehn Tagen ohne Angabe von Gründen diesen Vertrag zu widerrufen.<br></br>
              Die Widerrufsfrist beträgt 14 Tage nach erhalt der Buchungsbestätigung.<br></br>
              Liegt zwischen Buchungsbestätigung und Mietbeginn weniger als 14 Tage, verzichten Sie auf Ihr Widerrufsrecht. In diesem Fall gelten die in den AGB getroffenen Bestimmungen.
              <br></br><br></br>
              Diese lauten wie folgt:<br></br>
              Bis sieben Tage vor Mietbeginn erfolgt bei Stornierungen eine Rückerstattung von 50 % der Mietkosten. Bei weniger als sieben Tagen bis Mietbeginn, verzichtet der Kunde im Falle einer Stornierung automatisch auf eine Rückerstattung der Mietkosten.
              Um Ihr Widerrufsrecht auszuüben, müssen Sie uns (Mirzoyan GbR, Kieler Straße 113, 24119 Kronshagen) mittels einer eindeutigen Erklärung (z. B. eine E-Mail oder per Post) über Ihren Entschluss, diesen Vertrag zu widerrufen, informieren.
              Zur Wahrung der Widerrufsfrist reicht es aus, dass Sie die Mitteilung über die Ausübung des Widerrufsrechts vor Ablauf der Widerrufsfrist absenden.
            </Typography>
          </li>

          <li style={{ fontSize: 22, fontWeight: "bold", marginTop: "2%" }}>
            <Typography
              style={{
                fontWeight: "bold",
                fontSize: "22px",
              }}
            >
              Folgen des Widerrufs
            </Typography>
                <Typography
                  style={{
                    fontSize: "18px",
                    wordSpacing: "5px",
                    lineHeight: 2,
                  }}
                >
                  Wenn Sie diesen Vertrag widerrufen, haben wir Ihnen alle Zahlungen, die wir von Ihnen erhalten haben, einschließlich der Lieferkosten (mit Ausnahme der zusätzlichen Kosten, die sich daraus ergeben, dass Sie eine andere Art der Lieferung als die von uns angebotene, günstigste Standardlieferung gewählt haben), unverzüglich und spätestens binnen vierzehn Tagen ab dem Tag zurückzuzahlen, an dem die Mitteilung über Ihren Widerruf dieses Vertrags bei uns eingegangen ist. Für diese Rückzahlung verwenden wir dasselbe Zahlungsmittel, das Sie bei der ursprünglichen Transaktion eingesetzt haben, es sei denn, mit Ihnen wurde ausdrücklich etwas anderes vereinbart; in keinem Fall werden Ihnen wegen dieser Rückzahlung Entgelte berechnet.
                </Typography>
          </li>
        </ol>
      </Box>
    </div>
  );
};

export default Urban;
