import { Typography } from "@mui/material";
import * as React from "react";
import BannerImage from "./BannerImage";
import Footer from "./Footer";
import HomeCards from "./HomeCards";
import LowerButton from "./LowerButton";
import LowerText from "./LowerText";
import Maps from "./Maps";
import UpperText from "./UpperText";
import HeroLogo from "./HeroLogo";
export default function Home() {
  return (
    <>
    <HeroLogo />
      <BannerImage />

      <UpperText />
      <HomeCards />
      <LowerButton />
      <Maps />
    </>
  );
}
