import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Spinner } from "../Spinner/Spinner";

export default function AlertDialogSlide({ error, setError, onSuccess, applying }) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div style={{marginLeft: "auto", marginRight: "auto"}}>
      <Button
        type="button"
        sx={{ textTransform: "capitalize" }}
        variant="contained"
        style={{ background: "#b89981" }}
        size="large"
        onClick={handleClickOpen}
        disabled={applying}
      >
        {applying ? "Bitte warten…" : "Rabatt Bestätigen"}
      </Button>

      {applying ? <Spinner text={"Rabattierung wird vorgenommen - das kann einen Moment dauern…"} /> : null}

      <Dialog
        open={open}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"BITTE BEACHTE!"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Der Rabatt gilt für alle Artikel und kann nicht rückgängig gemacht werden.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>NEIN</Button>
          <Button
            onClick={() => {
                handleClose()
              onSuccess();
            }}
          >
            JA
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
