import * as React from 'react';
import {
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  TableSortLabel,
  Paper,
  Tooltip,
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import http from '../../http';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Typography } from '@mui/material';
import Pagination from './Pagination';
import { Spinner } from '../Spinner/Spinner';

export default function BasicTable() {
  const [products, setProducts] = React.useState([]);
  const [orderBy, setOrderBy] = React.useState('');
  const [order, setOrder] = React.useState('asc');
  const [page, setPage] = React.useState(0);
  const [isLoading, setIsLoading] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(50);
  const [searchValue, setSearchValue] = React.useState('');

  const navigate = useNavigate();

  const handleRequestSort = (property) => {
    const isAscending = orderBy === property && order === 'asc';
    setOrder(isAscending ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const formatPrice = (value) => {
    let price = parseFloat(value).toFixed(2);
    price = price.toLocaleString();
    let priceRaw = price.split('.');
    priceRaw[0] = priceRaw[0].replace(',', '.');
    price = priceRaw[0] + ',' + priceRaw[1];
    return price;
  };

  const filteredProducts = React.useMemo(() => {
    return products.filter((product) => {
      if (searchValue) {
        return (
          product.title?.toLowerCase().includes(searchValue.toLowerCase()) ||
          product.manufacturer
            ?.toLowerCase()
            .includes(searchValue.toLowerCase()) ||
          product.sku?.toLowerCase().includes(searchValue.toLowerCase()) ||
          moment(product.createdAt)
            .format('DD-MM-YYYY')
            .includes(searchValue) ||
          formatPrice(product.price)?.toString().includes(searchValue)
        );
      }
      return true;
    });
  }, [products, searchValue]);

  const sortedProducts = React.useMemo(() => {
    return filteredProducts.sort((a, b) => {
      if (order === 'asc') {
        return a[orderBy] < b[orderBy] ? -1 : 1;
      } else {
        return a[orderBy] > b[orderBy] ? -1 : 1;
      }
    });
  }, [filteredProducts, orderBy, order]);

  const onPageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 1);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(
      event,
      Math.max(0, Math.ceil(sortedProducts?.length / rowsPerPage) - 1)
    );
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  React.useEffect(() => {
    const fetchUserData = async () => {
      try {
        const id = localStorage.getItem('id');
        const response = await http.get(`/product/get-all-by-user-id/${id}`);
        setProducts(response.data.data);
      } catch (error) {
        console.log('Error fetching user data', error);
      }
    };

    const updateUserData = async () => {
      try {
        const id = localStorage.getItem('id');
        await http.get(`/product/update-all-by-user-id/${id}`);
      } catch (error) {
        console.log('Error updating user data', error);
      }
    };

    fetchUserData();
    updateUserData();
  }, []);

  const postToPOS = () => {
    setIsLoading(true);
    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: '/product/add/all/user-products-to-pos',
    };

    http
      .request(config)
      .then((response) => {
        alert(
          'All of your products are added successfully to the POS and ready to sell!'
        );
      })
      .catch(function (error) {
        if (error.response) {
          console.log(error.response.data);
          alert(error.response.data.message);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          console.log(error.request);
          alert(error.request);
        } else {
          console.log('Error', error.message);
          alert(error.message);
        }
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <Box sx={{ minHeight: '100vh' }}>
      <Box sx={{ display: 'flex', justifyContent: 'center', gap: '2rem' }}>
        <Button
          sx={{
            ':hover': {
              bgcolor: '#c7ccc8',
            },
            width: '14rem',
            height: '6rem',
            background: '#c7ccc8',
            color: 'black',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            p: 0.5,
            borderRadius: '1rem',
          }}
          onClick={() => {
            navigate(`/add_products`);
          }}
        >
          <Typography sx={{ fontSize: '18px' }}>Weitere Artikel </Typography>{' '}
          <Typography sx={{ fontSize: '18px' }}>hinzufügen</Typography>
        </Button>

        <Button
          disabled={isLoading}
          sx={{
            ':hover': {
              bgcolor: '#8b8c84',
            },
            width: '14rem',
            height: '6rem',
            p: 0.5,
            background: '#8b8c84',
            color: 'white',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            borderRadius: '1rem',
          }}
          onClick={() => postToPOS()}
        >
          <Typography sx={{ fontSize: '16px' }}>Alle Artikel </Typography>{' '}
          <Typography sx={{ fontSize: '16px' }}>hinzugefügt?</Typography>{' '}
          <Typography sx={{ fontSize: '16px' }}>Hier bestätigen!</Typography>
        </Button>

        <Button
          sx={{
            ':hover': {
              bgcolor: '#c7ccc8',
            },
            width: '14rem',
            height: '6rem',
            background: '#c7ccc8',
            color: 'black',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            p: 0.5,
            borderRadius: '1rem',
          }}
          onClick={() => {
            navigate(`/apply_discount`);
          }}
        >
          <Typography sx={{ fontSize: '18px' }}>
            <span style={{ textDecoration: 'underline' }}>ALLE</span> ARTIKEL
            RABATTIEREN
          </Typography>
        </Button>
      </Box>

      {isLoading ? (
        <Spinner
          text={
            'Artikel werden übermittelt - das kann einen Moment dauern. Den Ladevorgang bitte nicht abbrechen bzw. nicht aktualiseren!'
          }
        />
      ) : (
        <Box sx={{ boxShadow: 1, p: 2, margin: 2 }}>
          <Typography sx={{ fontSize: '20px', fontWeight: 'bold' }}>
            Übersicht deiner Artikel
          </Typography>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <select
              style={{ padding: '6px', marginBottom: '6px' }}
              onChange={handleChangeRowsPerPage}
            >
              {[50, 80, 100, 200, 500].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  zeigen {pageSize}
                </option>
              ))}
            </select>
            <input
              type='search'
              onChange={(event) => setSearchValue(event.target.value)}
              placeholder='Suche...'
              style={{ padding: '8px', marginBottom: '6px' }}
            />
          </div>
          <TableContainer component={Paper}>
            <Table>
              <TableHead sx={{ bgcolor: '#e4f0f1' }}>
                <TableRow>
                  <TableCell align='left' sx={{ fontWeight: '600' }}>
                    <TableSortLabel
                      active={orderBy === 'sku'}
                      direction={orderBy === 'sku' ? order : 'asc'}
                      onClick={() => handleRequestSort('sku')}
                    >
                      Artikelnummer
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align='left' sx={{ fontWeight: '600' }}>
                    <TableSortLabel
                      active={orderBy === 'title'}
                      direction={orderBy === 'title' ? order : 'asc'}
                      onClick={() => handleRequestSort('title')}
                    >
                      Bezeichnung
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align='left' sx={{ fontWeight: '600' }}>
                    <TableSortLabel
                      active={orderBy === 'brand_name'}
                      direction={orderBy === 'brand_name' ? order : 'asc'}
                      onClick={() => handleRequestSort('brand_name')}
                    >
                      Marke
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align='left' sx={{ fontWeight: '600' }}>
                    <TableSortLabel
                      active={orderBy === 'quantity'}
                      direction={orderBy === 'quantity' ? order : 'asc'}
                      onClick={() => handleRequestSort('quantity')}
                    >
                      Menge
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align='left' sx={{ fontWeight: '600' }}>
                    <TableSortLabel
                      active={orderBy === 'price'}
                      direction={orderBy === 'price' ? order : 'asc'}
                      onClick={() => handleRequestSort('price')}
                    >
                      Preis
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align='left' sx={{ fontWeight: '600' }}>
                    <TableSortLabel
                      active={orderBy === 'is_added_to_pos'}
                      direction={orderBy === 'is_added_to_pos' ? order : 'asc'}
                      onClick={() => handleRequestSort('is_added_to_pos')}
                    >
                      Status
                    </TableSortLabel>
                  </TableCell>
                  <TableCell
                    align='left'
                    sx={{
                      fontWeight: '600',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <Tooltip
                      title='Anzeige aktualisiert sich täglich um 01:00 Uhr'
                      arrow
                    >
                      <InfoIcon
                        sx={{
                          marginRight: 1,
                          fontSize: '20px',
                          cursor: 'pointer',
                        }}
                      />
                    </Tooltip>
                    <TableSortLabel
                      active={orderBy === 'inventory_change_timestamps'}
                      direction={
                        orderBy === 'inventory_change_timestamps'
                          ? order
                          : 'asc'
                      }
                      onClick={() =>
                        handleRequestSort('inventory_change_timestamps')
                      }
                    >
                      Verkaufsdatum
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align='left' sx={{ fontWeight: '600' }}>
                    <TableSortLabel
                      active={orderBy === 'createdAt'}
                      direction={orderBy === 'createdAt' ? order : 'asc'}
                      onClick={() => handleRequestSort('createdAt')}
                    >
                      Hinzugefügt am
                    </TableSortLabel>
                  </TableCell>
                  <TableCell
                    align='left'
                    sx={{ fontWeight: '600' }}
                  ></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedProducts
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((valued, index) => (
                    <TableRow key={index} sx={{ background: '#F9F9F9 ' }}>
                      <TableCell>{valued.sku}</TableCell>
                      <TableCell align='left'>{valued.title}</TableCell>
                      <TableCell align='left'>{valued.brand_name}</TableCell>
                      <TableCell align='left'>{valued.quantity}</TableCell>
                      <TableCell align='left'>
                        {formatPrice(valued.price)}
                      </TableCell>
                      <TableCell align='left'>
                        {valued?.is_added_to_pos == 1
                          ? 'Aktiv'
                          : valued?.is_added_to_pos == 2
                          ? 'Verkauft'
                          : 'Neu'}
                      </TableCell>
                      <TableCell align='left' sx={{ whiteSpace: 'pre-wrap' }}>
                        {valued?.inventory_change_timestamps
                          ?.split(',')
                          .map((timestamp, index) => {
                            const formattedDate = moment(
                              new Date(timestamp)
                            ).format('DD-MM-YYYY');
                            return (
                              <div key={index}>
                                {index + 1}. {formattedDate}
                              </div>
                            );
                          })}
                      </TableCell>
                      <TableCell align='left'>
                        {moment(valued.createdAt).format('DD-MM-YYYY')}
                      </TableCell>
                      <TableCell align='left'>
                        {valued.is_added_to_pos === 0 && (
                          <Button
                            onClick={() => {
                              navigate(`/update_products/${valued?.id}`);
                            }}
                            style={{
                              borderRadius: '20px',
                              backgroundColor: '#d0a667',
                              color: 'white',
                            }}
                          >
                            Bearbeiten
                          </Button>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>

          <Box sx={{ p: 2, display: 'flex', justifyContent: 'end' }}>
            <Pagination
              products={sortedProducts}
              page={page}
              rowsPerPage={rowsPerPage}
              handleFirstPageButtonClick={handleFirstPageButtonClick}
              handleBackButtonClick={handleBackButtonClick}
              handleNextButtonClick={handleNextButtonClick}
              handleLastPageButtonClick={handleLastPageButtonClick}
              handleChangePage={onPageChange}
            />
          </Box>
        </Box>
      )}
    </Box>
  );
}
