import {
  Avatar,
  Box,
  Button,
  Container,
  Grid,
  Typography,
} from '@mui/material';
import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import MeineBuchungTable from './MeineBuchungTable';
import http from '../../http';
import RecentBookings from './RecentBookings';
import LatestTransactions from './LatestTransactions';
import StandsLists from './StandsLists';
import CategoriesLists from './CategoriesLists';
import Holidays from './Holidays';
import {
  PiCurrencyEurBold,
  PiArrowsHorizontalFill,
  PiListChecksDuotone,
  PiUsers,
} from 'react-icons/pi';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  // textAlign: 'center',
  // color: theme.palette.text.secondary,
}));
const AdminTabs = () => {
  const [active, setActive] = useState(0);
  const [userData, setUserData] = useState(null);
  const [userStats, setUserStats] = useState(null);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await http.get('/user'); // Replace with the appropriate API endpoint URL
        setUserData(response?.data?.data);
      } catch (error) {
        console.log('Error fetching user data', error);
      }
    };

    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: 'stats/get-all-users-stats',
    };
    http
      .request(config)
      .then((response) => {
        const { data } = response.data;
        setUserStats(data);
      })
      .catch((error) => {
        console.log(error);
      });

    fetchUserData();
  }, []);

  return (
    <Box sx={{ pb: '2rem' }}>
      <br />
      <Box sx={{ m: 'auto' }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
          }}
        >
          <h1 style={{ fontWeight: 700, fontSize: '20px' }}>Mein Bereich</h1>
        </Box>

        <Box
          sx={{
            display: { xs: 'block', sm: 'flex' },
            justifyContent: 'center',
            gap: '2rem',
            // background: "lightGray",
            margin: 'auto',
            mt: 3,
          }}
        >
          <Item
            sx={{
              background: 'white',
              borderRadius: '1rem',
              minWidth: { xs: '100%', sm: '40%', md: '28%' },
              minHeight: '20rem',
              p: 3,
            }}
          >
            <Avatar />
            <Typography
              sx={{ pt: 3, pb: 0.5, fontWeight: '600', fontSize: '22px' }}
            >
              {`${userData?.first_name} ${userData?.last_name}`}
            </Typography>
            <Typography
              sx={{
                pt: 0,
                pb: 1,
                fontWeight: '500',
                fontSize: '20px',
                overflowWrap: 'break-word',
              }}
            >
              {`${userData?.email}`}
            </Typography>
          </Item>

          <Grid container spacing={2} style={{ width: '90%', margin: 'auto' }}>
            <Grid item xs={12} sm={6} md={4}>
              <Item style={{ padding: '10px' }}>
                <Box style={{ display: 'flex' }}>
                  <h4>
                    <PiCurrencyEurBold
                      style={{
                        height: '30px',
                        width: '30px',
                        color: '#f5c13d',
                      }}
                    />
                  </h4>
                  <h4 style={{ lineHeight: '30px', fontWeight: 600 }}>
                    Gesamtumsatz
                  </h4>
                </Box>
                <br />
                <Box style={{ display: 'flex', paddingTop: '10px' }}>
                  <h1>€ {`${userStats?.total_revenue}`}</h1>
                </Box>
              </Item>
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <Item style={{ padding: '10px' }}>
                <Box style={{ display: 'flex' }}>
                  <h4>
                    <PiArrowsHorizontalFill
                      style={{
                        height: '30px',
                        width: '30px',
                        color: '#3419f5',
                      }}
                    />
                  </h4>
                  <h4 style={{ lineHeight: '30px', fontWeight: 600 }}>
                    Gesamtbuchungen
                  </h4>
                </Box>
                <br />
                <Box style={{ display: 'flex', paddingTop: '10px' }}>
                  <h1>{`${userStats?.total_bookings}`}</h1>
                </Box>
              </Item>
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <Item style={{ padding: '10px' }}>
                <Box style={{ display: 'flex', alignItems: 'center' }}>
                  <h4>
                    <PiUsers
                      style={{
                        height: '30px',
                        width: '30px',
                        color: '#3419f5',
                      }}
                    />
                  </h4>
                  <h4
                    style={{
                      lineHeight: '30px',
                      fontWeight: 800,
                      marginLeft: '6px',
                    }}
                  >
                    Gesamtzahl der Benutzer
                  </h4>
                </Box>
                <br />
                <Box style={{ display: 'flex', paddingTop: '10px' }}>
                  <h1>{`${userStats?.total_user}`}</h1>
                </Box>
              </Item>
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <Item style={{ padding: '10px' }}>
                <Box style={{ display: 'flex' }}>
                  <h4>
                    <PiListChecksDuotone
                      style={{
                        height: '30px',
                        width: '30px',
                        color: '#3419f5',
                      }}
                    />
                  </h4>
                  <h4 style={{ lineHeight: '30px', fontWeight: 600 }}>
                    Gesamtzahl der aktiven Buchungen
                  </h4>
                </Box>
                <br />
                <Box style={{ display: 'flex', paddingTop: '10px' }}>
                  <h1>{`${userStats?.total_active_bookings}`}</h1>
                </Box>
              </Item>
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <Item style={{ padding: '10px' }}>
                <Box style={{ display: 'flex' }}>
                  <h4>
                    <PiListChecksDuotone
                      style={{
                        height: '30px',
                        width: '30px',
                        color: '#3419f5',
                      }}
                    />
                  </h4>
                  <h4 style={{ lineHeight: '30px', fontWeight: 600 }}>
                    Gesamtzahl der Stände
                  </h4>
                </Box>
                <br />
                <Box style={{ display: 'flex', paddingTop: '10px' }}>
                  <h1>{`${userStats?.number_of_stands_booked}`}</h1>
                </Box>
              </Item>
            </Grid>
          </Grid>
        </Box>

        {/* Booking and Transaction Tables */}
        <br />
        <RecentBookings />
        <br />
        <LatestTransactions />
      </Box>
    </Box>
  );
};

export default AdminTabs;
