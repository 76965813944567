const QuestionData=[
    {
        question:"Wie kann ich bei euch ein Regal buchen?",
        answer:"Der Buchungsprozess erfolgt über unsere Homepage über den Reiter Regal mieten."
    },
    // {
    //     question:"Welche Zahlungsarten bietet ihr an?",
    //     answer:"Aktuell akzeptieren wir Zahlungen per Sofort-Überweisung, Paypal und Visa- oder Mastercard."
    // },
    {
        question:"Wann kann ich mein gemietetes Regal füllen und wann muss es wieder geräumt sein?",
        answer:"Du kannst dein Regal frühestens einen Werktag vor Mietbeginn, eine Stunde vor Ladenschluss, füllen. Ab Mietbeginn ganztägig während der Öffnungszeiten auffüllen. Das Regal muss eine Stunde vor Ladenschluss, am Tag des Mietendes, ausgeräumt sein."
    },
    {
        question:"Wer hält meinen Stand während meiner Mietlaufzeit ordentlich?",
        answer:`Wir sorgen täglich für eine grundlegende Ordnung im Laden, wie beispielsweise das Vermeiden von Kleidungsstücken auf
        dem Boden. Jeder Mieter ist jedoch selbst dafür verantwortlich, die Ordnung und Präsentation seiner Waren im Regal
        aufrechtzuerhalten.`
    },
    {
        question:"Was darf ich nicht zum Verkauf anbieten?",
        answer:`Beschädigte, nicht funktionierende oder stark verschmutzte Kleidungsstücke dürfen nicht zum Verkauf angeboten werden.
        Ebenfalls bitten wir darum, keine gefälschten Produkte anzubieten. Wenn du unsicher bist, frage gerne bei uns nach.`
    },
    {
        question:"Gibt es eine maximale Anzahl an Artikeln, die ich in meinem Regal verkaufen darf?",
        answer:`Wir schreiben keine begrenzte Anzahl von Artikeln vor, empfehlen jedoch, das Regal nicht übermäßig voll zu machen.
        Volle Regale wirken oft unübersichtlich und unordentlich, was potenzielle Käufer abschrecken könnte.`
    },
    {
        question:"Kann ich mein Regal während meiner Mietlaufzeit mit weiteren Artikeln auffüllen?",
        answer:`Du kannst beliebig oft Artikel austauschen, neu auffüllen oder aus dem Regal entfernen. Bitte gib uns an der Kasse
        Bescheid, wenn du Artikel wieder mit nach Hause nimmst. Vergiss nicht neue Artikel auf der Homepage einzutragen,
        damit du weitere Preisetiketten von uns erhältst.`
    },
    {
        question:"Was passiert, wenn ich es nicht schaffe mein Regal rechtzeitig bei Mietende zu räumen?",
        answer:`Wir verstehen, dass immer etwas Unvorhergesehenes passieren kann. Bitte informiere uns rechtzeitig und wir finden eine
        Lösung. Sollten wir keine Benachrichtigung erhalten und das Regal eine Stunde vor Feierabend am letzten Miettag immer
        noch nicht geräumt sein, berechnen wir 15,- € für das Leerräumen sowie fünf Euro pro Tag für die Lagerung.`
    },
    {
        question:"Kann ich auch meinen Kinderwagen, Autositz, etc. bei euch verkaufen?",
        answer:`Auf Anfrage kannst du auch größere Gegenstände bei uns verkaufen. Wenn du ein Regal bei uns
        gemietet hast, ist dies im Mietpreis enthalten. Wenn du kein Regal bei uns hast, berechnen wir eine Provision i.H.v 25 % des
        Verkaufspreises.`
    },
    {
        question:"Ich möchte selbstgemachte Dinge verkaufen. Kann ich dafür ein Regal bei euch mieten?",
        answer:`Du kannst auch selbstgemachte Dinge bei uns verkaufen. Bedenke jedoch, dass dies als gewerbliche Tätigkeit gilt und
        versteuert werden muss. Wir übernehmen keine Beratung oder Haftung in diesem Zusammenhang.`
    },
    {
        question:"Wie erhalte ich die Auszahlung meines Verkaufsumsatzes?",
        answer:`Am Ende deiner Mietlaufzeit erhältst du eine Rechnung, auf der dein gesamter Verkaufsumsatz sowie die zu zahlende
        Provision ausgewiesen sind. Die Differenz wird dir per Überweisung gutgeschrieben. Nachdem du dein Regal ausgeräumt hast dauert es 7 Werktage bis deine Abrechnung erstellt wird.`
    }
]

export default QuestionData;