import axios from 'axios';

axios.defaults.baseURL = 'https://minmini.de/api/api';
// axios.defaults.baseURL = 'http://localhost:8000/api';
axios.defaults.withCredentials = true;

const instance = axios.create({
  headers: {
    'Content-Type': 'application/json',
  },
});

let retryAttempt = false;

instance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalConfig = error.config;

    if (originalConfig.url !== '/auth/login' && error.response) {
      if (error.response.status === 401 && !retryAttempt) {
        retryAttempt = true;

        try {
          await instance.post('/auth/refresh-token');

          return instance(originalConfig);
        } catch (refreshError) {
          document.cookie = 'accessToken=; Max-Age=0; path=/;';
          document.cookie = 'refreshToken=; Max-Age=0; path=/;';
          localStorage.clear();

          const currentPath = window.location.pathname;
          if (currentPath !== '/booking-process') {
            window.location.href = '/login';
          }

          return Promise.reject(refreshError);
        }
      }
    }

    return Promise.reject(error);
  }
);

export default instance;
