import { red } from "@mui/material/colors";
import { createTheme } from "@mui/material/styles";

// A custom theme for this app
const values = {
  xs: 0,
  sm: 426,
  md: 960,
  lg: 1280,
  xl: 1920
};

const theme = createTheme({
  breakpoints: {
    keys: ["xs", "sm", "md", "lg", "xl"],
    up: (key) => `@media (min-width:${values[key]}px)`
  },
  palette: {
    primary: {
      main: "#556cd6",
    },
    secondary: {
      main: "#19857b",
    },
    error: {
      main: red.A400,
    },
  },
  typography: {
    fontFamily: ["Avenir", "Avenir-bold"].join(","),
  },
});

export default theme;
