import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import http from '../../http';
import { Box, Typography } from '@mui/material';
import Pagination from '../my-profile/Pagination';
import moment from 'moment';

export default function ReferralUsersLists() {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(50);
  const [referralUsers, setReferralUsers] = React.useState([]);
  const [searchValue, setSearchValue] = React.useState('');
  const [filterReferralUsers, setFilterReferralUsers] = React.useState([]);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const onPageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(
      event,
      Math.max(0, Math.ceil(products?.length / rowsPerPage) - 1)
    );
  };

  React.useEffect(() => {
    const fetchReferralUsersData = async () => {
      try {
        let config = {
          method: 'get',
          maxBodyLength: Infinity,
          url: '/user/get-referral-users',
        };
        http
          .request(config)
          .then((response) => {
            setReferralUsers(response.data);
          })
          .catch((error) => {
            console.log(error);
          });
      } catch (error) {
        console.log('Error fetching user data', error);
      }
    };

    fetchReferralUsersData();
  }, []);

  const filteredReferralUsers = React.useMemo(() => {
    return referralUsers?.filter((curr) => {
      if (!searchValue) return true;
      return curr?.owner_id == searchValue;
    });
  }, [referralUsers, searchValue]);

  React.useEffect(() => {
    setFilterReferralUsers([...filteredReferralUsers]);
  }, [filteredReferralUsers]);

  return (
    <Box
      sx={{
        m: 'auto',
        p: 2,
        paddingTop: 5,
      }}
      component={Paper}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          mt: 3,
          mb: 1,
        }}
      >
        <Typography
          sx={{ fontSize: '25px', marginBottom: '30px', fontWeight: 'bold' }}
        >
          Referral Users Lists
        </Typography>
      </Box>
      <TableContainer>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <select
            style={{ padding: '6px', marginBottom: '6px' }}
            onChange={handleChangeRowsPerPage}
          >
            {[50, 100, 150, 200].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                zeigen {pageSize}
              </option>
            ))}
          </select>
          <input
            type='search'
            onChange={(event) => setSearchValue(event.target.value)}
            placeholder='Suche...'
            style={{ padding: '8px', marginBottom: '6px' }}
          />
        </div>

        <Table aria-label='a dense table'>
          <TableHead sx={{ bgcolor: '#e4f0f1' }}>
            <TableRow>
              <TableCell align='left' sx={{ fontWeight: '600' }}>
                ID
              </TableCell>{' '}
              <TableCell align='left' sx={{ fontWeight: '600' }}>
                Owner ID
              </TableCell>
              <TableCell align='left' sx={{ fontWeight: '600' }}>
                Referral Code
              </TableCell>
              <TableCell align='left' sx={{ fontWeight: '600' }}>
                Used By User ID
              </TableCell>
              <TableCell align='left' sx={{ fontWeight: '600' }}>
                Booking ID
              </TableCell>
              <TableCell align='left' sx={{ fontWeight: '600' }}>
                Used At
              </TableCell>
              <TableCell align='left' sx={{ fontWeight: '600' }}>
                Is Redeemed
              </TableCell>
            </TableRow>
          </TableHead>
          {(rowsPerPage > 0
            ? filterReferralUsers.slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage
              )
            : filterReferralUsers
          )?.map((valued, index) => (
            <TableBody>
              <TableRow
                sx={{
                  background: '#F9F9F9 ',
                }}
              >
                <TableCell sx={{}}>{valued?.id} </TableCell>
                <TableCell align='left' sx={{}}>
                  {valued?.owner_id}
                </TableCell>
                <TableCell align='left' sx={{}}>
                  {valued?.referral_code}
                </TableCell>
                <TableCell align='left' sx={{}}>
                  {valued?.used_by_user_id}
                </TableCell>
                <TableCell align='left' sx={{}}>
                  {valued?.booking_id}
                </TableCell>
                <TableCell align='left' sx={{}}>
                  {moment(valued?.used_at).format('YYYY-MM-DD HH:mm:ss')}
                </TableCell>
                <TableCell align='left' sx={{}}>
                  {`${valued?.is_redeemed}`}
                </TableCell>
              </TableRow>
            </TableBody>
          ))}
        </Table>
      </TableContainer>
      <Box
        sx={{ p: 2, display: 'flex', justifyContent: 'end' }}
        // component={Paper}
      >
        <Pagination
          products={referralUsers}
          page={page}
          rowsPerPage={rowsPerPage}
          handleFirstPageButtonClick={handleFirstPageButtonClick}
          handleBackButtonClick={handleBackButtonClick}
          handleNextButtonClick={handleNextButtonClick}
          handleLastPageButtonClick={handleLastPageButtonClick}
          handleChangePage={onPageChange}
        />
      </Box>
    </Box>
  );
}
