import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import http from '../../http';
import { Box, FormControl, Typography } from '@mui/material';
import Pagination from '../my-profile/Pagination';
import { format } from 'date-fns';

export default function StandsLists() {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(50);
  const [products, setProducts] = React.useState([]);
  const [searchValue, setSearchValue] = React.useState('');
  const [filterProducts, setFilterProducts] = React.useState([]);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const onPageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(
      event,
      Math.max(0, Math.ceil(products?.length / rowsPerPage) - 1)
    );
  };

  React.useEffect(() => {
    const fetchProductData = async () => {
      try {
        let config = {
          method: 'get',
          maxBodyLength: Infinity,
          url: '/product/get/all',
        };
        http
          .request(config)
          .then((response) => {
            setProducts(response.data.data);
          })
          .catch((error) => {
            console.log(error);
          });
      } catch (error) {
        console.log('Error fetching user data', error);
      }
    };

    fetchProductData();
  }, []);

  const { filteredProducts } = React.useMemo(() => {
    return {
      filteredProducts: products.filter((curr) => {
        if (!searchValue) {
          return true;
        }

        const searchLowerCase = searchValue.toLowerCase();
        return curr?.sku?.toLowerCase().includes(searchLowerCase);
      }),
    };
  }, [products, searchValue]);

  React.useEffect(() => {
    setFilterProducts([...filteredProducts]);
  }, [filteredProducts]);

  return (
    <Box
      sx={{
        m: 'auto',
        p: 2,
        paddingTop: 5,
      }}
      component={Paper}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          mt: 3,
          mb: 1,
        }}
      >
        <Typography
          sx={{ fontSize: '25px', marginBottom: '30px', fontWeight: 'bold' }}
        >
          Product Lists
        </Typography>
      </Box>
      <TableContainer>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <select
            style={{ padding: '6px', marginBottom: '6px' }}
            onChange={handleChangeRowsPerPage}
          >
            {[50, 100, 150, 200].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                zeigen {pageSize}
              </option>
            ))}
          </select>
          <input
            type='search'
            onChange={(event) => setSearchValue(event.target.value)}
            placeholder='Suche...'
            style={{ padding: '8px', marginBottom: '6px' }}
          />
        </div>

        <Table aria-label='a dense table'>
          <TableHead sx={{ bgcolor: '#e4f0f1' }}>
            <TableRow>
              <TableCell align='left' sx={{ fontWeight: '600', width: '0%' }}>
                SKU
              </TableCell>
              <TableCell align='left' sx={{ fontWeight: '600', width: '15%' }}>
                UUID
              </TableCell>
              <TableCell align='left' sx={{ fontWeight: '600', width: '15%' }}>
                Brand Name
              </TableCell>
              <TableCell align='left' sx={{ fontWeight: '600', width: '0%' }}>
                Title
              </TableCell>
              <TableCell align='left' sx={{ fontWeight: '600', width: '0%' }}>
                Description
              </TableCell>
              <TableCell align='left' sx={{ fontWeight: '600', width: '0%' }}>
                Quantity
              </TableCell>
              <TableCell align='left' sx={{ fontWeight: '600', width: '0%' }}>
                User ID
              </TableCell>
            </TableRow>
          </TableHead>
          {(rowsPerPage > 0
            ? filterProducts.slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage
              )
            : filterProducts
          )?.map((valued, index) => (
            <TableBody>
              <TableRow
                sx={{
                  background: '#F9F9F9 ',
                }}
              >
                <TableCell sx={{}}>{valued?.sku} </TableCell>
                <TableCell align='left' sx={{}}>
                  {valued?.uuid}
                </TableCell>
                <TableCell align='left' sx={{}}>
                  {valued?.brand_name}
                </TableCell>
                <TableCell align='left' sx={{}}>
                  {valued?.title}
                </TableCell>
                <TableCell align='left' sx={{}}>
                  {valued?.description}
                </TableCell>
                <TableCell align='left' sx={{}}>
                  {valued?.quantity}
                </TableCell>
                <TableCell align='left' sx={{}}>
                  {valued?.user_id}
                </TableCell>
              </TableRow>
            </TableBody>
          ))}
        </Table>
      </TableContainer>
      <Box
        sx={{ p: 2, display: 'flex', justifyContent: 'end' }}
        // component={Paper}
      >
        <Pagination
          products={products}
          page={page}
          rowsPerPage={rowsPerPage}
          handleFirstPageButtonClick={handleFirstPageButtonClick}
          handleBackButtonClick={handleBackButtonClick}
          handleNextButtonClick={handleNextButtonClick}
          handleLastPageButtonClick={handleLastPageButtonClick}
          handleChangePage={onPageChange}
        />
      </Box>
    </Box>
  );
}
