import React, { useState } from "react";
import { styled } from "@mui/material/styles";

import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { Typography, Button } from "@mui/material";
import "../../styles/homecards.css";
import { useNavigate } from "react-router-dom";
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

export default function HomeCards() {
  const navigate = useNavigate();
  const [packages, setPackages] = useState([
    {
      id: 1,
      title: "1 Woche",
      cardColor: "#dcb9b7",
      price: 25,
      discountPrice: 20,
      duration: 7,
      isSelected: false,
      features: "+ 15% Provision",
    },
    {
      id: 2,
      title: "2 Wochen",
      price: 40,
      discountPrice: 30,
      cardColor: "#d3c2b2",
      duration: 14,
      isSelected: false,
      features: "+ 15% Provision",
    },
    {
      id: 3,
      title: "3 Wochen",
      price: 60,
      discountPrice: 55,
      cardColor: "#cac397",
      duration: 21,
      isSelected: false,
      features: "+ 15% Provision",
    },
    {
      id: 4,
      title: "4 Wochen",
      description: "Itaque earum hic",
      icon: "bx-car",
      price: 70,
      discountPrice: 60,
      cardColor: "#c5bfb8",
      duration: 28,
      isSelected: false,
      features: "+ 15% Provision",
    },
    {
      id: 5,
      title: "4 Wochen",
      description: "Itaque earum hic",
      icon: "bx-car",
      price: 0,
      discountPrice: 60,
      cardColor: "#d7e0d7",
      duration: 28,
      isSelected: false,
      features: "+ 40% Provision",
    },
  ]);

  const selectPackage = (item) => {
    const packagesClone = [...packages];
    const newUpdatedList = packagesClone.map((day) => {
      if (day.id === item?.id) {
        day["isSelected"] = true;
        return day;
      } else {
        day["isSelected"] = false;
        return day;
      }
    });
    setPackages(newUpdatedList);
    navigate("/booking-process", { state: { package: item } });
  };

  return (
    <Box style={{ width: "85%", margin: "auto" }}>
      <br />
      <Grid
        style={{ display: "flex", flexWrap: "wrap", justifyContent: "center" }}
        container
        spacing={3}
      >
        {packages.map((packageItem) => {
          return (
            <Grid item lg={3} xs={12} sm={6} md={3}>
              <Item
                style={{
                  borderRadius: "15px",
                  paddingTop: "10px",
                  paddingBottom: "10px",
                  background: packageItem?.cardColor,
                }}
              >
                <br />
                <Typography style={{ fontSize: "20px", fontWeight: 600 }}>
                  {packageItem?.title}
                </Typography>
                <Typography style={{ marginTop: "10px" }}>
                  <span style={{ fontSize: "14px", fontWeight: "bold" }}>
                    <span
                      style={{
                        fontSize: "18px",
                        fontWeight: "bold",
                        color: "black",
                      }}
                    >
                     {packageItem.id === 5 ? `${packageItem?.price},- \u20AC` : `${packageItem?.price},- \u20AC`}
                    </span>
                  </span>
                </Typography>
                <Typography style={{ marginTop: "14px", fontWeight: "bold" }}>
                  <h4
                    style={{
                      fontWeight: "bolder",
                      fontSize: "12px",
                      color: "black",
                    }}
                  >
                    {packageItem?.features}
                  </h4>
                </Typography>
                <br />
                <Typography style={{ marginTop: "15px" }}>
                  <Button
                    onClick={() => selectPackage(packageItem)}
                    sx={{
                      ":hover": {
                        background: "#e9c396",
                        color: "#fff",
                      },
                      width: "95%",
                      margin: "auto",
                      borderRadius: "30px",
                      padding: "12px",
                      background: packageItem?.isSelected ? "#e9c396" : "white",
                      color: "GrayText",
                      border: "none",
                      marginTop: "15px",
                      cursor: "pointer",
                    }}
                  >
                    <Typography
                      style={{
                        fontSize: "15px",
                        fontWeight: "bold",
                      }}
                    >
                      Jetzt Buchen
                    </Typography>
                  </Button>
                </Typography>
              </Item>
            </Grid>
          );
        })}

        {/* <Grid item lg={3} xs={12} sm={6} md={3}>
          <Item
            style={{
              borderRadius: "15px",
              paddingTop: "15px",
              paddingBottom: "15px",
              background: "#c6a6a5",
            }}
          >
            <br />
            <Typography style={{ fontSize: "20px", fontWeight: "bold" }}>
              1 Woche
            </Typography>
            <Typography style={{ marginTop: "10px" }}>
              <span style={{ fontSize: "14px", fontWeight: "bold" }}>
                <del>25,- €</del> &nbsp;
                <span
                  style={{
                    fontSize: "18px",
                    fontWeight: "bold",
                    color: "black",
                  }}
                >
                  20,- €*
                </span>
              </span>
            </Typography>
            <Typography
              style={{
                marginTop: "14px",
                fontSize: "12px",
                fontWeight: "bold",
              }}
            >
              + 15% Provision
            </Typography>

            <br />
            <button
              style={{
                width: "95%",
                margin: "auto",
                borderRadius: "30px",
                padding: "12px",
                background: "white",
                color: "GrayText",
                border: "none",
                marginTop: "15px",
              }}
            >
              <Typography
                style={{
                  fontSize: "15px",
                  fontWeight: "bold",
                }}
              >
              Jetzt Buchen
              </Typography>
            </button>
          </Item>
        </Grid>

        <Grid item lg={3} xs={12} sm={6} md={3}>
          <Item
            style={{
              borderRadius: "15px",
              paddingTop: "15px",
              paddingBottom: "15px",
              background: "#beafa0",
            }}
          >
            <br />
            <Typography style={{ fontSize: "20px", fontWeight: "bold" }}>
              2 Woche
            </Typography>
            <Typography style={{ marginTop: "10px" }}>
              <span style={{ fontSize: "14px", fontWeight: "bold" }}>
                <del>25,- €</del> &nbsp;
                <span
                  style={{
                    fontSize: "18px",
                    fontWeight: "bold",
                    color: "black",
                  }}
                >
                  20,- €*
                </span>
              </span>
            </Typography>
            <Typography
              style={{
                marginTop: "14px",
                fontSize: "12px",
                fontWeight: "bold",
              }}
            >
              + 15% Provision
            </Typography>

            <br />
            <button
              style={{
                width: "95%",
                margin: "auto",
                borderRadius: "30px",
                padding: "12px",
                background: "white",
                color: "GrayText",
                border: "none",
                marginTop: "15px",
              }}
            >
              <Typography
                style={{
                  fontSize: "15px",
                  fontWeight: "bold",
                }}
              >
              Jetzt Buchen
              </Typography>
            </button>
          </Item>
        </Grid>
     
        <Grid item lg={3} xs={12} sm={6} md={3}>
          <Item
            style={{
              borderRadius: "15px",
              paddingTop: "15px",
              paddingBottom: "15px",
              background: "#b6af88",
            }}
          >
            <br />
            <Typography style={{ fontSize: "20px", fontWeight: "bold" }}>
              3 Woche
            </Typography>
            <Typography style={{ marginTop: "10px" }}>
              <span style={{ fontSize: "14px", fontWeight: "bold" }}>
                <del>25,- €</del> &nbsp;
                <span
                  style={{
                    fontSize: "18px",
                    fontWeight: "bold",
                    color: "black",
                  }}
                >
                  20,- €*
                </span>
              </span>
            </Typography>
            <Typography
              style={{
                marginTop: "14px",
                fontSize: "12px",
                fontWeight: "bold",
              }}
            >
              + 15% Provision
            </Typography>

            <br />
            <button
              style={{
                width: "95%",
                margin: "auto",
                borderRadius: "30px",
                padding: "12px",
                background: "white",
                color: "GrayText",
                border: "none",
                marginTop: "15px",
              }}
            >
              <Typography
                style={{
                  fontSize: "15px",
                  fontWeight: "bold",
                }}
              >
              Jetzt Buchen
              </Typography>
            </button>
          </Item>
        </Grid>

        <Grid item lg={3} xs={12} sm={6} md={3}>
          <Item
            style={{
              borderRadius: "15px",
              paddingTop: "15px",
              paddingBottom: "15px",
              background: "#b1aca6",
            }}
          >
            <br />
            <Typography style={{ fontSize: "20px", fontWeight: "bold" }}>
              4 Woche
            </Typography>
            <Typography style={{ marginTop: "10px" }}>
              <span style={{ fontSize: "14px", fontWeight: "bold" }}>
                <del>25,- €</del> &nbsp;
                <span
                  style={{
                    fontSize: "18px",
                    fontWeight: "bold",
                    color: "black",
                  }}
                >
                  20,- €*
                </span>
              </span>
            </Typography>
            <Typography
              style={{
                marginTop: "14px",
                fontSize: "12px",
                fontWeight: "bold",
              }}
            >
              + 15% Provision
            </Typography>

            <br />
            <button
              style={{
                width: "95%",
                margin: "auto",
                borderRadius: "30px",
                padding: "12px",
                background: "white",
                color: "GrayText",
                border: "none",
                marginTop: "15px",
              }}
            >
              <Typography
                style={{
                  fontSize: "15px",
                  fontWeight: "bold",
                }}
              >
              Jetzt Buchen
              </Typography>
            </button>
          </Item>
        </Grid> */}
      </Grid>
    </Box>
  );
}
