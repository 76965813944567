import {
  KeyboardArrowLeft,
  KeyboardArrowRight,
  KeyboardDoubleArrowLeft,
  KeyboardDoubleArrowRight,
} from "@mui/icons-material";
import { Box, IconButton, Paper, TextField } from "@mui/material";
import React from "react";

const Pagination = () => {
  return (
    <Box sx={{ display: "flex", gap: 1.3, alignItems: "center" }}>
      <Box
        sx={{
          background: "#beafa0",
          p: ".5rem",
          height: "2.5rem",
          borderRadius: ".5rem",
          //   display: "flex",
        }}
      >
        <KeyboardDoubleArrowLeft sx={{ color: "white" }} />
      </Box>

      <Box
        sx={{
          background: "#beafa0",
          p: ".5rem",
          height: "2.5rem",
          borderRadius: ".5rem",
        }}
      >
        <KeyboardArrowLeft sx={{ color: "white" }} />
      </Box>
      <Box>
        page <strong>1 of 1</strong>
      </Box>
      <TextField
        type="number"
        value="1"
        size="small"
        autoFocus
        sx={{ width: "4rem", height: "2rem" }}
      />
      <Box
        sx={{
          background: "#beafa0",
          p: ".5rem",
          height: "2.5rem",
          borderRadius: ".5rem",
        }}
      >
        <KeyboardArrowRight sx={{ color: "white" }} />
      </Box>
      <Box
        sx={{
          background: "#beafa0",
          p: ".5rem",
          height: "2.5rem",
          borderRadius: ".5rem",
          //   display: "flex",
        }}
      >
        <KeyboardDoubleArrowRight sx={{ color: "white" }} />
      </Box>
    </Box>
  );
};

export default Pagination;
