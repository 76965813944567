import React, { useState, useEffect } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateRangeCalendar } from '@mui/x-date-pickers-pro/DateRangeCalendar';
import { styled } from '@mui/material/styles';
import { DateRange } from '@mui/x-date-pickers-pro';
import Calendar from 'react-calendar';
import moment from 'moment';
import http from '../../http';
import {
  isSameDay,
  isSunday,
  isToday,
  addMonths,
  isAfter,
  addDays,
  parseISO,
} from 'date-fns';
import { Box, Grid, Paper, Button, Typography } from '@mui/material';
import './APIrecordDesign/style2.css';
import 'react-calendar/dist/Calendar.css';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { useNavigate } from 'react-router-dom';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));
export default function DateRangeCalendarValue({
  selectDayItem,
  setSelectedEndDate,
  setSelectedStartDate,
  selectedStartDate,
  selectedEndDate,
  setActive,
}) {
  const navigate = useNavigate();
  const [disabledDates, setDisabledDates] = useState([]);
  // const [selectedStartDate, setSelectedStartDate] = useState(null);
  // const [selectedEndDate, setSelectedEndDate] = useState(null);
  const [date, setDate] = useState(new Date());
  const [isRange, setRange] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [isNext, setIsNext] = useState(false);

  useEffect(() => {
    setLoading(true);
    http
      .request({
        method: 'get',
        maxBodyLength: Infinity,
        url: '/holidays',
        headers: {},
      })
      .then((response) => {
        const { data } = response.data;
        setDisabledDates(data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const tileDisabled = ({ date, view }) => {
    const tempDate = addMonths(new Date(), 7);

    // Disable tiles in month view only
    if (isSunday(new Date(date))) {
      return true;
    } else if (isToday(new Date(date))) {
      // Check if a date React-Calendar wants to check is on the list of disabled dates
      return true;
    } else if (isAfter(date, new Date(tempDate))) {
      // Check if a date React-Calendar wants to check is on the list of disabled dates
      return true;
    } else {
      // Check if a date React-Calendar wants to check is on the list of disabled dates
      return disabledDates.find((dDate) =>
        isSameDay(new Date(dDate?.holiday_date), date)
      );
    }
  };

  const calcWorkingDays = (fromDate, days) => {
    var count = 1;
    while (count < days) {
      fromDate.setDate(fromDate.getDate() + 1);
      if (
        fromDate.getDay() != 0 &&
        !disabledDates.find((dDate) =>
          isSameDay(parseISO(dDate.holiday_date), fromDate)
        )
      )
        // Skip weekends
        count++;
    }
    return fromDate;
  };

  const onDaySelect = (value) => {
    const workingDays = calcWorkingDays(
      new Date(value),
      selectDayItem?.duration
    );
    setDate([new Date(value), new Date(workingDays)]);
    // setRange(isRange);
    setSelectedStartDate(new Date(value));
    setSelectedEndDate(workingDays);
    setIsNext(true);
    // setTimeout(() => {
    //   setLoading(false);
    // }, 2000); //
  };

  const handleNavigation = () => {
    const startDate = new Date(selectedStartDate);
    const endDate = new Date(selectedEndDate);

    const startUTC = new Date(
      Date.UTC(
        startDate.getFullYear(),
        startDate.getMonth(),
        startDate.getDate()
      )
    );
    startUTC.setUTCHours(0, 0, 0, 0);

    const endUTC = new Date(
      Date.UTC(endDate.getFullYear(), endDate.getMonth(), endDate.getDate())
    );
    endUTC.setUTCHours(23, 59, 0, 0);

    navigate('/booking-process', {
      state: {
        startDate: startUTC.toISOString(),
        endDate: endUTC.toISOString(),
      },
    });
  };

  return (
    // <Box sx={{ minHeight: "100vh" }} >

    <Grid container spacing={2} sx={{ boxShadow: 0 }} justifyContent={'center'}>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
        style={{ zIndex: 99999 }}
      >
        <CircularProgress color='inherit' />
      </Backdrop>
      <Grid item xs={12} md={7} sx={{ boxShadow: 0 }} justifyContent={'center'}>
        <Typography
          sx={{
            fontSize: { xs: 18 },
            textAlign: 'center',
            marginBottom: '30px',
          }}
        >
          Wähle deinen gewünschten Zeitraum
        </Typography>

        <Item sx={{ boxShadow: 0 }}>
          <div className='calendar-container d-flex justify-content-center'>
            {isLoading ? (
              <div></div>
            ) : (
              <Calendar
                className='custom-calendar-container'
                onChange={onDaySelect}
                value={date}
                selectRange={isRange}
                minDetail='year'
                // showDoubleView={true}
                minDate={new Date()}
                allowPartialRange={true}
                tileDisabled={tileDisabled}
                // activeStartDate={new Date()} // pass in state var in activeStartDate
                // showNeighboringMonth={false}
                // showFixedNumberOfWeeks={true}
                goToRangeStartOnSelect={true}
                // onActiveStartDateChange={({ value, activeStartDate, action }) => {
                //   if (action === 'next') {
                //     // setSelectedStartDate(new Date(activeStartDate))
                //   }
                //   if (action === 'prev') {
                //     // setSelectedStartDate(new Date(activeStartDate))
                //   }
                // }}
              />
            )}
          </div>
          {selectedStartDate ? (
            <Box
              sx={{
                bgcolor: 'white',
                p: '1rem',
                pl: '2rem',
                fontWeight: 'bold',
                fontSize: '18px',
              }}
            >
              {' '}
              {moment(new Date(selectedStartDate)).format(
                'L'
              )}&nbsp;|&nbsp; {moment(new Date(selectedEndDate)).format('L')}
            </Box>
          ) : null}
        </Item>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            marginTop: '10px',
          }}
        >
          <Button
            variant='contained'
            style={{
              marginRight: '8px',
              background: '#b98b8b',
              marginRight: '37px',
            }}
            onClick={() => {
              setActive(0);
            }}
          >
            Zurück
          </Button>
          <Button
            disabled={!isNext}
            variant='contained'
            style={{
              marginRight: '8px',
              background: isNext ? '#b98b8b' : '#7a7e80',
              color: '#fff',
            }}
            onClick={() => {
              setActive(2);
              handleNavigation();
            }}
          >
            Weiter
          </Button>
        </Box>
      </Grid>
    </Grid>
    // </Box>
  );
}
