export const EXTRAS = [
  {
    id: 1,
    title: 'Standard',
    price: 0,
    isSelected: false,
    features: [
      'Du etikettierst deine Ware',
      ' Du füllst dein Regal mit deiner Ware bei Mietbeginn',
      'Du räumst die nicht verkauften Artikel bei Mietende aus deinem Regal',
    ],
  },
  {
    id: 2,
    title: 'Komfort',
    price: 15,
    isSelected: false,
    features: [
      ' Du gibst deine Ware bis spätestens 3 Werktage vor Mietbeginn bei uns im Geschäft ab',
      ' Wir etikettieren deine Artikel, präsentieren sie in deinem Regal und räumen diese bei Mietende wieder aus',
      'Du holst deine nicht verkauften Artikel bis spätestens 3 Werktage nach Mietende wieder ab',
    ],
  },
  {
    id: 3,
    title: 'Sorglos',
    price: 30,
    isSelected: false,
    features: [
      'Du schickst deine Artikel per Post direkt zu uns in das Geschäft',
      ' Wir etikettieren deine Artikel, präsentieren sie in deinem Regal und räumen diese bei Mietende wieder aus',
      'Wir schicken dir deine nicht verkauften Artikel spätestens 7 Werktage nach Mietende an dich zurück',
    ],
  },
];

export const PACKAGES = [
  {
    id: 1,
    title: '1 Woche',
    cardColor: '#dcb9b7',
    price: 25,
    discountPrice: 20,
    duration: 7,
    isSelected: false,
    features: '+ 15% Provision',
  },
  {
    id: 2,
    title: '2 Wochen',
    price: 40,
    discountPrice: 30,
    cardColor: '#d3c2b2',
    duration: 14,
    isSelected: false,
    features: '+ 15% Provision',
  },
  {
    id: 3,
    title: '3 Wochen',
    price: 60,
    discountPrice: 55,
    cardColor: '#cac397',
    duration: 21,
    isSelected: false,
    features: '+ 15% Provision',
  },
  {
    id: 4,
    title: '4 Wochen',
    description: 'Itaque earum hic',
    icon: 'bx-car',
    price: 70,
    discountPrice: 60,
    cardColor: '#c5bfb8',
    duration: 28,
    isSelected: false,
    features: '+ 15% Provision',
  },
  {
    id: 5,
    title: '4 Wochen',
    description: 'Itaque earum hic',
    icon: 'bx-car',
    price: 0,
    discountPrice: 60,
    cardColor: '#d7e0d7',
    duration: 28,
    isSelected: false,
    features: '+ 40% Provision',
  },
];
