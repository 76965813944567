import { Avatar, Box, Button, Container, Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';
import DateRangeCalendarValue from './DateRange';
import Signup from './Signup';
import MyComponent from './CardComponent';
import HomeCards from './bookingPackages';
import CardComponent from './SecondCard';
import Shelf from './APIrecordDesign/Shelf';
import MuiAlert from '@mui/material/Alert';
import { useNavigate, useLocation } from 'react-router-dom';

const BookingTabs = (props) => {
  const location = useLocation();
  const [packageSelected, setPackageSelected] = useState(
    location?.state?.package
  );
  const [active, setActive] = useState(0);
  const [selectDayItem, setSelectDayItem] = useState(location?.state?.package);
  const [extraItem, setExtraItem] = useState(null);
  const [selectedStartDate, setSelectedStartDate] = useState(null);
  const [selectedEndDate, setSelectedEndDate] = useState(null);
  const [selectedStand, setSelectedStand] = useState({});
  const [userDetail, setUserDetail] = useState({});
  const [emailExits, setEmailExits] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [showAlertMsg, setShowAlertMsg] = useState(false);
  const [userId, setUserId] = useState(null);
  const [isCouponApplied, setISCouponApplied] = useState(false);
  const [newsOffer, setNewsOffer] = useState(false);
  const [couponDetail, setCouponDetail] = useState({});
  const [isReferralCodeApplied, setIsReferralCodeApplied] = useState(false);
  const [referralCode, setReferralCode] = useState('');
  const [referralDetail, setReferralDetail] = useState({});

  const handleActive = (value) => {
    setActive(value);
    setShowAlertMsg('Package is selected!');
  };

  const [flag, setFlag] = useState(true);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    if (location?.state?.package && flag) {
      setActive(1);
      setFlag(false);
    }
    if (active == 0 && selectDayItem) {
      setShowAlert(true);
    }
  }, [active]);

  return (
    <Container sx={{ minHeight: '100vh' }}>
      <Typography
        sx={{
          fontWeight: 'bold',
          fontSize: { xs: 25 },
          textAlign: 'center',
          marginTop: '60px',
        }}
      >
        Starte jetzt deine Buchung
      </Typography>
      <br />
      <br />
      <Box
        sx={{
          display: 'flex',
          justifyContent: { xs: 'start', sm: 'center' },
          gap: { xs: '0.5rem', md: '3rem' },
          flexWrap: 'wrap',
          mt: { xs: '1rem', sm: '10px' },
          mb: '2.8rem',
        }}
      >
        <Button
          disabled
          sx={{ width: '9rem' }}
          style={{
            background: '#ceccd4',
            color: active === 0 ? 'black' : 'white',
          }}
          onClick={() => handleActive(0)}
        >
          {' '}
          Paket
        </Button>
        <Button
          disabled
          sx={{ width: '9rem' }}
          style={{
            background: '#ceccd4',
            color: active === 1 ? 'black' : 'white',
          }}
          onClick={() => handleActive(1)}
        >
          {' '}
          Mietbeginn
        </Button>
        <Button
          disabled
          style={{
            background: '#beafa0',
            color: active === 2 ? 'black' : 'white',
          }}
          onClick={() => handleActive(2)}
        >
          Regal
        </Button>
        <Button
          disabled
          sx={{ width: '9rem' }}
          style={{
            background: '#bac2be',
            color: active === 3 ? 'black' : 'white',
          }}
          onClick={() => handleActive(3)}
        >
          Kontaktdaten
        </Button>
        <Button
          disabled
          sx={{ width: '9rem' }}
          style={{
            background: '#aea8a2',
            color: active == 4 ? 'black' : 'white',
          }}
          onClick={() => handleActive(4)}
        >
          Extras
        </Button>
        <Button
          disabled
          sx={{ width: '9rem' }}
          style={{
            background: '#8b8c84',
            color: active == 5 ? 'black' : 'white',
          }}
          onClick={() => handleActive(5)}
        >
          Bestellübersicht
        </Button>
      </Box>

      {active == 0 && (
        <HomeCards
          setSelectDayItem={(value) => setSelectDayItem(value)}
          setActive={(value) => setActive(value)}
          selectedPackage={location?.state?.package?.id}
        />
      )}
      {active == 1 && (
        <DateRangeCalendarValue
          selectDayItem={selectDayItem}
          setSelectedStartDate={(value) => setSelectedStartDate(value)}
          setSelectedEndDate={(value) => setSelectedEndDate(value)}
          selectedStartDate={selectedStartDate}
          selectedEndDate={selectedEndDate}
          setActive={(value) => setActive(value)}
        />
      )}
      {active == 2 && (
        <Shelf
          selectedStand={selectedStand}
          setSelectedStand={(value) => setSelectedStand(value)}
          setActive={(value) => setActive(value)}
        />
      )}
      {active == 3 && (
        <Signup
          setUserDetail={(value) => setUserDetail(value)}
          userId={userId}
          newsOffer={newsOffer}
          setUserId={(value) => setUserId(value)}
          setEmailExits={(value) => setEmailExits(value)}
          setActive={(value) => setActive(value)}
          selectedPackage={selectDayItem}
          isCouponApplied={isCouponApplied}
          setISCouponApplied={(value) => setISCouponApplied(value)}
          setCouponDetail={(value) => setCouponDetail(value)}
          isReferralCodeApplied={isReferralCodeApplied}
          setIsReferralCodeApplied={(value) => setIsReferralCodeApplied(value)}
          referralCode={referralCode}
          setReferralCode={(value) => setReferralCode(value)}
          setReferralDetail={(value) => setReferralDetail(value)}
          setNewsOffer={(value) => setNewsOffer(value)}
        />
      )}
      {active == 4 && (
        <MyComponent
          setExtraItem={(value) => setExtraItem(value)}
          setActive={(value) => setActive(value)}
        />
      )}
      {active == 5 && (
        <CardComponent
          userDetail={userDetail}
          emailExits={emailExits}
          selectedStartDate={selectedStartDate}
          selectedEndDate={selectedEndDate}
          extraItem={extraItem}
          selectDayItem={selectDayItem}
          selectedPackage={selectDayItem}
          selectedStand={selectedStand}
          userId={userId}
          newsOffer={newsOffer}
          isCouponApplied={isCouponApplied}
          couponDetail={couponDetail}
          isReferralCodeApplied={isReferralCodeApplied}
          referralCode={referralCode}
          referralDetail={referralDetail}
        />
      )}
    </Container>
  );
};

export default BookingTabs;
