import { Box, Button, Typography } from "@mui/material";
import React from "react";
import "../../styles/homecards.css";

const LowerButton = () => {
  return (
    <>
      <Box className="lower-button">
        <span className="lower-button-text">
          Oder komm’ vorbei und stöbere nach tollen Schätzen!
        </span>
        <br />
        <br />
        <Typography
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontSize: "20px",
          }}
        >
          <Button
            variant="contained"
            style={{
              background: "rgb(211, 194, 178)",
              borderRadius: "20px",
              border: "none",
              width: "30rem",
              padding: "20px",
            }}
            className="hier-button"
          >
            <span style={{  fontSize: "22px"}}>
              Hier findest Du uns
            </span>
          </Button>
        </Typography>
      </Box>
    </>
  );
};

export default LowerButton;
