import PropTypes from 'prop-types';
import React from 'react';
import { Button, Card, Paper, Typography, Box, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const CardPricing = (props) => {
  const handleSelectDay = () => {
    if (
      (props.pricing.duration === 7 && props.sevenDayOverlap > 0) ||
      (props.pricing.duration === 14 && props.fourteenDayOverlap > 0) ||
      (props.pricing.duration === 21 && props.twentyOneDayOverlap > 0) ||
      (props.pricing.duration === 28 && props.twentyEightDayOverlap > 0)
    ) {
      return;
    }

    props.setSelectDay(props.pricing);
  };

  return (
    <React.Fragment>
      <Grid item lg={3} xs={12} sm={6} md={3}>
        <Item
          style={{
            borderRadius: '15px',
            paddingTop: '15px',
            paddingBottom: '15px',
            background: props.pricing.cardColor,
          }}
        >
          <br />
          <Typography style={{ fontSize: '20px', fontWeight: 'bold' }}>
            {props.pricing.title}
          </Typography>
          <Typography style={{ marginTop: '10px' }}>
            <span style={{ fontWeight: 'bold', color: '#000' }}>
              <sup>€</sup>
              <span style={{ marginLeft: 4, fontSize: 22 }}>
                {props.pricing.price}{' '}
              </span>{' '}
              <span style={{ margin: 4 }}>/</span>
              <span style={{ fontSize: 14 }}>
                {props.pricing.duration + ' tage'}
              </span>
            </span>
          </Typography>
          <Typography
            style={{
              marginTop: '14px',
              fontSize: '12px',
              fontWeight: 'bold',
            }}
          >
            {props.pricing.features.map((feature, key) => (
              <p className='text-black text-center' key={'_feature_' + key}>
                {feature.title}
              </p>
            ))}
          </Typography>

          <br />
          <Button
            onClick={handleSelectDay}
            size='large'
            variant='contained'
            disabled={
              (props.pricing.duration === 7 && props.sevenDayOverlap > 0) ||
              (props.pricing.duration === 14 && props.fourteenDayOverlap > 0) ||
              (props.pricing.duration === 21 &&
                props.twentyOneDayOverlap > 0) ||
              (props.pricing.duration === 28 && props.twentyEightDayOverlap > 0)
            }
            sx={{
              ':hover': {
                bgcolor: props?.pricing?.isSelected ? '#B39076' : '#636678',
              },
              width: '90%',
              bgcolor: props?.pricing?.isSelected ? '#B39076' : '#636678',
            }}
          >
            {props?.pricing?.isSelected ? 'Selected' : 'Choose'}
          </Button>
        </Item>
      </Grid>
    </React.Fragment>
  );
};

CardPricing.propTypes = {
  pricing: PropTypes.object,
  setSelectDay: PropTypes.func,
  sevenDayOverlap: PropTypes.number,
  fourteenDayOverlap: PropTypes.number,
  twentyOneDayOverlap: PropTypes.number,
  twentyEightDayOverlap: PropTypes.number,
};

export default CardPricing;
