import React from 'react'
import { Box } from '@mui/material'
import logo from "../../img/logo.png";

export default function HeroLogo() {
  return (
    <div>
                  <Box
            display="flex"
            justifyContent="center"
            flexDirection="Column"
            alignItems="center"
          >
            <img
              src={logo}
              width="220px"
              height="220px"
              // className={scroll ? "Nscrol" : "NnotScrol"}
            />
            <h4
              // className={scroll ? "Nscrol" : "NnotScrol"}
              style={{
                color: "black",
                marginTop: 5,
                marginBottom: 15,
                fontSize: 20,
              }}
            >
              Platz schaffen und Gutes tun
            </h4>
          </Box>
    </div>
  )
}
