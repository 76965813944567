import { Box } from "@mui/material";
import React from "react";
import "../../styles/homecards.css";

const UpperText = () => {
  return (
    <Box
      style={{
        width: "90%",
        margin: "auto",
      }}
    >
      <h1 className="upper-text">
        Wie schnell Kinder wirklich groß werden, merkt man besonders an
        Kleidung, Spielzeug und Ausstattungen. Kaum hat man etwas Größen- und
        Altersentsprechendes gekauft, findet man es kurze Zeit später schon
        wieder in aussortierten Boxen. Wir helfen dir, dich von diesen Dingen zu
        trennen und bieten alle Vorteile eines Second Hand Shops, eines
        Flohmarkts und dem Online-Verkauf in einem Konzept.
      </h1>

      <h1 className="lower-text">
        Buche jetzt dein Regal und gib gebrauchten Dingen eine 2. Chance!
      </h1>
    </Box>
  );
};

export default UpperText;
