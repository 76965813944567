import React, { useEffect } from 'react'
import UpdateUserForm from './FormUser'
import PasswordUserForm from './PasswordUserForm'
import { Box } from '@mui/material'
import { useLocation, useNavigate } from "react-router-dom";
import queryString from 'query-string';
function ProfileAdmin() {
  const location = useLocation();
  const navigate = useNavigate();
  const parsed = queryString.parse(location.search);

  useEffect(() => {

    if (!parsed || !parsed?.user_id) {
      navigate(`/users-admin`);
    }

  }, [])

  return (
    <Box sx={{ py: "1rem", minHeight: "100vh" }}>
      <UpdateUserForm id={parsed?.user_id} />
      {/* <PasswordUserForm id={parsed?.user_id} /> */}
    </Box>
  )
}

export default ProfileAdmin