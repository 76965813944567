import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import http from '../../http';
import moment from 'moment';

import {
  Box,
  Button,
  FormControl,
  MenuItem,
  Select,
  Stack,
  TablePagination,
  Typography,
} from '@mui/material';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Pagination from '../my-profile/Pagination';

export default function CategoriesLists() {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [products, setProducts] = React.useState([]);
  const [age, setAge] = React.useState('');
  const [add, setAdd] = React.useState(false);
  const [update, setUpdate] = React.useState(false);
  const [name, setName] = React.useState('');
  const [updatedItemId, setUpdatedItemId] = React.useState(null);

  const handleClickOpen = () => {
    setAdd(true);
  };

  const handleClose = () => {
    setAdd(false);
  };

  const handleClickUpdateOpen = () => {
    setUpdate(true);
  };

  const handleUpdateClose = () => {
    setUpdate(false);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const onPageChange = (event, newPage) => {
    // if (newPage < Math.round(products?.length / rowsPerPage)) {
    setPage(newPage);
    // } else {
    // Math.max(0, Math.ceil(products?.length / rowsPerPage));
    // }
  };

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(
      event,
      Math.max(0, Math.ceil(products?.length / rowsPerPage) - 1)
    );
  };

  React.useEffect(() => {
    const fetchUserData = async () => {
      try {
        const id = localStorage.getItem('id');
        let config = {
          method: 'get',
          maxBodyLength: Infinity,
          url: `/category/get/all`,
          headers: {},
        };

        http
          .request(config)
          .then((response) => {
            setProducts(response?.data?.data);
          })
          .catch((error) => {
            console.log(error);
          });
      } catch (error) {
        console.log('Error fetching user data', error);
      }
    };

    fetchUserData();
  }, []);

  const addNewProduct = (bytes, decimals = 2) => {
    if (name === '' || name === null) {
      alert('Please enter a category name');
      return;
    }

    const User_id = localStorage.getItem('User_id');
    let data = JSON.stringify({
      name: name,
    });

    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: '/category',
      headers: {
        'Content-Type': 'application/json',
      },
      data: data,
    };

    http
      .request(config)
      .then((response) => {
        alert('category is added successfully');
        window.location.reload();
      })
      .catch((error) => {
        alert('Failed to add category.Please try again');
        // window.location.reload();
      });
  };

  const updateProduct = (bytes, decimals = 2) => {
    const User_id = localStorage.getItem('User_id');
    let data = JSON.stringify({
      name: name,
    });

    let config = {
      method: 'put',
      maxBodyLength: Infinity,
      url: `/category/${id}`,
      headers: {
        'Content-Type': 'application/json',
      },
      data: data,
    };

    http
      .request(config)
      .then((response) => {
        alert('category is Updated successfully');
        window.location.reload();
      })
      .catch((error) => {
        alert('Failed to Updated category.Please try again');
        window.location.reload();
      });
  };

  const deleteProduct = (id) => {
    let config = {
      method: 'delete',
      maxBodyLength: Infinity,
      url: `/category/${id}`,
      headers: {},
    };

    http
      .request(config)
      .then((response) => {
        alert('category is Deleted successfully');
        window.location.reload();
      })
      .catch((error) => {
        alert('Failed to Delete category.Please try again');
        window.location.reload();
      });
  };

  return (
    <Box sx={{ pb: '2rem' }}>
      <Box sx={{ m: 'auto', p: 2 }}>
        <Dialog open={add} onClose={handleClose}>
          <DialogTitle>Kategorie hinzufügen</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Bitte geben Sie hier den Namen der Kategorie ein. Diese wird dann
              auch im POS hinzugefügt.
            </DialogContentText>
            <TextField
              autoFocus
              margin='dense'
              id='name'
              label='Kategorie'
              type='text'
              value={name}
              onChange={(text) => setName(text?.target.value)}
              fullWidth
              variant='standard'
            />
          </DialogContent>
          <DialogActions>
            <Button style={{ color: 'red' }} onClick={handleClose}>
              Stornieren
            </Button>
            <Button onClick={addNewProduct}>Speichern</Button>
          </DialogActions>
        </Dialog>

        <Dialog open={update} onClose={handleUpdateClose}>
          <DialogTitle>Kategorie aktualisieren</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Bitte geben Sie Ihr Wunschdatum ein, um dies zu aktualisieren.
            </DialogContentText>
            <TextField
              autoFocus
              margin='dense'
              id='name'
              label='Kategorie'
              type='text'
              value={name}
              onChange={(text) => setName(text?.target.value)}
              fullWidth
              variant='standard'
            />
          </DialogContent>
          <DialogActions>
            <Button style={{ color: 'red' }} onClick={handleUpdateClose}>
              Stornieren
            </Button>
            <Button onClick={updateProduct}>Speichern</Button>
          </DialogActions>
        </Dialog>
        <Box component={Paper} sx={{ p: 2 }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              mt: 3,
              mb: 1,
            }}
          >
            <Typography sx={{ fontSize: '25px', fontWeight: 'bolder' }}>
              Kategorienlisten
            </Typography>
            <Button
              onClick={handleClickOpen}
              sx={{
                ':hover': {
                  bgcolor: '#e4f0f1',
                  color: 'black',
                },
                bgcolor: '#e4f0f1',
                color: 'black',
                fontWeight: 'bold',
                padding: '1rem',
              }}
            >
              Kategorie hinzufügen
            </Button>
          </Box>

          <TableContainer>
            <FormControl
              sx={{
                width: {
                  sm: '6rem',
                  xs: '100%',
                },
                marginBottom: '10px',
              }}
            >
              <select
                style={{
                  padding: '6px',
                  marginBottom: '4px',
                  marginTop: '6px',
                }}
                onChange={handleChangeRowsPerPage}
              >
                {[50, 100, 150, 200].map((pageSize) => (
                  //  {[1, 2, 3,5,6].map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    zeigen {pageSize}
                  </option>
                ))}
              </select>
            </FormControl>

            <Table aria-label='a dense table'>
              <TableHead sx={{ bgcolor: '#e4f0f1' }}>
                <TableRow>
                  <TableCell align='left' sx={{ fontWeight: '600' }}>
                    Kategoriename
                  </TableCell>{' '}
                  <TableCell align='left' sx={{ fontWeight: '600' }}>
                    POS ID
                  </TableCell>
                  <TableCell align='left' sx={{ fontWeight: '600' }}>
                    Datum
                  </TableCell>
                  <TableCell align='left' sx={{ fontWeight: '600' }}>
                    Details anzeigen
                  </TableCell>
                </TableRow>
              </TableHead>
              {(rowsPerPage > 0
                ? products.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  )
                : products
              )?.map((valued, index) => (
                <TableBody>
                  <TableRow
                    sx={{
                      background: '#F9F9F9 ',
                    }}
                  >
                    <TableCell sx={{}}>{valued?.name} </TableCell>
                    <TableCell align='left' sx={{}}>
                      {valued?.pos_id}
                    </TableCell>

                    <TableCell align='left' sx={{}}>
                      {' '}
                      {moment(valued.createdAt).format('DD-MM-YYYY')}
                    </TableCell>

                    <TableCell align='left' sx={{}}>
                      {' '}
                      <Button
                        onClick={() => {
                          setUpdatedItemId(valued?.id);
                          setName(valued?.holiday_date);
                          setUpdate(true);
                        }}
                        style={{
                          backgroundColor: '#d0a667',
                          color: 'white',
                          marginRight: '8px',
                        }}
                      >
                        Aktualisieren
                      </Button>
                      <Button
                        onClick={() => deleteProduct(valued?.id)}
                        style={{
                          backgroundColor: '#b2102f',
                          color: 'white',
                        }}
                      >
                        Löschen
                      </Button>
                    </TableCell>
                  </TableRow>
                </TableBody>
              ))}
            </Table>
          </TableContainer>

          <Box
            sx={{ p: 2, display: 'flex', justifyContent: 'end' }}
            // component={Paper}
          >
            <Pagination
              products={products}
              page={page}
              rowsPerPage={rowsPerPage}
              handleFirstPageButtonClick={handleFirstPageButtonClick}
              handleBackButtonClick={handleBackButtonClick}
              handleNextButtonClick={handleNextButtonClick}
              handleLastPageButtonClick={handleLastPageButtonClick}
              handleChangePage={onPageChange}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
