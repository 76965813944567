import React from 'react';
import {
  Typography,
  Link,
  Breadcrumbs,
  Grid,
  TextField,
  Button,
  Box,
  InputLabel,
  Paper,
  MenuItem,
  Select,
} from '@mui/material';
import { TextareaAutosize } from '@mui/base/TextareaAutosize';
import * as Yup from 'yup';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import http from '../../http';
import CurrencyInput from 'react-currency-input-field';

function AddProduct() {
  const navigate = useNavigate();

  const [standId, setStandId] = useState(null);
  const [categories, setCategory] = useState(null);
  const [userBookingList, setUserBookingList] = useState([]);
  const [price, setPrice] = useState('');

  const generateSku = (standId, userId) => {
    const timeString = Date.now().toString();
    const timePortion = timeString.substr(timeString.length - 6);

    const userIdString = userId.toString().padStart(3, '0').substr(0, 3);
    const standIdString = standId.toString().padStart(3, '0').substr(0, 3);

    return userIdString + standIdString + timePortion;
  };

  const generateRandomPassword = () => {
    var packageLevel = 0;
    var gs1Prefix = [0, formik.values.stands];

    var labelerCode = [];
    for (var index = 0; index < 7; index++) {
      labelerCode[index] = Math.floor(Math.random() * 10);
    }

    var gtinArray = [];
    gtinArray.push(packageLevel);
    gtinArray.push(gs1Prefix[0]);
    gtinArray.push(gs1Prefix[1]);
    for (index = 0; index < 7; index++) {
      gtinArray.push(labelerCode[index]);
    }

    gtinArray.push(checkDigit14(gtinArray));

    var gtinString = '';
    for (index = 0; index < gtinArray.length; index++) {
      gtinString += '' + gtinArray[index];
    }

    return gtinString;
  };

  const checkDigit14 = (gtinArray) => {
    var sum = 0;
    for (var index = 0; index < gtinArray.length; index++) {
      if (index % 2 !== 0) {
        sum += gtinArray[index];
      } else {
        sum += gtinArray[index] * 3;
      }
    }

    var checkDigit = 0;
    var remainder = sum % 10;

    if (remainder !== 0) {
      checkDigit = 10 - remainder;
    }
    //alert('sum=' + sum + ', remainder=' + remainder + ', checkDigit=' + checkDigit);
    return checkDigit;
  };

  useEffect(() => {
    http
      .request({
        method: 'get',
        maxBodyLength: Infinity,
        url: '/booking/get-user-active-bookings',
      })
      .then((response) => {
        setUserBookingList(response.data.response.data);
      })
      .catch((error) => {
        console.log(error);
      });

    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: '/category/get/all',
      headers: {},
    };

    http
      .request(config)
      .then((response) => {
        const { data } = response.data;
        setCategory(data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const getBookingId = (stand_id) => {
    let booking_id;
    userBookingList &&
      userBookingList.length &&
      userBookingList.map((booking) => {
        if (booking?.stand_id == stand_id) {
          booking_id = booking.id;
        }
      });

    return booking_id;
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      product_name: '',
      stands: '',
      manufacturer_name: ' ',
      category: '',
      manufacturer_brand: ' ',
      quantity: '',
      price: '',
      bookings: '',
      description: ' ',
    },

    onSubmit: (values) => {
      if (values.price == undefined || values.price == '') {
        alert('Preis ist erforderlich');
        return;
      }
      let data = JSON.stringify({
        manufacturer: values.manufacturer_brand,
        brand_name: values.manufacturer_brand,
        sku: generateSku(values.stands, localStorage.getItem('id')),
        title: values.product_name,
        description: values.description,
        quantity: values.quantity,
        category_id: values.category,
        adaptive_price: '0',
        manual_price: '0',
        infinite_inventory: 0,
        user_id: localStorage.getItem('id'),
        booking_id: getBookingId(values.stands),
        stand_id: values.stands,
        price: values.price ? parseFloat(values.price.replace(',', '.')) : 0,
      });

      let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: '/product',
        headers: {
          'Content-Type': 'application/json',
        },
        data: data,
      };

      http
        .request(config)
        .then((response) => {
          alert('Produkt wurde erfolgreich hinzugefügt', response);
          window.location.reload();
        })
        .catch((error) => {
          alert(
            'Produkt kann nicht hinzugefügt werden. Bitte versuchen Sie es erneut.'
          );
          // window.location.reload();
        });
    },
    validationSchema: Yup.object({
      product_name: Yup.string().required('Benennung ist erforderlich!'),
      stands: Yup.string().required('Regalnummer ist erforderlich'),
      // manufacturer_name: Yup.string().required("Herstellername ist erforderlich"),
      category: Yup.string().required('Kategorie ist erforderlich!'),
      // manufacturer_brand: Yup.string().required("Postleitzahl ist erforderlich"),
      quantity: Yup.string().required('Menge ist erforderlich'),
      price: Yup.string().required('Preis ist erforderlich'),
      // bookings: Yup.string().required("Buchungen sind erforderlich"),
      // description: Yup.string().required("Beschreibung ist erforderlich"),
    }),
  });

  const mask = (
    value,
    precision = 2,
    decimalSeparator = ',',
    thousandSeparator = '.',
    allowNegative = false,
    prefix = '',
    suffix = ''
  ) => {
    // provide some default values and arg validation.
    if (precision < 0) {
      precision = 0;
    } // precision cannot be negative
    if (precision > 20) {
      precision = 20;
    } // precision cannot be greater than 20

    if (value === null || value === undefined) {
      return {
        value: 0,
        maskedValue: '',
      };
    }

    value = String(value); //if the given value is a Number, let's convert into String to manipulate that

    if (value.length == 0) {
      return {
        value: 0,
        maskedValue: '',
      };
    }

    // extract digits. if no digits, fill in a zero.
    let digits = value.match(/\d/g) || ['0'];

    let numberIsNegative = false;
    if (allowNegative) {
      let negativeSignCount = (value.match(/-/g) || []).length;
      // number will be negative if we have an odd number of "-"
      // ideally, we should only ever have 0, 1 or 2 (positive number, making a number negative
      // and making a negative number positive, respectively)
      numberIsNegative = negativeSignCount % 2 === 1;

      // if every digit in the array is '0', then the number should never be negative
      let allDigitsAreZero = true;
      for (let idx = 0; idx < digits.length; idx += 1) {
        if (digits[idx] !== '0') {
          allDigitsAreZero = false;
          break;
        }
      }
      if (allDigitsAreZero) {
        numberIsNegative = false;
      }
    }

    // zero-pad a input
    while (digits.length <= precision) {
      digits.unshift('0');
    }

    if (precision > 0) {
      // add the decimal separator
      digits.splice(digits.length - precision, 0, '.');
    }

    // clean up extraneous digits like leading zeros.
    digits = Number(digits.join('')).toFixed(precision).split('');
    let raw = Number(digits.join(''));

    let decimalpos = digits.length - precision - 1; // -1 needed to position the decimal separator before the digits.
    if (precision > 0) {
      // set the final decimal separator
      digits[decimalpos] = decimalSeparator;
    } else {
      // when precision is 0, there is no decimal separator.
      decimalpos = digits.length;
    }

    // add in any thousand separators
    for (let x = decimalpos - 3; x > 0; x = x - 3) {
      digits.splice(x, 0, thousandSeparator);
    }

    // if we have a prefix or suffix, add them in.
    if (prefix.length > 0) {
      digits.unshift(prefix);
    }
    if (suffix.length > 0) {
      digits.push(suffix);
    }

    // if the number is negative, insert a "-" to
    // the front of the array and negate the raw value
    if (allowNegative && numberIsNegative) {
      digits.unshift('-');
      raw = -raw;
    }

    // return {
    //     value: raw,
    //     maskedValue: digits.join('').trim()
    // };
    return digits.join('').trim();
  };

  return (
    <div style={{ backgroundColor: '#fdfcf6', height: '100vh' }}>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Paper
          sx={{
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '60vh',
            borderRadius: '1rem',
            bgcolor: '#fdfcf6',
          }}
        >
          <Typography
            sx={{
              fontSize: 22,
              paddingTop: '30px',
              paddingLeft: '50px',
              fontWeight: 'bold',
            }}
          >
            Produkt hinzufügen
          </Typography>
          <form
            onSubmit={formik.handleSubmit}
            style={{
              padding: '10px 50px 50px 50px',
              borderRadius: '1rem',
            }}
          >
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <InputLabel
                  sx={{ fontWeight: '700', fontSize: '25px' }}
                  shrink
                  htmlFor='product_name'
                >
                  Bezeichnung
                  <span style={{ color: 'red', fontSize: '27px' }}>*</span>
                </InputLabel>
                <TextField
                  fullWidth
                  id='product_name'
                  name='product_name'
                  placeholder='Art, Farbe, Größe: Beispiel: Body, Blau, 52'
                  size='small'
                  value={formik.values.product_name}
                  onChange={formik.handleChange}
                  sx={{
                    '& label': {
                      color: 'black',
                    },
                    '& input': {
                      backgroundColor: '#fdfcf6',
                    },
                  }}
                />
                {formik.errors.product_name && formik.touched.product_name && (
                  <div style={{ color: 'red', padding: 2 }}>
                    {formik.errors.product_name}
                  </div>
                )}
              </Grid>
              <Grid item xs={12} sm={6}>
                <InputLabel
                  sx={{ fontWeight: 700, fontSize: '25px' }}
                  shrink
                  htmlFor='stands'
                >
                  Regalnummer
                  <span style={{ color: 'red', fontSize: '27px' }}>*</span>
                </InputLabel>
                <Select
                  labelId='stands'
                  id='stands'
                  name='stands'
                  size='small'
                  value={formik.values.stands}
                  onChange={formik.handleChange}
                  label='Auswählen'
                  style={{ minWidth: '100%' }}
                >
                  {userBookingList &&
                    userBookingList.length &&
                    userBookingList.map((booking) => {
                      return (
                        <MenuItem
                          key={'stand_' + booking?.stand_id}
                          value={booking?.stand_id}
                        >
                          {booking?.stand?.label}
                        </MenuItem>
                      );
                    })}
                </Select>
                {formik.errors.stands && formik.touched.stands && (
                  <div style={{ color: 'red', padding: 2 }}>
                    {formik.errors.stands}
                  </div>
                )}
              </Grid>
              {/* <Grid item xs={12} sm={6}>
                <InputLabel
                  sx={{ fontWeight: 700, fontSize: "20px" }}
                  shrink
                  htmlFor="manufacturer_name"
                >
                  Name des Herstellers
                </InputLabel>
                <TextField
                  fullWidth
                  id="manufacturer_name"
                  name="manufacturer_name"
                  size="small"
                  placeholder="Name des Herstellers"
                  value={formik.values.manufacturer_name}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.manufacturer_name &&
                    Boolean(formik.errors.manufacturer_name)
                  }
                  sx={{
                    "& label": {
                      color: "black",
                    },
                    "& input": {
                      backgroundColor: "#fdfcf6",
                    },
                  }}
                  helperText={
                    formik.touched.manufacturer_name &&
                    formik.errors.manufacturer_name
                  }
                />
              </Grid> */}
              <Grid item xs={12} sm={6}>
                <InputLabel
                  sx={{ fontWeight: 700, fontSize: '25px' }}
                  shrink
                  htmlFor='category'
                >
                  Kategorie
                  <span style={{ color: 'red', fontSize: '27px' }}>*</span>
                </InputLabel>
                <Select
                  labelId='category'
                  id='category'
                  name='category'
                  size='small'
                  value={formik.values.category}
                  onChange={formik.handleChange}
                  label='Kategorie'
                  style={{ minWidth: '100%' }}
                >
                  {categories?.length &&
                    categories.map((category) => {
                      return (
                        <MenuItem
                          key={'category_' + category?.id}
                          value={category?.id}
                        >
                          {category?.name}
                        </MenuItem>
                      );
                    })}
                </Select>
                {formik.errors.category && formik.touched.category && (
                  <div style={{ color: 'red', padding: 2 }}>
                    {formik.errors.category}
                  </div>
                )}
              </Grid>

              <Grid item xs={12} sm={6}>
                <InputLabel
                  sx={{ fontWeight: 700, fontSize: '25px' }}
                  shrink
                  htmlFor='manufacturer_brand'
                >
                  Marke
                </InputLabel>
                <TextField
                  fullWidth
                  size='small'
                  id='manufacturer_brand'
                  name='manufacturer_brand'
                  placeholder='Beispiel: H&M'
                  value={formik.values.manufacturer_brand}
                  onChange={formik.handleChange}
                  sx={{
                    '& label': {
                      color: 'black',
                    },
                    '& input': {
                      backgroundColor: '#fdfcf6',
                    },
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <InputLabel
                  sx={{ fontWeight: 700, fontSize: '25px' }}
                  shrink
                  htmlFor='quantity'
                >
                  Menge<span style={{ color: 'red', fontSize: '27px' }}>*</span>
                </InputLabel>
                <TextField
                  fullWidth
                  id='quantity'
                  size='small'
                  name='quantity'
                  placeholder='Menge'
                  value={formik.values.quantity}
                  onChange={formik.handleChange}
                  onBlur={formik.handleChange}
                  sx={{
                    '& label': {
                      color: 'black',
                    },
                    '& input': {
                      backgroundColor: '#fdfcf6',
                    },
                  }}
                />
                {formik.errors.quantity && formik.touched.quantity && (
                  <div style={{ color: 'red', padding: 2 }}>
                    {formik.errors.quantity}
                  </div>
                )}
              </Grid>

              <Grid item xs={12} sm={6}>
                <Grid item xs={12} sm={12}>
                  <InputLabel
                    sx={{ fontWeight: 700, fontSize: '25px' }}
                    shrink
                    htmlFor='price'
                  >
                    Preis
                    <span style={{ color: 'red', fontSize: '27px' }}>*</span>
                  </InputLabel>
                  <CurrencyInput
                    id='price'
                    name='price'
                    size='small'
                    decimalsLimit={2}
                    placeholder={'0,00'}
                    allowDecimals={true}
                    decimalSeparator=','
                    prefix='€'
                    groupSeparator='.'
                    value={formik.values.price}
                    onValueChange={(value, name) => {
                      // setPrice(value.replace(',','.'))
                      formik.setFieldValue('price', value);
                      // formik.values.price = value?value.replace(',','.'):""
                    }}
                    style={{
                      backgroundColor: '#fdfcf6',
                      padding: '4px 4px',
                      border: '1px solid #9e9e9e',
                      height: '3em',
                      margin: 0,
                      display: 'block',
                      paddingTop: '1px',
                      // padding: "8.5px 14px",
                      borderRadius: '4px',
                      width: '100%',
                    }}
                  />
                  {formik.errors.price && formik.touched.price && (
                    <div style={{ color: 'red', padding: 2 }}>
                      {formik.errors.price}
                    </div>
                  )}
                  {/* <TextField
                    fullWidth
                    id="price"
                    name="price"
                    size="small"
                    placeholder="Beispiel: 2,00€"
                    value={formik.values.price == "" ? formik.values.price : mask(formik.values.price)}
                    onChange={formik.handleChange}
                    error={formik.touched.price && Boolean(formik.errors.price)}
                    sx={{
                      "& label": {
                        color: "black",
                      },
                      "& input": {
                        backgroundColor: "#fdfcf6",
                      },
                    }}
                    helperText={formik.touched.price && formik.errors.price}
                  /> */}
                </Grid>
                {/* <Grid item xs={12} sm={12}>
                  <InputLabel
                    sx={{
                      fontWeight: 700,
                      fontSize: "20px",
                      marginTop: "10px",
                    }}
                    shrink
                    htmlFor="bookings"
                  >
                    Buchungen
                  </InputLabel>
                  <Select
                    labelId="bookings"
                    id="bookings"
                    name="bookings"
                    size="small"
                    value={formik.values.bookings}
                    onChange={formik.handleChange}
                    label="Buchungen"
                    style={{ minWidth: "100%" }}
                  >
                    {userBookingList && userBookingList.length && userBookingList.map((booking) => {
                      return <MenuItem key={'booking_' + booking?.id} value={booking?.id}>{booking?.id}</MenuItem>
                    })}
                  </Select>

                </Grid> */}
              </Grid>

              <Grid item xs={12} sm={6}>
                <InputLabel
                  sx={{ fontWeight: 700, fontSize: '20px' }}
                  shrink
                  htmlFor='description'
                >
                  Besonderheiten
                </InputLabel>
                <TextareaAutosize
                  minRows={4}
                  name='description'
                  size='small'
                  placeholder='Blau-Weiß gestreift mit Herzen '
                  maxRows={6}
                  value={formik.values.description}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.description && formik.errors.description
                  }
                  style={{
                    lineHeight: 1.5,
                    width: '100%',
                    padding: '12px',
                    borderRadius: '12px 12px 0 12px',
                    backgroundColor: '#fdfcf6',
                  }}
                  sx={{
                    width: '100%',
                    maxWidth: '100%',
                    '& label': {
                      color: 'black',
                    },
                    '& input': {
                      backgroundColor: '#fdfcf6',
                    },
                  }}
                ></TextareaAutosize>
              </Grid>
            </Grid>
            <Box sx={{ display: 'flex', mt: 2 }}>
              <Button
                type='submit'
                sx={{ textTransform: 'capitalize' }}
                variant='contained'
                style={{ background: '#b89981' }}
                size='large'
              >
                Produkt hinzufügen
              </Button>
            </Box>
          </form>
        </Paper>
      </div>
    </div>
  );
}

export default AddProduct;
