import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import http from '../../http';
import moment from 'moment';

import {
  Box,
  FormControl,
  MenuItem,
  Select,
  Stack,
  TablePagination,
  Typography,
} from '@mui/material';
import Pagination from '../my-profile/Pagination';
import { Link } from 'react-router-dom';

export default function UsersLists() {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(50);
  const [products, setProducts] = React.useState([]);
  const [hasNewsOffer, setHasNewsOffer] = React.useState('');
  const [age, setAge] = React.useState('');

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const onPageChange = (event, newPage) => {
    // if (newPage < Math.round(products?.length / rowsPerPage)) {
    setPage(newPage);
    // } else {
    // Math.max(0, Math.ceil(products?.length / rowsPerPage));
    // }
  };

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(
      event,
      Math.max(0, Math.ceil(products?.length / rowsPerPage) - 1)
    );
  };

  React.useEffect(() => {
    const fetchUserData = async () => {
      try {
        const id = localStorage.getItem('id');
        let config = {
          method: 'get',
          maxBodyLength: Infinity,
          url: '/user/get-all-users',
        };
        http
          .request(config)
          .then((response) => {
            const { data } = response.data;
            setProducts(response.data.data);
          })
          .catch((error) => {
            console.log(error);
          });
      } catch (error) {
        console.log('Error fetching user data', error);
      }
    };

    fetchUserData();
  }, []);

  return (
    <Box
      sx={{
        m: 'auto',
        p: 2,
        paddingTop: 5,
      }}
      component={Paper}
    >
      <Typography
        sx={{ fontSize: '25px', marginBottom: '30px', fontWeight: 'bold' }}
      >
        Users Lists
      </Typography>
      <TableContainer>
        <FormControl
          sx={{
            width: {
              sm: '100%',
              xs: '100%',
            },
            marginBottom: '10px',
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
            }}
          >
            <select
              style={{ padding: '6px', marginBottom: '4px', marginTop: '6px' }}
              onChange={handleChangeRowsPerPage}
            >
              {[50, 100, 150, 200].map((pageSize) => (
                //  {[1, 2, 3,5,6].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  zeigen {pageSize}
                </option>
              ))}
            </select>

            <select
              style={{ padding: '6px', marginBottom: '4px', marginTop: '6px' }}
              value={hasNewsOffer}
              onChange={(event) => setHasNewsOffer(event.target.value)}
            >
              <option value=''>Has News Offer?</option>
              <option value='yes'>Yes</option>
              <option value='no'>No</option>
            </select>
          </div>
        </FormControl>

        <Table aria-label='a dense table'>
          <TableHead sx={{ bgcolor: '#e4f0f1' }}>
            <TableRow>
              <TableCell align='left' sx={{ fontWeight: '600' }}>
                ID
              </TableCell>{' '}
              <TableCell align='left' sx={{ fontWeight: '600' }}>
                Name
              </TableCell>{' '}
              <TableCell align='left' sx={{ fontWeight: '600' }}>
                Email
              </TableCell>
              <TableCell align='left' sx={{ fontWeight: '600' }}>
                News Offer
              </TableCell>
              <TableCell align='left' sx={{ fontWeight: '600' }}>
                Wallet
              </TableCell>
              <TableCell align='left' sx={{ fontWeight: '600' }}>
                Date
              </TableCell>
              <TableCell align='left' sx={{ fontWeight: '600' }}>
                Actions
              </TableCell>
            </TableRow>
          </TableHead>
          {(rowsPerPage > 0
            ? products.slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage
              )
            : products
          )
            ?.filter((product) =>
              hasNewsOffer === 'yes'
                ? product.news_offer
                : hasNewsOffer === 'no'
                ? !product.news_offer
                : true
            )
            ?.map((valued, index) => (
              <TableBody>
                <TableRow
                  sx={{
                    background: '#F9F9F9 ',
                  }}
                >
                  <TableCell sx={{}}>{valued?.id} </TableCell>
                  <TableCell sx={{}}>
                    {valued?.first_name} {valued?.last_name}{' '}
                  </TableCell>
                  <TableCell align='left' sx={{}}>
                    {valued?.email}
                  </TableCell>
                  <TableCell align='left' sx={{}}>
                    {valued?.news_offer ? 'Yes' : 'No'}
                  </TableCell>
                  <TableCell align='left' sx={{}}>
                    {valued?.wallet}
                  </TableCell>

                  <TableCell align='left' sx={{}}>
                    {' '}
                    {moment(valued.createdAt).format('DD-MM-YYYY')}
                  </TableCell>

                  <TableCell align='left' sx={{}}>
                    {' '}
                    <Link
                      // style={{ color: "blue", textDecoration: "none" }}
                      to={`/user-details?user_id=${valued?.id}`}
                    >
                      View
                    </Link>
                  </TableCell>
                </TableRow>
              </TableBody>
            ))}
        </Table>
      </TableContainer>
      <Box
        sx={{ p: 2, display: 'flex', justifyContent: 'end' }}
        // component={Paper}
      >
        <Pagination
          products={products}
          page={page}
          rowsPerPage={rowsPerPage}
          handleFirstPageButtonClick={handleFirstPageButtonClick}
          handleBackButtonClick={handleBackButtonClick}
          handleNextButtonClick={handleNextButtonClick}
          handleLastPageButtonClick={handleLastPageButtonClick}
          handleChangePage={onPageChange}
        />
      </Box>
    </Box>
  );
}
