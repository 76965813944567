import React from "react";
import image from "../../img/bg-ico-hero.jpg";
const BannerImage = () => {
  return (
    <div style={{}}>
      <img
        src={image}
        alt="main hero image"
        style={{
          height: "75vh",
          objectPosition: "center",
          width: "100vw",
          objectFit: "cover",
        }}
      />
    </div>
  );
};

export default BannerImage;

{
  /* <img src="./images/bg-ico-hero.jpg" style={{height:'70vh',backgroundSize:'cover',backgroundPosition:'center'}} /> */
}
