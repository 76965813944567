import { Box, Typography } from "@mui/material";
import React from "react";
import logo from "../../img/logo.png";
import "./styles/letter.css";

const Impressum = () => {
  return (
    <div style={{ padding: "20px" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection:{xs:"column-reverse", lg:"row"},
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            display: "flex",
            marginTop: "20px",
            justifyContent: "center",
            width: "100%",
            // marginLeft: "220px",
          }}
        >
          <Typography
            style={{
              fontWeight: "bold",
              textAlign: "center",
              fontSize: "30px",
            }}
          >
            Impressum
          </Typography>
        </Box>


        <img src={logo} width="200px" height="200px" />
      
      </Box>

      <Box className="text-container">
        <ol style={{ fontWeight: "bold" }} className="orderd-list">
          <li sx={{ fontSize: 22, fontWeight: "bold", marginTop: "2%" }}>
            <Typography
              style={{
                fontWeight: "bold",
                fontSize: "22px",
              }}
            >
              Angaben gemäß § 5 TMG
            </Typography>

            <Typography
              style={{
                fontSize: "18px",
                wordSpacing: "5px",
                lineHeight: 2,
              }}
            >
              Mirzoyan GbR<br></br>
              Kieler Straße 113<br></br>
              24119 Kronshagen<br></br>
            </Typography>
          </li>

          <li style={{ fontSize: 22, fontWeight: "bold", marginTop: "2%" }}>
            <Typography
              style={{
                fontWeight: "bold",
                fontSize: "22px",
              }}
            >
              Vertreten durch:
            </Typography>
                <Typography
                  style={{
                    fontSize: "18px",
                    wordSpacing: "5px",
                    lineHeight: 2,
                  }}
                >
                  Edgar Mirzoyan<br></br>
                  Christina Mirzoyan<br></br>
                  Mikael Mirzoyan
                </Typography>
          </li>

          <li style={{ fontSize: 22, fontWeight: "bold", marginTop: "2%" }}>
            <Typography
              style={{
                fontWeight: "bold",
                fontSize: "22px",
              }}
            >
              Kontakt
            </Typography>


                <Typography
                  style={{
                    fontSize: "18px",
                    wordSpacing: "5px",
                    lineHeight: 2,
                  }}
                >
                  Telefon: 0431 - 59674213<br></br>
                  E-Mail: info@minmini.de<br></br><br></br>
                  Umsatzsteuer-Identifikationsnummer gem. § 27a UStG: DE 361131804
                </Typography>
          </li>

          <li style={{ fontSize: 22, fontWeight: "bold", marginTop: "2%" }}>
            <Typography
              style={{
                fontWeight: "bold",
                fontSize: "22px",
              }}
            >
              EU-Streitschlichtung
            </Typography>

                <Typography
                  style={{
                    fontSize: "18px",
                    wordSpacing: "5px",
                    lineHeight: 2,
                  }}
                >
                  Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit: https://ec.europa.eu/consumers/odr/.<br></br>
                  Unsere E-Mail-Adresse finden Sie oben im Impressum.
                </Typography>
          </li>

          <li style={{ fontSize: 22, fontWeight: "bold", marginTop: "2%" }}>
            <Typography
              style={{
                fontWeight: "bold",
                fontSize: "22px",
              }}
            >
             Verbraucherstreitbeilegung/Universalschlichtungsstelle
            </Typography>

                <Typography
                  style={{
                    fontSize: "18px",
                    wordSpacing: "5px",
                    lineHeight: 2,
                  }}
                >
                  Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.
                  <br></br><br></br>
                  Quelle:<br></br>
                  e-recht24.de
                </Typography>

          </li>
        </ol>
      </Box>
    </div>
  );
};

export default Impressum;
