import React, { useState, useEffect } from 'react';
import {
  Grid,
  Card,
  CardContent,
  Typography,
  Button,
  Box,
  Fab,
  CardActions,
} from '@mui/material';
import { EXTRAS } from './constants';

const MyComponent = ({ setActive, setExtraItem }) => {
  const [extras, setExtras] = useState(EXTRAS);
  const [isNext, setIsNext] = useState(false);

  const selectPackage = (item) => {
    const packagesClone = [...extras];
    const newUpdatedList = packagesClone.map((day) => {
      if (day.id === item?.id) {
        day['isSelected'] = true;
        return day;
      } else {
        day['isSelected'] = false;
        return day;
      }
    });
    setExtras(newUpdatedList);
    setExtraItem(item);
    setIsNext(true);
  };

  return (
    <Grid
      container
      spacing={3}
      justifyContent={'center'}
      style={{ width: '100%', margin: 'auto' }}
    >
      {extras &&
        extras.map((item) => {
          return (
            <Grid item xs={12} sm={10} lg={4}>
              <Card
                sx={{
                  height: '100%',
                  borderRadius: '10px',
                  padding: '10px',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                }}
              >
                <CardContent>
                  <Typography
                    variant='h5'
                    component='div'
                    align='center'
                    sx={{ paddingBottom: '20px', fontWeight: 600 }}
                  >
                    {item?.title}
                  </Typography>
                  <ul style={{ paddingLeft: '12px' }}>
                    {item?.features.map((item) => {
                      return <li style={{ paddingBottom: '20px' }}> {item}</li>;
                    })}
                  </ul>
                </CardContent>
                <CardActions sx={{ display: 'flex', justifyContent: 'center' }}>
                  <Button
                    onClick={() => selectPackage(item)}
                    size='large'
                    variant='contained'
                    sx={{
                      ':hover': {
                        background: item?.isSelected ? '#e9c396' : '#bac2be',
                      },
                      width: '90%',
                      background: item?.isSelected ? '#e9c396' : '#bac2be',
                      fontWeight: 'bold',
                      fontSize: 18,
                    }}
                  >
                    {`+ ${item?.price},00 €`}
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          );
        })}

      <Grid item xs={12} sm={7} lg={12}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            marginTop: '26px',
          }}
        >
          <Button
            variant='contained'
            style={{
              marginRight: '8px',
              background: '#b98b8b',
              marginRight: '37px',
            }}
            onClick={() => {
              setActive(3);
            }}
          >
            Zurück
          </Button>
          <Button
            variant='contained'
            style={{
              marginRight: '8px',
              background: isNext ? '#b98b8b' : '#7a7e80',
              color: '#fff',
            }}
            onClick={() => {
              setActive(5);
            }}
          >
            Weiter
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};

export default MyComponent;
