import http from '../../http';
import login from '../../img/login.png';
import { AiFillLock } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import {
  Grid,
  TextField,
  Button,
  Box,
  FormControlLabel,
  Checkbox,
  InputLabel,
  Paper,
  IconButton,
} from '@mui/material';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Dashboard, House } from '@mui/icons-material';

const ForgotPassword = () => {
  const [data, setData] = useState({
    email: '',
    password: '',
  });
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const handleInput = (e) => {
    const { name, value } = e.target;

    setData((preVal) => {
      return {
        ...preVal,
        [name]: value,
      };
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    http
      .request({
        method: 'post',
        maxBodyLength: Infinity,
        url: '/auth/forgot-password',
        headers: {
          'Content-Type': 'application/json',
        },
        data: data,
      })
      .then((response) => {
        alert(response?.data?.message);
        navigate('/login');
      })
      .catch(function (error) {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log(error?.response?.data);
          alert(error?.response?.data?.message);
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser
          // and an instance of http.ClientRequest in node.js
          console.log(error.request);
          alert(error?.request?.message);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log('Error', error?.message);
          alert(error?.message);
        }
      });
  };
  const handleHome = () => {
    navigate('/');
  };

  return (
    <Box sx={{ mt: 5 }}>
      <Paper
        sx={{
          width: { xs: '90%', sm: '40%', md: '35%', lg: '30%' },
          margin: 'auto',
          alignItems: 'center',
          minHeight: '45vh',
          p: '1rem',
          borderRadius: '20px',
        }}
        elevation={1}
      >
        <form>
          <Grid container spacing={3} justifyContent={'center'}>
            <Grid item xs={12} sm={11}>
              <Box
                sx={{
                  display: {
                    xs: 'block',
                    sm: 'flex',
                  },
                  justifyContent: {
                    xs: 'space-between',
                  },
                  alignItems: 'center',
                }}
              >
                <Box>
                  <h1 style={{ fontSize: '16px' }}>Forgot password !</h1>
                  <h2 style={{ fontSize: '13px' }}>
                    {' '}
                    Lost your password? Please enter your username or email
                    address. You will receive a link to create a new password
                    via email.
                  </h2>
                </Box>
                <Box
                  display='flex'
                  justifyContent='center'
                  flexDirection='Column'
                  alignItems='center'
                ></Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={11}>
              <InputLabel
                sx={{ fontWeight: 700, fontSize: '20px' }}
                shrink
                htmlFor='email'
              >
                Email
              </InputLabel>
              <TextField
                fullWidth
                size='small'
                id='email'
                name='email'
                value={data.email}
                placeholder='Enter Your Email'
                onChange={handleInput}
                sx={{
                  '& label': {
                    color: 'black',
                  },
                  '& input': {
                    backgroundColor: 'white',
                  },
                }}
              />
            </Grid>

            <Grid item xs={12} sm={11}>
              <Box sx={{ display: 'flex', justifyContent: 'left' }}>
                <Button
                  onClick={handleSubmit}
                  sx={{ textTransform: 'capitalize' }}
                  variant='contained'
                  style={{ background: '#b89981', width: '100%' }}
                  size='large'
                >
                  Send email
                </Button>
              </Box>
            </Grid>

            <Grid item xs={12} sm={8}>
              <Link to={'/login'} style={{ textDecoration: 'none' }}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    fontSize: '13px',
                    mt: 1,
                    pb: 1,
                  }}
                >
                  <AiFillLock style={{ marginTop: 3 }} /> &nbsp; Sing in instead
                </Box>
              </Link>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </Box>
  );
};
export default ForgotPassword;
