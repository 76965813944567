import { Grid, Link } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import footerImg2 from "../../img/logo.png";

import "../../App.css";
const Footer = () => {
  return (
    <Box display="flex" style={{ background: "#e1b6b4", marginTop:"20px" }}>
      <Box
        display="flex"
        flexGrow={1}
            
        alignItems="center"
        sx={{ m: 2, p: 2,
          padding:{
            sm:2,
            xs:0,
          },
        justifyContent:{
           xs:'left',
           sm:'center'
        },
    
      
        }}
        className="b"
      >
        <Link
          href="/impressum"
          style={{ color: "black", fontSize: "medium", marginLeft: "0px",fontWeight:600}}
          underline="none"
        >
          {" "}
          {"Impressum"}
        </Link>
        <Link
          href="/letter"
          style={{ color: "black", fontSize: "medium",fontWeight:600 }}
          sx={{
            marginLeft: {
              xs: "8px",
              sm: "30px",
            },
          }}
          underline="none"
        >
          {" "}
          {"AGB"}
        </Link>
        <Link
          href="/urban"
          style={{ color: "black", fontSize: "medium",fontWeight:600 }}
          underline="none"
          sx={{
            marginLeft: {
              xs: "8px",
              sm: "30px",
            },
          }}
        >
          {" "}
          {"Widerruf"}
        </Link>
        <Link
          href="/datenschutz"
          style={{ color: "black", fontSize: "medium",fontWeight:600 }}
          sx={{
            display:{
          sm:"block",
          xs:'none'
            },
            marginLeft: {
              xs: "8px",
              sm: "30px",
            },
          }}
          underline="none"
        >
          {" "}
          {"Datenschutz"}
        </Link>
        <Link
          href="/faq"
          style={{ color: "black", fontSize: "medium",fontWeight:600 }}
          sx={{
            marginLeft: {
              xs: "8px",
              sm: "30px",
            },
          }}
          underline="none"
        >
          {" "}
          {"FAQ"}
        </Link>
      </Box>
      <img
        src={footerImg2}
        width="120px"
        height="120px"
        style={{ padding: "0px" }}
        className="fimg"
      />
    </Box>
  );
};

export default Footer;
