import React, { useRef } from 'react';
import {
  Typography,
  Link,
  Breadcrumbs,
  Grid,
  TextField,
  Button,
  Box,
  InputLabel,
  Paper,
  MenuItem,
  Select,
  Modal,
} from '@mui/material';
import { TextareaAutosize } from '@mui/base/TextareaAutosize';
import * as Yup from 'yup';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import http from '../../http';
import CurrencyInput from 'react-currency-input-field';
import AlertDialogSlide from '../Dialog/Dialog';

function ApplyDiscount() {
  const navigate = useNavigate();
  const [discount, setDiscount] = useState(0.2);
  const [error, setError] = useState('');
  const [isApplying, setIsApplying] = React.useState(false);
  const [latestBookings, setLatestBookings] = useState([]);
  const [stand, setStand] = useState('');

  useEffect(() => {
    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: '/booking/get-user-current-bookings?limit=10000&page=1',
    };
    http
      .request(config)
      .then((response) => {
        if (response.data.response.data)
          setLatestBookings(response.data.response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const handleChange = (event) => {
    setDiscount(event.target.value);
  };

  const handleStandChange = (event) => {
    setStand(event.target.value);
  };

  const applyDiscount = async () => {
    if (!stand || stand === '') {
      alert('Please select a stand!');
      return;
    }

    setIsApplying(true);
    try {
      const id = localStorage.getItem('id');
      const response = await http.post(
        `/product/apply-discount-by-user-and-stand-id/${id}`,
        { discount: discount, standId: stand }
      );
      setIsApplying(false);
      navigate('/my-profile/products');
    } catch (error) {
      console.log('Error fetching user data', error);
      setIsApplying(false);
      if (error.message === 'Request failed with status code 502')
        setError('Something went wrong. Please try again!');
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    applyDiscount();
  };

  const buttonRef = useRef(null);

  const onSuccess = () => {
    buttonRef.current.click();
  };

  return (
    <div style={{ backgroundColor: '#fdfcf6', height: '100vh' }}>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Paper
          sx={{
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '60vh',
            borderRadius: '1rem',
            bgcolor: '#fdfcf6',
          }}
        >
          <Typography
            sx={{
              fontSize: 30,
              paddingTop: '30px',
              paddingLeft: '50px',
              paddingRight: '20px',
              fontWeight: 'bold',
            }}
          >
            Um wie viel Prozent möchtest du deine Artikel rabattieren?
          </Typography>
          <form
            onSubmit={handleSubmit}
            style={{
              padding: '10px 50px 50px 50px',
              textAlign: 'center',
              borderRadius: '1rem',
            }}
          >
            <Grid item xs={12} sm={6}>
              <label
                sx={{ fontWeight: 700, fontSize: '25px', width: '100%' }}
                shrink
                htmlFor='stands'
              >
                Stands
              </label>
              <br /> {/* Add a line break here */}
              <select
                style={{
                  width: '30%',
                  padding: '5px', // Reduce padding to make it smaller
                  fontSize: '16px',
                  textAlign: 'center',
                  height: '30px', // Adjust the height to make it smaller
                }}
                value={stand}
                onChange={handleStandChange}
                required
              >
                <option value='' disabled>
                  Select your stand
                </option>
                {latestBookings.map((booking) => (
                  <option key={booking.stand_id} value={booking.stand_id}>
                    {booking.stand_id}
                  </option>
                ))}
              </select>
            </Grid>

            <Grid item xs={12} sm={6}>
              <label
                sx={{ fontWeight: 700, fontSize: '25px', width: '100%' }}
                shrink
                htmlFor='stands'
              >
                Rabatt in Prozent
              </label>
              <br /> {/* Add a line break here */}
              <select
                style={{
                  width: '30%',
                  padding: '5px', // Reduce padding to make it smaller
                  fontSize: '16px',
                  textAlign: 'center',
                  height: '30px', // Adjust the height to make it smaller
                }}
                onChange={handleChange}
              >
                <option value={0.2}>20</option>
                <option value={0.3}>30</option>
                <option value={0.5}>50</option>
                <option value={0.8}>80</option>
              </select>
            </Grid>

            <Box sx={{ display: 'flex', mt: 2 }}>
              <Button
                type='button'
                ref={buttonRef}
                onClick={applyDiscount}
                sx={{ textTransform: 'capitalize', display: 'none' }}
                variant='contained'
                style={{ background: '#b89981' }}
                size='large'
              >
                Apply Discount
              </Button>
              <AlertDialogSlide
                onSuccess={onSuccess}
                error={error}
                applying={isApplying}
                setError={setError}
              />
            </Box>
            {error && (
              <p style={{ color: 'red', fontSize: '12px', marginTop: '10px' }}>
                {error}
              </p>
            )}
          </form>
        </Paper>
      </div>
    </div>
  );
}

export default ApplyDiscount;
