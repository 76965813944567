import React from 'react'
import UpdateForm from './Form'
import PasswordForm from './PasswordForm'
import { Box } from '@mui/material'
function Profile() {
  return (
        <Box sx={{ py: "1rem", minHeight: "100vh" }}>
          <UpdateForm />
          <PasswordForm />
        </Box>
  )
}

export default Profile