import * as React from 'react';
import { useTable, useSortBy } from 'react-table';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import http from '../../http';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { Box, Button, Grid } from '@mui/material';
import Pagination from './Pagination';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Barcode from 'react-barcode';
import ReactToPrint from 'react-to-print';

const pageStyle = `
  @page {
    size: 210mm 297mm;
    margin: 7% 5%;
  }

  body {
    padding: 0;
  }

  @media all {
    .pageBreak {
      display: none;
    }
  }

  @media print {
    .pageBreak {
      page-break-before: always;
    }
  }
`;

const classes = {
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: 20,
    textAlign: 'center',
    color: 'blue',
    fontFamily: 'Roboto',
  },
};

export default function StandsProductsDetails() {
  const params = useParams();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [products, setProducts] = React.useState([]);
  const [newProducts, setNewProducts] = React.useState([]);
  const [update, setUpdate] = React.useState(false);
  const [updatedItemId, setUpdatedItemId] = React.useState(null);
  const [add, setAdd] = React.useState(false);
  const [filterNew, setFilterNew] = React.useState(false);
  const [searchValue, setSearchValue] = React.useState('');
  const [filterProducts, setFilterProducts] = React.useState([]);

  const handleClickOpen = () => {
    setAdd(true);
  };

  const handleClose = () => {
    setAdd(false);
    setFilterNew(false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleClickUpdateOpen = () => {
    setUpdate(true);
  };

  const handleUpdateClose = () => {
    setUpdate(false);
  };

  const descriptionElementRef = React.useRef(null);
  const ref = React.useRef();
  const printAll = React.useRef();

  React.useEffect(() => {
    if (update) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [update]);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  React.useEffect(() => {
    const fetchUserData = async () => {
      try {
        const id = localStorage.getItem('id');
        let config = {
          method: 'get',
          maxBodyLength: Infinity,
          url: `/product/get-all-by-stand-id/${params?.id}`,
          headers: {},
        };

        http
          .request(config)
          .then((response) => {
            const responseProducts = response?.data?.data;
            setProducts(responseProducts);
            setNewProducts(
              responseProducts?.filter(
                (responseProduct) => responseProduct.is_printed === 0
              )
            );
          })
          .catch((error) => {
            console.log(error);
          });
      } catch (error) {
        console.log('Error fetching user data', error);
      }
    };

    fetchUserData();
  }, []);

  const setIsPrintedToTrue = (productIds) => {
    try {
      let config = {
        method: 'post',
        data: { ProductIDs: productIds },
        url: `/product/update-print-status-by-product-ids`,
        headers: {},
      };

      http
        .request(config)
        .then((response) => {
          if (response.data.status) {
            const productIdsSet = new Set(productIds);

            const updatedProducts = products.map((product) =>
              productIdsSet.has(product.id)
                ? { ...product, is_printed: true }
                : product
            );

            const updatedNewProducts = newProducts.filter(
              (newProduct) => !productIdsSet.has(newProduct.id)
            );

            setProducts(updatedProducts);
            setNewProducts(updatedNewProducts);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log('Error updating', error);
    }
  };

  const columns = React.useMemo(
    () => [
      { Header: 'User Id', accessor: 'user_id' },
      { Header: 'Product SKU', accessor: 'sku' },
      { Header: 'Product Name', accessor: 'title' },
      { Header: 'Manufacturer Name', accessor: 'manufacturer' },
      { Header: 'Brand Name', accessor: 'brand_name' },
      { Header: 'Price', accessor: 'price' },
      { Header: 'Quantity', accessor: 'quantity' },
      {
        Header: 'Status',
        accessor: 'is_added_to_pos',
        Cell: (row) =>
          row.value == 1 ? 'Aktiv' : row.value == 2 ? 'Verkauft' : 'Neu',
      },
      {
        Header: 'Inventory Changes',
        accessor: 'inventory_change_timestamps',
        Cell: ({ value }) => {
          if (!value) return null;

          const formattedDates = value.split(',').map((timestamp, index) => {
            const formattedDate = moment(new Date(timestamp)).format(
              'DD-MM-YYYY'
            );
            return (
              <div key={index} style={{ minWidth: '100px' }}>
                {' '}
                {index + 1}. {formattedDate}
              </div>
            );
          });

          return <div>{formattedDates}</div>;
        },
      },
      {
        Header: 'Date',
        accessor: 'createdAt',
        Cell: (row) => moment(row.value).format('DD-MM-YYYY'),
      },
      {
        Header: 'View Details',
        accessor: '',
        Cell: ({ row }) => (
          <Button
            onClick={() => {
              setUpdatedItemId(row.original);
              setUpdate(true);
            }}
            style={{
              borderRadius: '2px',
              backgroundColor: '#d0a667',
              color: 'white',
            }}
          >
            Print Tag
          </Button>
        ),
      },
    ],
    [setUpdatedItemId, setUpdate]
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data: filterProducts }, useSortBy);

  const { filteredProducts } = React.useMemo(() => {
    return {
      filteredProducts: products.filter((curr) => {
        if (!searchValue) {
          return true;
        }

        const searchLowerCase = searchValue.toLowerCase();
        const status =
          curr?.is_added_to_pos == 1
            ? 'Aktiv'
            : curr?.is_added_to_pos == 2
            ? 'Verkauft'
            : 'Neu';

        return (
          curr?.user_id?.toString().includes(searchLowerCase) ||
          curr?.sku?.toLowerCase().includes(searchLowerCase) ||
          curr?.title?.toLowerCase().includes(searchLowerCase) ||
          curr?.manufacturer?.toLowerCase().includes(searchLowerCase) ||
          curr?.brand_name?.toLowerCase().includes(searchLowerCase) ||
          curr?.price?.includes(searchLowerCase) ||
          curr?.quantity?.toString().includes(searchLowerCase) ||
          status?.toLowerCase().includes(searchLowerCase)
        );
      }),
    };
  }, [products, searchValue]);

  React.useEffect(() => {
    setFilterProducts([...filteredProducts]);
  }, [filteredProducts]);

  return (
    <Box sx={{ pr: 2 }}>
      {/* <Box sx={{ maxWidth: { xs: "98%", sm: "85%", }, m: "auto", p: 2 }} > */}
      <Box
        sx={{ display: 'flex', justifyContent: 'center', gap: '2rem' }}
      ></Box>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <div>
          <Button
            onClick={handleClickOpen}
            size='small'
            sx={{
              ':hover': {
                backgroundColor: '#967543',
              },
              fontSize: '15px',
              width: '10rem',
              borderRadius: '2px',
              color: 'white',
              backgroundColor: '#d0a667',
            }}
          >
            Print All Tags
          </Button>
          <Button
            onClick={() => {
              setFilterNew(true);
              handleClickOpen();
            }}
            size='small'
            sx={{
              ':hover': {
                backgroundColor: '#967543',
              },
              fontSize: '15px',
              width: '10rem',
              borderRadius: '2px',
              color: 'white',
              backgroundColor: '#d0a667',
            }}
            style={{ marginLeft: '10px' }}
          >
            Print New Tags
          </Button>
        </div>
        <div>
          <input
            type='search'
            onChange={(event) => setSearchValue(event.target.value)}
            placeholder='Suche...'
            style={{ padding: '8px', marginBottom: '6px' }}
          />
        </div>
      </div>
      <br /> <br />
      <Dialog
        open={update}
        onClose={handleUpdateClose}
        scroll={'paper'}
        aria-labelledby='scroll-dialog-title'
        aria-describedby='scroll-dialog-description'
        sx={{ margin: 10 }}
      >
        <DialogTitle id='scroll-dialog-title'>Price Tag</DialogTitle>
        <DialogContent
          ref={ref}
          dividers={scroll === 'paper'}
          className='labelsDialog'
        >
          <div ref={printAll}>
            <div style={classes.root}>
              <Grid
                container
                spacing={1}
                sx={{
                  alignContent: 'center',
                  alignItems: 'center',
                  marginTop: '0px',
                }}
              >
                {Array.from({ length: updatedItemId?.quantity }, (_, index) => (
                  <Grid item xs={3} sm={3} className='labelsGrid'>
                    {/* <Paper style={classes.paper}> */}
                    {/* <div className="border-top" key={key + product?.id}> */}
                    <Box
                      sx={{
                        display: 'flex',
                        fontSize: '6px',
                        paddingBottom: '2px',
                        alignContent: 'space-between',
                        alignItems: 'space-between',
                      }}
                    >
                      <p
                        style={{
                          marginLeft: '10px',
                          paddingTop: '2px',
                          fontSize: '8px',
                        }}
                        className=''
                      >
                        {updatedItemId?.stand_id}
                      </p>
                      <p
                        style={{
                          marginLeft: '10px',
                          paddingTop: '2px',
                          fontSize: '8px',
                        }}
                        className=''
                      >
                        {updatedItemId?.brand_name.length > 13
                          ? updatedItemId.brand_name.slice(0, 13)
                          : updatedItemId?.brand_name}
                      </p>
                      <p
                        style={{
                          marginLeft: 'auto',
                          marginRight: '5px',
                          fontSize: '11px',
                          fontWeight: 'bold',
                          fontFamily: 'Arial',
                        }}
                        className=''
                      >
                        {updatedItemId?.price}€{' '}
                      </p>
                    </Box>

                    {/* <Box sx={{ display: "flex", fontSize: "15px", }}>
                    <p style={{ marginLeft: "4px", fontSize: "15px", }}>Price: </p>
                    <p className=""> €{updatedItemId?.price} </p>
                  </Box> */}

                    {/* <Box sx={{ display: "flex", fontSize: "15px", }}>
                    <p style={{ marginLeft: "4px", fontSize: "15px", }}>Brand: </p>
                    <p className=""> {updatedItemId?.brand_name}</p>
                  </Box> */}

                    <Box
                      sx={{
                        display: 'flex',
                        fontSize: '7px',
                        paddingBottom: '2px',
                      }}
                    >
                      {/* <p style={{ marginLeft: "4px", fontSize: "15px", }}>Name: </p> */}
                      <p
                        className=''
                        style={{
                          marginLeft: '10px',
                          fontSize: '8px',
                          textOverflow: 'ellipsis',
                          overflow: 'hidden',
                          whiteSpace: 'nowrap',
                        }}
                      >
                        {updatedItemId?.title}
                      </p>
                    </Box>

                    <Box
                      sx={{
                        display: 'flex',
                        fontSize: '7px',
                        paddingBottom: '2px',
                      }}
                    >
                      <Barcode
                        value={updatedItemId?.sku}
                        height={20}
                        font={'sans-serif'}
                      />
                    </Box>
                    {/* </div> */}
                    {/* </Paper> */}
                  </Grid>
                ))}
              </Grid>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            size='small'
            sx={{
              fontSize: '15px',
              borderRadius: '2px',
              color: 'red',
              marginRight: 3,
            }}
            onClick={handleUpdateClose}
          >
            Cancel
          </Button>
          <ReactToPrint
            trigger={() => (
              <Button
                size='small'
                sx={{
                  ':hover': {
                    backgroundColor: '#967543',
                  },
                  fontSize: '15px',
                  borderRadius: '2px',
                  color: 'white',
                  backgroundColor: '#d0a667',
                }}
              >
                Print
              </Button>
            )}
            onAfterPrint={() => setIsPrintedToTrue([updatedItemId.id])}
            content={() => printAll.current}
            pageStyle={pageStyle}
          />
        </DialogActions>
      </Dialog>
      <Dialog
        open={add}
        onClose={handleClose}
        scroll={'paper'}
        aria-labelledby='scroll-dialog-title'
        aria-describedby='scroll-dialog-description'
        sx={{ margin: 10 }}
      >
        <DialogTitle id='scroll-dialog-title'>
          {filterNew ? 'New Price Tags' : 'Price Tags'}
        </DialogTitle>
        <DialogContent dividers={scroll === 'paper'} className='labelsDialog'>
          <div ref={printAll}>
            <div style={classes.root}>
              <Grid
                container
                spacing={1}
                sx={{
                  alignContent: 'center',
                  alignItems: 'center',
                  marginTop: '0px',
                }}
              >
                {(filterNew ? newProducts : products)?.map((product, key) => {
                  return Array.from(
                    { length: product?.quantity },
                    (_, index) => (
                      <Grid item xs={3} sm={3} className='labelsGrid'>
                        {/* <Paper style={classes.paper}> */}
                        {/* <div className="border-top" key={key + product?.id}> */}
                        <Box
                          sx={{
                            display: 'flex',
                            fontSize: '6px',
                            paddingBottom: '2px',
                            alignContent: 'space-between',
                            alignItems: 'space-between',
                          }}
                        >
                          <p
                            style={{
                              marginLeft: '10px',
                              paddingTop: '2px',
                              fontSize: '8px',
                            }}
                            className=''
                          >
                            {params?.id}
                          </p>
                          <p
                            style={{
                              marginLeft: '10px',
                              paddingTop: '2px',
                              fontSize: '8px',
                            }}
                            className=''
                          >
                            {product?.brand_name.length > 13
                              ? product.brand_name.slice(0, 13)
                              : product?.brand_name}
                          </p>
                          <p
                            style={{
                              marginLeft: 'auto',
                              marginRight: '5px',
                              fontSize: '11px',
                              fontWeight: 'bold',
                              fontFamily: 'Arial',
                            }}
                            className=''
                          >
                            {product?.price}€{' '}
                          </p>
                        </Box>

                        {/* <Box sx={{ display: "flex", fontSize: "11px", }}>
                        <p style={{ marginLeft: "10px", fontSize: "11px", }}>Price: </p>
                        <p className=""> €{product?.price} </p>
                      </Box> */}

                        {/* <Box sx={{ display: "flex", fontSize: "11px", }}>
                        <p style={{ marginLeft: "10px", fontSize: "11px", }}>Brand: </p>
                        <p className="">{" "} {product?.brand_name}</p>
                      </Box> */}

                        <Box
                          sx={{
                            display: 'flex',
                            fontSize: '7px',
                            paddingBottom: '2px',
                          }}
                        >
                          {/* <p style={{ marginLeft: "10px", fontSize: "11px", }}>Name: </p> */}
                          <p
                            className=''
                            style={{
                              marginLeft: '10px',
                              fontSize: '8px',
                              textOverflow: 'ellipsis',
                              overflow: 'hidden',
                              whiteSpace: 'nowrap',
                            }}
                          >
                            {' '}
                            {product?.title}
                          </p>
                        </Box>

                        <Box
                          sx={{
                            display: 'flex',
                            fontSize: '7px',
                            paddingBottom: '2px',
                          }}
                        >
                          <Barcode
                            value={product?.sku}
                            height={20}
                            font={'sans-serif'}
                          />
                        </Box>
                        {/* </div> */}
                        {/* </Paper> */}
                      </Grid>
                    )
                  );
                })}
              </Grid>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            size='small'
            sx={{
              fontSize: '15px',
              borderRadius: '2px',
              color: 'red',
              marginRight: 3,
            }}
            onClick={handleClose}
          >
            Cancel
          </Button>
          <ReactToPrint
            trigger={() => (
              <Button
                size='small'
                sx={{
                  ':hover': {
                    backgroundColor: '#967543',
                  },
                  fontSize: '15px',
                  borderRadius: '2px',
                  color: 'white',
                  backgroundColor: '#d0a667',
                }}
                type='button'
              >
                Print
              </Button>
            )}
            onAfterPrint={() => {
              const productIds = products?.filter((product) =>
                filterNew ? !product?.is_printed : true
              );
              setIsPrintedToTrue(productIds.map((product) => product.id));
            }}
            content={() => printAll.current}
            pageStyle={pageStyle}
          />
        </DialogActions>
      </Dialog>
      <TableContainer>
        <Table {...getTableProps()} aria-label='a dense table'>
          <TableHead sx={{ bgcolor: '#e4f0f1' }}>
            <TableRow>
              {headerGroups.map((headerGroup) => (
                <React.Fragment key={headerGroup.id}>
                  {headerGroup.headers.map((column) => (
                    <TableCell
                      key={column.id}
                      align='left'
                      sx={{
                        fontFamily: 'sans-serif',
                        fontSize: '0.82rem',
                        fontWeight: '600',
                      }}
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                    >
                      {column.render('Header')}
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <ArrowDropDownIcon
                            sx={{
                              verticalAlign: 'middle',
                            }}
                          />
                        ) : (
                          <ArrowDropUpIcon
                            sx={{
                              verticalAlign: 'middle',
                            }}
                          />
                        )
                      ) : null}
                    </TableCell>
                  ))}
                </React.Fragment>
              ))}
            </TableRow>
          </TableHead>
          <TableBody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <TableRow
                  key={row.id}
                  sx={{
                    background: '#F9F9F9 ',
                  }}
                  {...row.getRowProps()}
                >
                  {row.cells.map((cell) => (
                    <TableCell key={cell.id} {...cell.getCellProps()}>
                      {cell.render('Cell')}
                    </TableCell>
                  ))}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Box sx={{ p: 2, display: 'flex', justifyContent: 'end' }}>
        <Pagination />
      </Box>
    </Box>
  );
}
