import { Grid, Box, Typography } from "@mui/material";
import React from "react";
import logo from "../../img/logo.png";
import "./styles/letter.css";

const Datenschutz = () => {
  return (
    <div style={{ padding: "20px" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection:{xs:"column-reverse", lg:"row"},
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            display: "flex",
            marginTop: "20px",
            justifyContent: "center",
            width: "100%",
            // marginLeft: "220px",
          }}
        >
          <Typography
            style={{
              fontWeight: "bold",
              textAlign: "center",
              fontSize: "30px",
            }}
          >
            Datenschutzbestimmungen
            <br></br>
            <br></br>
            LETZTES UPDATE: 29. JULI 2023
          </Typography>

          <Typography
            style={{
              fontWeight: "bold",
              textAlign: "center",
              fontSize: "18px",
            }}
          >
          </Typography>
        </Box>


        <img src={logo} width="200px" height="200px" />
      
      </Box>

      <Box className="text-container">
            <Typography
              style={{
                fontSize: "18px",
                wordSpacing: "5px",
                lineHeight: 2,
              }}
            >
              Ihre Online-Privatsphäre ist „MinMini“ wichtig. Der Zweck dieser Datenschutzerklärung besteht darin,
Sie über unsere Praktiken in Bezug auf die personenbezogenen Daten zu informieren, die wir von
Benutzern unserer Website (im Folgenden als „Website“ bezeichnet) und der Dienste sammeln die über
die Website verfügbar gemacht werden. Bitte senden Sie uns eine E-Mail an info@minmini.de, wenn Sie
Fragen zu unserer Datenschutzerklärung, unseren Datenerfassungsrichtlinien, unserem Umgang mit
Benutzerinformationen haben oder wenn Sie eine Sicherheitsverletzung melden möchten.
            </Typography>
   

          <ol style={{ fontWeight: "bold" }} className="orderd-list">

          <li style={{ fontSize: 22, fontWeight: "bold", marginTop: "2%" }}>
            <Typography
              style={{
                fontWeight: "bold",
                fontSize: "22px",
              }}
            >
              Wozu diese Richtlinie dient
            </Typography>

                <Typography
                  style={{
                    fontSize: "18px",
                    wordSpacing: "5px",
                    lineHeight: 2,
                  }}
                >
                  Die Datenschutz-Grundverordnung (DSGVO) gilt für alle in Deutschland und der Europäischen Union
ansässige Unternehmen, die Daten von Bürgern aus Deutschland und der Europäischen Union speichern.
Wir legen Wert auf Ihre Privatsphäre und möchten Ihnen gegenüber rechenschaftspflichtig und
transparent sein, in der Art und Weise, wie wir Ihre persönlichen Daten erfassen und verwenden. Wir
möchten auch, dass Sie Ihre Rechte in Bezug auf Ihre persönlichen Daten kennen.
<br></br>
<br></br>
In dieser Datenschutzrichtlinie erfahren Sie, was Sie erwartet, wenn wir Ihre personenbezogenen Daten
erfassen und verwenden. Wir haben versucht, es Ihnen einfach und leicht zu machen, die für Sie
relevantesten Informationen zu finden und zu lesen.
<br></br><br></br>
Diese Datenschutzrichtlinie regelt zusammen mit unseren Nutzungsbedingungen und allen anderen darin
genannten Dokumenten die Grundlage für unsere Erhebung, Speicherung und Nutzung der von MinMini
erfassten personenbezogenen Daten. Wir sind stets bestrebt, die Informationen, die wir unseren Nutzern
zur Verfügung stellen, zu verbessern. Wenn Sie also Feedback zu dieser Datenschutzrichtlinie haben,
teilen Sie uns dies bitte über die oben genannten Kontaktdaten mit oder senden Sie uns eine E-Mail an
info@minmini.de.
<br></br><br></br>
MinMini („wir“, „wir“ oder „unser“) verpflichtet sich, Ihre Privatsphäre zu schützen. In dieser
Datenschutzerklärung wird erläutert, wie wir Ihre personenbezogenen Daten gemäß dem deutschen
Datenschutzgesetz erheben, verwenden, offenlegen und schützen.
Wir verpflichten uns zum Schutz Ihrer Privatsphäre und werden alle erforderlichen Schritte unternehmen,
um unseren gesetzlichen Verpflichtungen bei der Verwendung Ihrer personenbezogenen Daten
nachzukommen. Bitte lesen Sie dieses Dokument sorgfältig durch, um zu verstehen, wie wir Ihre
personenbezogenen Daten schützen.
                </Typography>
          </li>

          <li style={{ fontSize: 22, fontWeight: "bold", marginTop: "2%" }}>
            <Typography
              style={{
                fontWeight: "bold",
                fontSize: "22px",
              }}
            >
              Einwilligung
            </Typography>

                <Typography
                  style={{
                    fontSize: "18px",
                    wordSpacing: "5px",
                    lineHeight: 2,
                  }}
                >
                  Durch die Nutzung von „MinMini“ stimmen Sie den Bedingungen dieser Datenschutzrichtlinie zu. Wenn
Sie Informationen über unsere Website übermitteln, stimmen Sie der Erfassung, Nutzung und
Offenlegung dieser Informationen gemäß dieser Datenschutzrichtlinie zu. Wenn Sie mit irgendeinem
Punkt dieser Richtlinie nicht einverstanden sind, nutzen Sie unsere Dienste bitte nicht und geben Sie uns
Ihre Daten nicht weiter. Diese Richtlinie gilt für alle Besucher, Benutzer und registrierten Mitglieder.
                </Typography>
          </li>

          <li style={{ fontSize: 22, fontWeight: "bold", marginTop: "2%" }}>
            <Typography
              style={{
                fontWeight: "bold",
                fontSize: "22px",
              }}
            >
              Welche Informationen wir sammeln
            </Typography>

                <Typography
                  style={{
                    fontSize: "18px",
                    wordSpacing: "5px",
                    lineHeight: 2,
                  }}
                >
                  MinMini sammelt aktiv Informationen von seinen Besuchern, wenn Sie: ein Online-Konto erstellen, eine
Suche erstellen, sich für unseren Newsletter anmelden, eine Bewertung oder Rezension abgeben, an einer
Umfrage teilnehmen, ein Online-Formular ausfüllen, an einer Werbeaktion teilnehmen oder mit uns
interagieren.
<br></br><br></br>
Einige der von Ihnen übermittelten Informationen können persönlich identifizierbare Informationen sein,
die eindeutig mit Ihnen identifiziert werden können, wie z. B. vollständiger Name, Adresse oder Standort,
E-Mail-Adresse, Telefonnummer usw. In einigen Bereichen der Website müssen Sie möglicherweise
Informationen angeben, damit Sie von einer Vielzahl exklusiver Funktionen (z. B. E-MailBenachrichtigungen oder Informationsanfragen) profitieren können. An jeder Informationssammelstelle
werden Sie darüber informiert, welche Informationen erforderlich und optional sind.
                </Typography>
          </li>

          <li style={{ fontSize: 22, fontWeight: "bold", marginTop: "2%" }}>
            <Typography
              style={{
                fontWeight: "bold",
                fontSize: "22px",
              }}
            >
              Wir erfassen die folgenden Informationen über Sie
            </Typography>

            <ol style={{ fontWeight: "normal", fontSize: 18 }}>
              <li sx={{ fontWeight: "bold" }}>
                <Typography
                  style={{
                    fontSize: "18px",
                    wordSpacing: "5px",
                    lineHeight: 2,
                  }}
                >
                  <b>Kontaktinformationen</b> - Wir fragen Sie möglicherweise nach Ihren persönlichen Daten wie
Name, Adresse, Telefonnummer, E-Mail-Adresse oder Bankverbindung.
                </Typography>
              </li>
              <li sx={{ fontWeight: "bold" }}>
                <Typography
                  style={{
                    fontSize: "18px",
                    wordSpacing: "5px",
                    lineHeight: 2,
                  }}
                >
                  <b>Konto-Anmeldeinformationen</b> - Für die Einrichtung eines Kontos bei uns benötigen wir von
Ihnen die Angabe von Informationen.
                </Typography>
              </li>
              <li sx={{ fontWeight: "bold" }}>
                <Typography
                  style={{
                    fontSize: "18px",
                    wordSpacing: "5px",
                    lineHeight: 2,
                  }}
                >
                  <b>Informationen, die Sie teilen und übermitteln</b> - Wir können Informationen sammeln, die Sie
entweder öffentlich oder auf einem von uns bereitgestellten Kanal/einer Plattform (z. B. Forum,
Kommentarbereich, Umfrageformular und soziale Medien) veröffentlichen, um sie mit den
Benutzern zu teilen.
                </Typography>
              </li>
              <li sx={{ fontWeight: "bold" }}>
                <Typography
                  style={{
                    fontSize: "18px",
                    wordSpacing: "5px",
                    lineHeight: 2,
                  }}
                >
                <b>Protokolldateien:</b> Wie bei den meisten Websites erfassen wir bestimmte Informationen
automatisch und speichern sie in Protokolldateien. Zu diesen Informationen gehören IP-Adressen,
Browsertyp, Internetdienstanbieter (ISP), Verweis-/Ausstiegsseiten, Betriebssystem, Datums-/
Zeitstempel und Clickstream-Daten. Wir verwenden diese Informationen, um die Leistung der
Dienste aufrechtzuerhalten und zu verbessern.
                </Typography>
              </li>
              <li sx={{ fontWeight: "bold" }}>
                <Typography
                  style={{
                    fontSize: "18px",
                    wordSpacing: "5px",
                    lineHeight: 2,
                  }}
                >
                <b>Analytik:</b> Wir nutzen Analysedienste (einschließlich, aber nicht beschränkt auf Google
Analytics), um zu analysieren, wie Benutzer die Website nutzen. Analysedienste verwenden
Cookies, um Informationen zu sammeln, z. B. wie oft Benutzer die Website besuchen, und wir
verwenden die Informationen, um unsere Website und Dienste zu verbessern. Die Möglichkeit
der Analysedienste, die von ihnen gesammelten Informationen zu nutzen und weiterzugeben,
wird durch deren Nutzungsbedingungen und Datenschutzrichtlinien eingeschränkt, in denen Sie
weitere Informationen darüber finden, wie diese Unternehmen diese Informationen verwenden.
                </Typography>
              </li>
              <li sx={{ fontWeight: "bold" }}>
                <Typography
                  style={{
                    fontSize: "18px",
                    wordSpacing: "5px",
                    lineHeight: 2,
                  }}
                >
                <b>Standortinformationen:</b> Wenn Sie Standortdienste auf Ihrem Mobilgerät aktiviert haben,
erfassen wir möglicherweise Ihre Standortinformationen, um die von uns angebotenen Dienste zu
verbessern. Dies ermöglicht uns die Bereitstellung standortbezogener Dienste. Wenn Sie unseren
Zugriff oder unsere Berechtigungen ändern möchten, können Sie dies in den Einstellungen Ihres
Geräts tun.
                </Typography>
              </li>
            </ol>
          </li>

          <li style={{ fontSize: 22, fontWeight: "bold", marginTop: "2%" }}>
            <Typography
              style={{
                fontWeight: "bold",
                fontSize: "22px",
              }}
            >
              Verwendung Ihrer persönlichen Daten
            </Typography>

                <Typography
                  style={{
                    fontSize: "18px",
                    wordSpacing: "5px",
                    lineHeight: 2,
                  }}
                >
                  Im Allgemeinen werden die von Ihnen an uns übermittelten personenbezogenen Daten entweder
dazu verwendet, auf von Ihnen gestellte Anfragen zu antworten, uns dabei zu helfen, Sie besser
zu bedienen oder unsere Dienste zu vermarkten. Wir verwenden Ihre persönlichen Daten auf
folgende Weise:
                </Typography>
              <ol style={{ fontWeight: "normal", fontSize: 18 }}>
              <li sx={{ fontWeight: "bold" }}>
                <Typography
                  style={{
                    fontSize: "18px",
                    wordSpacing: "5px",
                    lineHeight: 2,
                  }}
                >
                Betreiben, Wartung und Verbessern der Dienste unserer Website;
                </Typography>
              </li>

              <li sx={{ fontWeight: "bold" }}>
                <Typography
                  style={{
                    fontSize: "18px",
                    wordSpacing: "5px",
                    lineHeight: 2,
                  }}
                >
                Beantworten von Kommentare/Fragen und Kundenservice;
                </Typography>
              </li>

              <li sx={{ fontWeight: "bold" }}>
                <Typography
                  style={{
                    fontSize: "18px",
                    wordSpacing: "5px",
                    lineHeight: 2,
                  }}
                >
                Entwicklung und Verbesserung unserer Dienste;
                </Typography>
              </li>

              <li sx={{ fontWeight: "bold" }}>
                <Typography
                  style={{
                    fontSize: "18px",
                    wordSpacing: "5px",
                    lineHeight: 2,
                  }}
                >
                Um unseren Service, unsere Zahlungen und andere Transaktionen im Zusammenhang mit
der Anwendung zu erfüllen und zu verwalten
                </Typography>
              </li>

              <li sx={{ fontWeight: "bold" }}>
                <Typography
                  style={{
                    fontSize: "18px",
                    wordSpacing: "5px",
                    lineHeight: 2,
                  }}
                >
                Um Sie als Benutzer in unserem System identifizieren zu können;
                </Typography>
              </li>

              <li sx={{ fontWeight: "bold" }}>
                <Typography
                  style={{
                    fontSize: "18px",
                    wordSpacing: "5px",
                    lineHeight: 2,
                  }}
                >
                Lösung von Streitigkeiten und Behebung von Problemen;
                </Typography>
              </li>

              <li sx={{ fontWeight: "bold" }}>
                <Typography
                  style={{
                    fontSize: "18px",
                    wordSpacing: "5px",
                    lineHeight: 2,
                  }}
                >
                Um die Strafverfolgung zu unterstützen und auf Vorladungen zu reagieren;
                </Typography>
              </li>

              <li sx={{ fontWeight: "bold" }}>
                <Typography
                  style={{
                    fontSize: "18px",
                    wordSpacing: "5px",
                    lineHeight: 2,
                  }}
                >
                Um ein persönliches Profil über Sie zu erstellen, um zukünftige Besuche der Anwendung
personalisierter zu gestalten;
                </Typography>
              </li>

              <li sx={{ fontWeight: "bold" }}>
                <Typography
                  style={{
                    fontSize: "18px",
                    wordSpacing: "5px",
                    lineHeight: 2,
                  }}
                >
                Um betrügerische Transaktionen zu verhindern, Diebstahl zu überwachen und vor
kriminellen Aktivitäten zu schützen.
                </Typography>
              </li>
            </ol>
            <Typography
                  style={{
                    fontSize: "18px",
                    wordSpacing: "5px",
                    lineHeight: 2,
                  }}
                >
                  Ihre personenbezogenen Daten können auf Servern in Deutschland und Europa gespeichert und
verarbeitet werden. Wir können aus Ihren personenbezogenen Daten auch anonyme Datensätze
erstellen, indem wir alle Informationen (z. B. Ihren Namen), mit denen Sie persönlich 
identifiziert werden können, vollständig ausschließen. Wir verwenden diese anonymen Daten,
um Anfrage- und Nutzungsmuster zu analysieren, damit wir den Inhalt unserer Dienste
verbessern und die Funktionalität der Website verbessern können. Wir behalten uns das Recht
vor, anonyme Daten für beliebige Zwecke zu verwenden und anonyme Daten nach unserem
alleinigen Ermessen an Dritte weiterzugeben.
<br></br>
Wir erhalten möglicherweise Erfahrungsberichte und Kommentare von Benutzern, die positive
Erfahrungen mit unseren Diensten gemacht haben. Wir können solche Inhalte veröffentlichen.
Wenn wir diese Inhalte veröffentlichen, können wir unsere Nutzer anhand ihres Vor- und
Nachnamens identifizieren. Bevor wir diese Informationen zusammen mit dem
Erfahrungsbericht veröffentlichen, holen wir die Einwilligung des Nutzers ein.
                </Typography>
          </li>

          <li style={{ fontSize: 22, fontWeight: "bold", marginTop: "2%" }}>
            <Typography
              style={{
                fontWeight: "bold",
                fontSize: "22px",
              }}
            >
              Offenlegung Ihrer persönlichen Daten
            </Typography>

            <Typography
                  style={{
                    fontSize: "18px",
                    wordSpacing: "5px",
                    lineHeight: 2,
                  }}
                >
                  Wir geben Ihre personenbezogenen Daten wie unten und an anderer Stelle in dieser
Datenschutzrichtlinie beschrieben weiter.
<br></br><br></br>
<b>Von Ihnen benannte Dritte:</b> Wenn Sie die Dienste nutzen, werden die von Ihnen bereitgestellten
personenbezogenen Daten an Dritte weitergegeben, denen Sie den Empfang dieser Informationen
gestattet haben.
<br></br><br></br>
<b>Drittanbieter von Dienstleistungen:</b> Wir können Ihre personenbezogenen Daten an
Drittanbieter weitergeben, um Ihnen die Dienste bereitzustellen, die wir Ihnen über unsere
Dienste anbieten; Qualitätssicherungstests durchführen; um technischen Support bereitzustellen;
die Dienste zu vermarkten; und/oder um andere Dienstleistungen für uns zu erbringen.
<br></br><br></br>
<b>Informationen, die wir teilen:</b> Wir können aggregierte Informationen über unsere Benutzer und
Informationen, die keine Einzelpersonen identifizieren, ohne Einschränkung offenlegen. Darüber
hinaus können wir die von uns erfassten oder von Ihnen bereitgestellten personenbezogenen
Daten weitergeben:
                </Typography>

            <ol style={{ fontWeight: "normal", fontSize: 18 }}>
              <li sx={{ fontWeight: "bold" }}>
                <Typography
                  style={{
                    fontSize: "18px",
                    wordSpacing: "5px",
                    lineHeight: 2,
                  }}
                >
                  Um den Zweck zu erfüllen, für den Sie uns die Daten zur Verfügung stellen, für jeden
anderen Zweck, den wir Ihnen bei der Bereitstellung der Informationen oder mit Ihrer
Einwilligung mitteilen;
                </Typography>
              </li>
              <li sx={{ fontWeight: "bold" }}>
                <Typography
                  style={{
                    fontSize: "18px",
                    wordSpacing: "5px",
                    lineHeight: 2,
                  }}
                >
                  An von Ihnen benannte Dritte;
                </Typography>
              </li>
              <li sx={{ fontWeight: "bold" }}>
                <Typography
                  style={{
                    fontSize: "18px",
                    wordSpacing: "5px",
                    lineHeight: 2,
                  }}
                >
                  Mit unseren Tochtergesellschaften und verbundenen Unternehmen;
                </Typography>
              </li>
              <li sx={{ fontWeight: "bold" }}>
                <Typography
                  style={{
                    fontSize: "18px",
                    wordSpacing: "5px",
                    lineHeight: 2,
                  }}
                >
                  An Dritte, um über die Dienste getätigte Zahlungen abzuwickeln.
                </Typography>
              </li>
            </ol>
            <Typography
                  style={{
                    fontSize: "18px",
                    wordSpacing: "5px",
                    lineHeight: 2,
                  }}
                >
                 <b>Sonstige Offenlegungen:</b> Unabhängig von den Entscheidungen, die Sie in Bezug auf Ihre 
personenbezogenen Daten treffen (wie unten beschrieben), kann MinMini personenbezogene
Daten offenlegen, wenn es in gutem Glauben davon ausgeht, dass eine solche Offenlegung
erforderlich ist: (i) im Zusammenhang mit einer rechtlichen Untersuchung; (ii) um relevante
Gesetze einzuhalten oder auf Vorladungen oder Haftbefehle zu reagieren, die unserem
Unternehmen zugestellt wurden; (iii) um die Rechte oder das Eigentum von minmini.de oder
Benutzern der Dienste zu schützen oder zu verteidigen; und/oder (iv) um Verstöße oder
potenzielle Verstöße gegen das Gesetz, diese Datenschutzrichtlinie oder unsere
Nutzungsbedingungen zu untersuchen oder bei deren Verhinderung zu helfen.
                </Typography>
          </li>

          <li style={{ fontSize: 22, fontWeight: "bold", marginTop: "2%" }}>
            <Typography
              style={{
                fontWeight: "bold",
                fontSize: "22px",
              }}
            >
              Websites von Drittanbietern
            </Typography>

            <Typography
                  style={{
                    fontSize: "18px",
                    wordSpacing: "5px",
                    lineHeight: 2,
                  }}
                >
                  Unsere Website kann Links zu Websites Dritter enthalten. Wenn Sie auf einen Link zu einer
anderen Website oder einem anderen Standort klicken, verlassen Sie automatisch unsere Website
oder Dienste und gelangen zu einer anderen Website, und ein anderes Unternehmen kann
personenbezogene Daten oder anonyme Daten von Ihnen erfassen. Wir haben keine Kontrolle
über diese externen Websites oder deren Inhalte, überprüfen sie nicht und sind nicht dafür
verantwortlich. Bitte beachten Sie, dass die Bedingungen dieser Datenschutzrichtlinie nicht für
diese externen Websites oder Inhalte oder für die Erfassung Ihrer persönlichen Daten gelten,
nachdem Sie auf Links zu solchen externen Websites geklickt haben. Wir empfehlen Ihnen, die
Datenschutzrichtlinien jeder Website, die Sie besuchen, zu lesen. Die Links zu Websites oder
Standorten Dritter dienen Ihrer Bequemlichkeit und bedeuten nicht, dass wir diese Inhalte oder
Websites Dritter befürworten.
                </Typography>
          </li>

          <li style={{ fontSize: 22, fontWeight: "bold", marginTop: "2%" }}>
            <Typography
              style={{
                fontWeight: "bold",
                fontSize: "22px",
              }}
            >
              Ihre Wahlmöglichkeiten in Bezug auf Informationen
            </Typography>

                <Typography
                  style={{
                    fontSize: "18px",
                    wordSpacing: "5px",
                    lineHeight: 2,
                  }}
                >
                  Wir bieten Ihnen Wahlmöglichkeiten hinsichtlich der Erhebung, Nutzung und Weitergabe Ihrer
personenbezogenen Daten. Wir senden Ihnen möglicherweise regelmäßig E-Mails, die die
Nutzung unserer Dienste direkt bewerben. Wenn Sie Werbemitteilungen von uns erhalten,
können Sie angeben, dass Sie keine weiteren Mitteilungen von uns mehr erhalten möchten, und
Sie haben die Möglichkeit, sich abzumelden, indem Sie den Anweisungen zum Abbestellen in
der E-Mail folgen, die Sie erhalten, oder indem Sie uns direkt kontaktieren. Trotz Ihrer
angegebenen E-Mail-Einstellungen senden wir Ihnen möglicherweise dienstbezogene
Mitteilungen, einschließlich Mitteilungen über Aktualisierungen unserer Nutzungsbedingungen
oder Datenschutzrichtlinien.
                </Typography>
          </li>

          <li style={{ fontSize: 22, fontWeight: "bold", marginTop: "2%" }}>
            <Typography
              style={{
                fontWeight: "bold",
                fontSize: "22px",
              }}
            >
              Ihre Rechte
            </Typography>

                <Typography
                  style={{
                    fontSize: "18px",
                    wordSpacing: "5px",
                    lineHeight: 2,
                  }}
                >
                  Als Nutzer unserer Website haben Sie die folgenden Rechte, die Sie jeweils ausüben können,
                  <br></br><br></br>
indem Sie uns unter info@minmini.de kontaktieren:
<br></br><br></br>
● Das Recht, jederzeit zu fragen, welche personenbezogenen Daten wir über Sie gespeichert
haben.
<br></br>
● Das Recht, von uns die kostenlose Aktualisierung und Berichtigung aller veralteten oder
falschen personenbezogenen Daten, die wir über Sie gespeichert haben, zu verlangen.
<br></br>
● Wie oben dargelegt, besteht das Recht, jegliche Marketingmitteilungen, die wir Ihnen senden,
abzubestellen.
<br></br><br></br>

<b>A. Zugangsrecht</b>
<br></br>
Sie haben das Recht auf Zugang zu allen personenbezogenen Daten, die wir über Sie gespeichert
haben. Sie können uns um eine Kopie Ihrer persönlichen Daten bitten; Bestätigung, ob Ihre
personenbezogenen Daten von uns verwendet werden; Einzelheiten darüber, wie und warum es
verwendet wird; und Einzelheiten darüber, welche Sicherheitsmaßnahmen getroffen werden,
wenn wir Ihre Daten außerhalb Deutschlands und des Europäischen Wirtschaftsraums übertragen.
<br></br><br></br>
<b>B. Recht, Ihre Informationen zu aktualisieren</b>
<br></br>
Sie haben das Recht, eine Aktualisierung Ihrer personenbezogenen Daten zu verlangen, wenn
diese veraltet oder falsch sind.
<br></br><br></br>
<b>C. Recht, Ihre Daten zu löschen</b>
<br></br>
Unter bestimmten Umständen haben Sie das Recht, von uns die Löschung aller
personenbezogenen Daten zu verlangen, die wir über Sie gespeichert haben. Weitere
Informationen zu diesen besonderen Umständen können Sie bei uns per E-Mail unter
info@minmini.de einholen
<br></br><br></br>
<b>D. Recht, die Nutzung Ihrer Daten einzuschränken</b>
<br></br>
Sie haben das Recht, von uns zu verlangen, dass wir die Art und Weise, wie wir Ihre
personenbezogenen Daten verarbeiten, unter bestimmten Umständen einschränken. Weitere 
Informationen zu diesen besonderen Umständen können Sie bei uns per E-Mail unter
info@minmini.de einholen
Wir geben Ihre Anfrage an andere Empfänger Ihrer personenbezogenen Daten weiter, es sei denn, dies ist
unmöglich oder mit einem unverhältnismäßigen Aufwand verbunden. Über die oben angegebene E-MailAdresse können Sie uns fragen, wer die Empfänger sind.
                </Typography>
          </li>

          <li style={{ fontSize: 22, fontWeight: "bold", marginTop: "2%" }}>
            <Typography
              style={{
                fontWeight: "bold",
                fontSize: "22px",
              }}
            >
              Faire Informationspraktiken
            </Typography>

                <Typography
                  style={{
                    fontSize: "18px",
                    wordSpacing: "5px",
                    lineHeight: 2,
                  }}
                >
                  Die Fair Information Practices Principles sind der Eckpfeiler der deutschen Datenschutzgesetzgebung und
die darin enthaltenen Ideen hatten großen Einfluss auf die Entwicklung der Datenschutzgesetze auf der
ganzen Welt. Die Einhaltung der verschiedenen Datenschutzbestimmungen zum Schutz
personenbezogener Daten erfordert ein Verständnis der Grundsätze der fairen Informationspraxis und der
Art und Weise, wie diese in die Praxis umgesetzt werden sollten.
<br></br><br></br>
Um im Einklang mit fairen Informationspraktiken zu stehen, werden wir die folgenden
Reaktionsmaßnahmen ergreifen, falls es zu einer Datenschutzverletzung kommt:
<br></br>
• Wir werden die Benutzer per E-Mail, Innerhalb von 7 Werktagen, benachrichtigen
<br></br>
• Wir benachrichtigen die Benutzer per In-Site-Benachrichtigung innerhalb eines Werktages
<br></br>
Wir stimmen auch dem Grundsatz der individuellen Wiedergutmachung zu, der vorschreibt, dass
Menschen die Möglichkeit haben, fahrlässige Datenerfasser und -verarbeiter rechtlich zur Rechenschaft
zu ziehen. Dieses Konzept sieht vor, dass sich Personen an Gerichte oder staatliche Stellen wenden
können, um Verstöße von Datenverarbeitern zu untersuchen und/oder strafrechtlich zu verfolgen, und dass
sie darüber hinaus durchsetzbare Rechte gegenüber Datennutzern haben.
                </Typography>
          </li>

          <li style={{ fontSize: 22, fontWeight: "bold", marginTop: "2%" }}>
            <Typography
              style={{
                fontWeight: "bold",
                fontSize: "22px",
              }}
            >
              Ihre Rechte als betroffene Person
            </Typography>

            <Typography
                  style={{
                    fontSize: "18px",
                    wordSpacing: "5px",
                    lineHeight: 2,
                  }}
                >
                  Während „MinMini“ jederzeit im Besitz Ihrer personenbezogenen Daten ist oder diese verarbeitet, haben
Sie möglicherweise die folgenden Rechte:
                </Typography>

            <ol style={{ fontWeight: "normal", fontSize: 18 }}>
              <li sx={{ fontWeight: "bold" }}>
                <Typography
                  style={{
                    fontSize: "18px",
                    wordSpacing: "5px",
                    lineHeight: 2,
                  }}
                >
                  <b>Recht auf Berichtigung –</b> Sie haben das Recht, personenbezogene Daten, die wir über Sie
gespeichert haben, zu korrigieren, wenn sie unrichtig oder unvollständig sind.
                </Typography>
              </li>

              <li sx={{ fontWeight: "bold" }}>
                <Typography
                  style={{
                    fontSize: "18px",
                    wordSpacing: "5px",
                    lineHeight: 2,
                  }}
                >
                  <b>Recht auf Löschung –</b> Unter bestimmten Umständen können Sie verlangen, dass die
personenbezogenen Daten, die wir über Sie gespeichert haben, aus unseren Aufzeichnungen
gelöscht werden.
                </Typography>
              </li>

              <li sx={{ fontWeight: "bold" }}>
                <Typography
                  style={{
                    fontSize: "18px",
                    wordSpacing: "5px",
                    lineHeight: 2,
                  }}
                >
                  <b>Recht auf Einschränkung der Verarbeitung –</b> Unter bestimmten Bedingungen haben Sie das
Recht, die Verarbeitung Ihrer personenbezogenen Daten einzuschränken.
                </Typography>
              </li>

              <li sx={{ fontWeight: "bold" }}>
                <Typography
                  style={{
                    fontSize: "18px",
                    wordSpacing: "5px",
                    lineHeight: 2,
                  }}
                >
                  <b>Recht auf Portabilität –</b> Sie haben das Recht, die von uns über Sie gespeicherten
personenbezogenen Daten an eine andere Organisation übertragen zu lassen.
                </Typography>
              </li><li sx={{ fontWeight: "bold" }}>
                <Typography
                  style={{
                    fontSize: "18px",
                    wordSpacing: "5px",
                    lineHeight: 2,
                  }}
                >
                  <b>Widerspruchsrecht  –</b> Sie haben das Recht, bestimmten Arten der Verarbeitung, wie z. B.
Direktmarketing, sowie Verarbeitungen, die wir auf Grundlage unserer berechtigten Interessen
durchführen, zu widersprechen.
                </Typography>
              </li>

              <li sx={{ fontWeight: "bold" }}>
                <Typography
                  style={{
                    fontSize: "18px",
                    wordSpacing: "5px",
                    lineHeight: 2,
                  }}
                >
                  <b>Recht auf Widerspruch gegen die automatisierte Verarbeitung, einschließlich Profiling –</b> Sie
haben außerdem das Recht, nicht den rechtlichen Auswirkungen einer automatisierten
Verarbeitung oder eines Profilings ausgesetzt zu sein.
                </Typography>
              </li>
            </ol>
          </li>

          <li style={{ fontSize: 22, fontWeight: "bold", marginTop: "2%" }}>
            <Typography
              style={{
                fontWeight: "bold",
                fontSize: "22px",
              }}
            >
              Richtlinienaktualisierung
            </Typography>

                <Typography
                  style={{
                    fontSize: "18px",
                    wordSpacing: "5px",
                    lineHeight: 2,
                  }}
                >
                  „MinMini“ behält sich das Recht vor, unsere Datenschutzrichtlinie ohne vorherige Ankündigung zu
ändern, zu ergänzen oder zu modifizieren. Wir werden die geänderte Richtlinie auf dieser Seite
veröffentlichen und oben auf der Seite das Datum der Überarbeitung der Richtlinie angeben. Bitte
überprüfen Sie unsere Website und diese Seite regelmäßig auf Aktualisierungen.
                </Typography>
          </li>

          <li style={{ fontSize: 22, fontWeight: "bold", marginTop: "2%" }}>
            <Typography
              style={{
                fontWeight: "bold",
                fontSize: "22px",
              }}
            >
              Kontaktiere uns
            </Typography>

                <Typography
                  style={{
                    fontSize: "18px",
                    wordSpacing: "5px",
                    lineHeight: 2,
                  }}
                >
                  Wenn Sie uns bezüglich irgendetwas in dieser Datenschutzrichtlinie oder darüber, wie wir Ihre
personenbezogenen Daten erfassen oder speichern, kontaktieren möchten, nehmen Sie bitte über die Seite
„Kontakt“ auf unserer Website Kontakt mit uns auf.
                </Typography>
          </li>
        </ol>
      </Box>
    </div>
  );
};

export default Datenschutz;
