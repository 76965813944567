import {
  KeyboardArrowLeft,
  KeyboardArrowRight,
  KeyboardDoubleArrowLeft,
  KeyboardDoubleArrowRight,
} from "@mui/icons-material";
import { Box, IconButton, Paper, TextField } from "@mui/material";
import React, { useState } from "react";

const Pagination = ({
  page,
  products,
  rowsPerPage,
  handleFirstPageButtonClick,
  handleBackButtonClick,
  handleNextButtonClick,
  handleLastPageButtonClick,
  handleChangePage,
}) => {
  const [page1, setPage1] = useState(page);

  return (
    <Box sx={{ display: "flex", gap: 1.3, alignItems: "center" }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        sx={{
          "&:disabled": {
            backgroundColor: "#C0C0C0",
          },
          ":hover": {
            background: "#beafa0",
          },
          background: "#beafa0",
          p: ".5rem",
          height: "2.5rem",
          borderRadius: ".5rem",
        }}
        disabled={page == 0}
        variant="contained"
      >
        <KeyboardDoubleArrowLeft sx={{ color: "white" }} />
      </IconButton>

      <IconButton
        onClick={handleBackButtonClick}
        sx={{
          "&:disabled": {
            backgroundColor: "#C0C0C0",
          },
          ":hover": {
            background: "#beafa0",
          },
          background: "#beafa0",
          p: ".5rem",
          height: "2.5rem",
          borderRadius: ".5rem",
        }}
        disabled={page == 0}
        variant="contained"
      >
        <KeyboardArrowLeft sx={{ color: "white" }} />
      </IconButton>

      <Box>
        page{" "}
        <strong>
          {page + 1} of{" "}
          {products?.length > rowsPerPage
            ? Math.ceil(products?.length / rowsPerPage)
            : page + 1}
        </strong>
      </Box>
      <TextField
        type="number"
        value={page + 1}
        size="small"
        sx={{ width: "4rem", height: "2.5rem" }}
        onChange={(e) => {
          setPage1(e.target.value);
          handleChangePage(e, e.target.value);
        }}
        inputProps={{ max: Math.ceil(products?.length / rowsPerPage), min: 0 }}
      />
      <IconButton
        onClick={handleNextButtonClick}
        sx={{
          "&:disabled": {
            backgroundColor: "#C0C0C0",
          },
          ":hover": {
            background: "#beafa0",
          },
          background: "#beafa0",
          p: ".5rem",
          height: "2.5rem",
          borderRadius: ".5rem",
        }}
        disabled={page >= Math.ceil(products?.length / rowsPerPage) - 1}
        variant="contained"
      >
        <KeyboardArrowRight sx={{ color: "white" }} />
      </IconButton>

      <IconButton
        onClick={handleLastPageButtonClick}
        sx={{
          "&:disabled": {
            backgroundColor: "#C0C0C0",
          },
          ":hover": {
            background: "#beafa0",
          },
          background: "#beafa0",
          p: ".5rem",
          height: "2.5rem",
          borderRadius: ".5rem",
        }}
        disabled={page >= Math.ceil(products?.length / rowsPerPage) - 1}
        variant="contained"
      >
        <KeyboardDoubleArrowRight sx={{ color: "white" }} />
      </IconButton>
    </Box>
  );
};

export default Pagination;
