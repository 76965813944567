import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import QuestionData from "../QuestionData";
import logo from "../../../img/logo.png";

const FAQ = () => {
  return (
    <Box style={{ width: "70%", margin: "auto" }}>
      
      <Box
        sx={{
          display: "flex",
          flexDirection:{xs:"column-reverse", lg:"row"},
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <span
          style={{
            display: "flex",
            marginTop: "20px",
            justifyContent: "center",
            width: "100%",
            // marginLeft: "220px",
          }}
        >
          <Typography
            style={{
              fontWeight: "bold",
              textAlign: "center",
              fontSize: "30px",
            }}
          >
            Frequently asked questions
          </Typography>
        </span>

        <img src={logo} width="200px" height="200px" />
      </Box>

      <br />
      
      <Grid container justifyContent={"center"} marginTop="30px">
        {QuestionData.map((val, index) => {
          return (
            <>
              <Grid item sm={12}>
                <h1 style={{ fontWeight: "bolder", fontSize: "16px" }}>
                  <Typography style={{fontWeight:"bold", fontSize:22,}}>{val.question}</Typography>
                </h1>
                <Typography
                  style={{
                    fontSize: "18px",
                    paddingBottom: "20px",
                    fontWeight:"lighter",
                    marginBottom:"6px"
                  }}
                >
                  {val.answer}
                </Typography>
              </Grid>
            </>
          );
        })}

        <Grid item xs={10}>
          <h2
            style={{
              textDecoration: "underline",
              fontSize: "20px",
              textAlign: "center",
              marginTop:"50px"
            }}
          >
            <a href="/letter">Alle weiteren Informationen findest Du in unseren Allgemeinen
            Geschäftsbedingungen.</a>
          </h2>
        </Grid>
      </Grid>
    </Box>
  );
};

export default FAQ;
